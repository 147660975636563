.sub-card {
  dl {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 1.75rem;
    dt,
    dd {
      color: $color-black-80;
      text-align: left;
    }
    dt {
      @include coTxtLabelMedium;
      white-space: nowrap;
      align-self: stretch;
      line-height: 1.625rem;
      padding-right: 0.5rem;
    }
    dd {
      @include shortening-two;
      position: relative;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0.5rem;
      width: 100%;
      height: 1px;
      background-color: $color-black-10;
    }
    & ~ dl {
      padding-top: 0;
      &::after {
        display: none;
        width: 0;
        height: 0;
      }
    }
  }
  &.type1,
  &.type3 {
    position: relative;
    input[type='radio'],
    input[type='checkbox'] {
      @include hide-input;
      & + label {
        @include card-check-type-off;
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
        // border-radius: 1.25rem;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .inner {
          position: relative;
          // border-radius: 1.125rem;
          background-color: #fff;
          padding: {
            top: 1.3125rem;
            right: 1.25rem;
            bottom: 0.5625rem;
            left: 1.25rem;
          }
          ul {
            &.case {
              display: flex;
              align-items: center;
              li {
                &.ico-checkbox {
                  margin-left: 0.25rem;
                  width: 1.25rem;
                  height: 1.25rem;
                  background: {
                    size: 1.25rem;
                    position: center;
                    repeat: no-repeat;
                  }
                  transition: background-image $transition-3 ease-in-out;
                }
                &:first-child {
                  @include coTxtSubCard;
                  color: $color-sub-02;
                }
              }
            }
            &.profile {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              padding-bottom: 0.5rem;
              margin-top: 0.25rem;
              li {
                @include coTxtTitleLarge;
                color: $color-black-80;
                position: relative;
                & ~ li {
                  span {
                    @include coTxtBody;
                    margin-left: 0.75rem;
                  }
                }
              }
            }
            &.tag-list {
              margin: {
                top: 0.75rem;
                right: 0;
                bottom: 0.75rem;
                left: 0;
              }
              width: 100%;
              max-height: 24px;
              overflow-y: hidden;
              overflow-x: scroll;
              flex-wrap: nowrap;
              li {
                margin: 0;
                flex: 0 0 auto;
                & ~ li {
                  margin-left: 0.75rem;
                }
              }
            }
          }
          dl {
            dt {
              width: 1.625rem;
            }
            dd {
              span {
                @include coTxtBodySmall;
                width: calc(100% - 2.125rem);
              }
            }
          }
        }
        &::before {
          @include card-check-type-on-before(1.25rem);
        }
      }

      &:checked {
        & + label {
          @include card-check-type-on;
          box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
          .inner {
            ul {
              &.case {
                li {
                  &.ico-checkbox {
                    background-image: url($image-ico-path + 'ico_checkbox_sub_card_20x20_on.svg');
                  }
                }
              }
            }
          }
          &::before {
            opacity: 1;
          }
        }
      }
    }
  }

  &.type1 {
    @include cardType01(1.125rem);
  }

  &.type3 {
    @include cardType01(0.75rem);
    input[type='radio'],
    input[type='checkbox'] {
      &:checked {
        & + label {
          @include card-check-type-on;
          box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
          .inner {
            ul {
              &.case {
                li {
                  &.ico-checkbox {
                  }
                }
              }
            }
          }
          &::before {
            opacity: 1;
          }
        }
      }
    }
  }

  &.type2 {
    background: #ffffff;
    border: 1px solid $color-black-05;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
    border-radius: 1.25rem;
    padding: 1.5rem 1.25rem 0;
    overflow: hidden;
    p {
      &.location {
        @include coTxtTitleLarge;
        color: $color-black-80;
        margin-top: 0.5rem;
        margin-bottom: 0.75rem;
      }
    }
    .info-list {
      padding-bottom: 1.5rem;
      dl {
        dt,
        dd {
          @include coTxtLabelMedium;
        }
        dt {
          width: 4rem;
          color: $color-black-40;
        }
        dd {
          position: relative;
          padding-left: 0.625rem;
          //width: calc(100% - 4.5rem);
          display: flex;
          align-items: center;
          justify-content: flex-start;
          span {
            @include coTxtLabelMedium;
            color: $color-black-80;
            position: relative;
            & ~ span {
              padding-left: 0.8125rem;
              &::after {
                content: '/';
                display: block;
                position: absolute;
                left: 0.25rem;
                top: 0;
                width: 0.3125rem;
                height: 1.25rem;
                color: $color-black-20;
              }
            }
          }
        }
        &::after {
          left: -1.375rem;
          width: calc(100% + 2.75rem);
        }
      }
    }
    .button-list {
      background-color: $color-bg-04;
      margin-left: -1.25rem;
      width: calc(100% + 2.5rem);
      height: 2.75rem;
      display: flex;
      align-items: center;
      border-top: 1px solid $color-black-10;
      a {
        @include coTxtBtnSmall;
        color: $color-black-80;
        height: 2.75rem;
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        & ~ a {
          border-left: 1px solid $color-black-10;
        }
      }
    }
  }

  &.type4 {
    margin-top: 1.25rem;
    @include cardType01(1.125rem);

    position: relative;
    input[type='radio'],
    input[type='checkbox'] {
      @include hide-input;
      & + label {
        @include card-check-type-off;
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
        // border-radius: 1.25rem;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .inner {
          position: relative;
          // border-radius: 1.125rem;
          background-color: #fff;
          padding: {
            top: 1.3125rem;
            right: 1.25rem;
            bottom: 0.5625rem;
            left: 1.25rem;
          }
          ul {
            &.case {
              display: flex;
              align-items: center;
              justify-content: space-between;
              li {
                &.ico-checkbox {
                  width: 1.25rem;
                  height: 1.25rem;
                  background-image: url($image-ico-path + 'ico_checkbox_sub_card_20x20_on.svg');
                  background-size: 1.25rem 1.25rem;
                  background-position: center center;
                  background-repeat: no-repeat;
                  transition: background-image $transition-3 ease-in-out;
                }
                &:first-child {
                  @include coTxtSubCard;
                  color: $color-sub-02;
                }
              }
            }
            &.profile {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              padding-bottom: 0.5rem;
              margin-top: 0.25rem;
              li {
                @include coTxtTitleLarge;
                color: $color-black-80;
                position: relative;
                & ~ li {
                  span {
                    @include coTxtBody;
                    margin-left: 0.75rem;
                  }
                }
              }
            }
            &.tag-list {
              margin: {
                top: 0.75rem;
                right: 0;
                bottom: 0.75rem;
                left: 0;
              }
              width: 100%;
              max-height: 24px;
              overflow-y: hidden;
              overflow-x: scroll;
              flex-wrap: nowrap;
              li {
                margin: 0;
                flex: 0 0 auto;
                & ~ li {
                  margin-left: 0.75rem;
                }
              }
            }
          }
          dl {
            dt {
              width: 1.625rem;
            }
            dd {
              span {
                @include coTxtBodySmall;
                width: calc(100% - 2.125rem);
              }
            }
          }
        }
        &::before {
          @include card-check-type-on-before(1.25rem);
        }
      }

      &:checked {
        & + label {
          @include card-check-type-on;
          box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
          .inner {
            ul {
              &.case {
                li {
                  &.target {
                    display: flex;
                    &:after {
                      content: '';
                      margin-left: 4px;
                      width: 1.25rem;
                      height: 1.25rem;
                      background-image: url($image-ico-path + 'ico_checkbox_sub_card_20x20_on.svg');
                      background-size: 1.25rem 1.25rem;
                      background-position: center center;
                      background-repeat: no-repeat;
                      transition: background-image $transition-3 ease-in-out;
                    }
                  }
                  &.ico-checkbox {
                  }
                }
              }
            }
          }
          &::before {
            opacity: 1;
          }
        }
      }
    }
  }
}
