header {
  &.header {
    &.co-header {
      position: fixed;
      left: 0;
      top: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $color-main;
      width: 100%;
      height: 5.25rem;
      padding: 1.5625rem 1.75rem;
      z-index: 990;
      transition: top $transition-3 linear;
      will-change: scroll-position;
      h1 {
        &.logo {
          display: flex;
          a {
            display: block;
            img {
              width: auto;
              height: 1.5rem;
              min-height: 24px;
            }
            padding: 0.5rem 0.75rem;
            background-color: $color-white-100;
            border-radius: 0.5rem;
            overflow: hidden;
          }
          &.type-color {
            display: block;
          }
        }
      }
      .menu {
        button {
          &.btn-open-gnb {
            img {
              width: 34px;
              height: 34px;
            }
          }
        }
      }
      // scroll hide/show
      &.scroll-hide {
        top: -5.25rem;
        // transition: top $transition-3 linear;
      }
    }
  }
}
