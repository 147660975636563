.list {
  &.type1 {
    display: block;
    li {
      @include coTxtLabelMedium;
      color: $color-black-60;
      position: relative;
      padding-left: 0.75rem;
      margin-top: 0.75rem;
      &:first-child {
        margin-top: 0;
      }
      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0.5rem;
        width: 0.25rem;
        height: 0.25rem;
        border-radius: 100%;
        background-color: $color-black-20;
      }
    }
  }
  &.default {
    @include coTxtBody;
    color: $color-black-60;
  }
}