// datepicker

// + common variable
$colors: (
  selected: $color-sub-02,
  holiday: $color-sub-03,
  disabled: $color-black-20,
);

.co-popup-datepicker {
  .cont-box {
    padding-bottom: 2.5rem;
    h1,
    h1 ~ p,
    h1 ~ p ~ .datepicker-list {
      padding: {
        left: 1.75rem;
        right: 1.75rem;
      }
    }
    h1 {
      @include coTxtTitleSmall;
      color: $color-black-80;
      position: relative;
      & ~ p {
        @include coTxtLabelMedium;
        color: $color-black-60;
        margin-top: 0.5rem;
        span {
          color: $color-sub-02;
        }
      }
      & ~ h1 {
        margin-top: 3.5rem;
        &::after {
          content: '';
          display: block;
          position: absolute;
          left: 1.75rem;
          top: -1.75rem;
          width: calc(100% - 2.5rem);
          height: 1px;
          background-color: $color-black-10;
        }
      }
    }
    .datepicker-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 1.25rem;
      .input-block {
        flex-grow: 1;
        position: relative;
        input[type='radio'],
        input[type='checkbox'] {
          position: absolute;
          left: 0;
          top: 0;
          @include position(50%, 50%);
          width: 1px;
          height: 1px;
          background-color: transparent;
          color: transparent;
          border: 0 none;
          & + label {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $color-main;
            border-radius: 1.75rem;
            padding: 0.5rem;
            span {
              @include coTxtLabelLarge;
              color: $color-black-60;
              color: $color-main;
            }
          }
          &:checked {
            & + label {
              background-color: $color-main;
              span {
                color: $color-white-100;
              }
            }
          }
        }
        & ~ .input-block {
          margin-left: 0.5rem;
        }
      }
    }

    // datepicker reset
    .ui-widget {
      font-family: $font-name;
    }
    // + datepicker
    .co-datepicker {
      &.hasDatepicker {
        margin-top: 1.25rem;
        .ui-datepicker-inline {
          padding: {
            top: 0;
            right: 0.5rem;
            bottom: 0.75rem;
            left: 0.5rem;
          }
          width: 100%;
          border: 0 none;
          border-radius: 0;
          .ui-datepicker-header {
            @include coTxtTitleHeadingLarge;
            color: $color-main;
            padding: 0;
            margin: 0 auto;
            text-align: center;
            border-radius: 0;
            border: 0 none;
            background-color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            .ui-datepicker-prev,
            .ui-datepicker-next {
              width: 2.125rem;
              height: 2.125rem;
              //position: relative;
              //right: auto;
              //left: auto;
              top: 0;
              display: inline-block;
              .ui-icon {
                width: 2.125rem;
                height: 2.125rem;
                left: 0;
                top: 0;
                margin: 0;
                background-size: 2.125rem 2.125rem;
                background-position: center center;
                background-repeat: no-repeat;
              }
              &:hover {
                background-color: transparent;
                border: 0 none;
              }
            }
            .ui-datepicker-prev {
              margin-right: 0.5rem;
              .ui-icon {
                background-image: url($image-ico-path + 'datepicker/ico_arrow_year_prev_34x34.svg');
              }
            }
            .ui-datepicker-next {
              margin-left: 0.5rem;
              .ui-icon {
                background-image: url($image-ico-path + 'datepicker/ico_arrow_year_next_34x34.svg');
              }
            }
            .ui-datepicker-title {
              @include coTxtTitleHeadingSmall;
              margin: 0;
              a,
              a span {
                color: $color-main;
                display: inline-block;
              }
              .ui-datepicker-month {
                margin-left: 0.5rem;
              }
              a {
                &.ui-datepicker-title {
                  span {
                    color: $color-black-40;
                    display: inline-block;
                    margin: 0 0.5rem;
                  }
                }
              }
            }
          }
          table {
            border-spacing: 0;
            td {
              position: relative;
            }
          }
          .ui-datepicker-calendar {
            margin: 0.625rem 0 0 0;
            thead tr th,
            tbody tr td,
            tbody tr td .ui-state-default {
              @include coTxtBody;
              color: $color-black-80;
              text-align: center;
            }
            thead tr th,
            tbody tr td .ui-state-default {
              padding: 0.625rem 0;
            }
            thead {
              tr {
                th {
                  &.ui-datepicker-week-end {
                    color: $color-sub-03;
                    & ~ .ui-datepicker-week-end {
                      color: $color-black-80;
                    }
                  }
                }
              }
            }
            tbody {
              tr {
                td {
                  opacity: 1;
                  padding: 0;
                  width: calc(100vw / 7 - 1.5rem);
                  border: 0 none;
                  box-sizing: border-box;
                  background-color: transparent;
                  .ui-state-default {
                    @include coTxtBody;
                    text-align: center;
                    border: 0 none;
                    box-sizing: border-box;
                    background-color: transparent;
                    color: $color-black-80;
                  }
                  &.ui-datepicker-current-day {
                    box-shadow: 0 0 0 0.1875rem $color-sub-02 inset;
                    border-radius: 0.5rem;
                    .ui-state-default {
                      border-radius: 0.5rem;
                      color: $color-sub-02;
                    }
                    &.reservation-closed {
                      .ui-state-default {
                        box-shadow: none;
                      }
                    }
                  }
                  &.ui-datepicker-today {
                    position: relative;
                    background-color: $color-main;
                    border-radius: 0.5rem;
                    position: relative;
                    &:after {
                      content: '오늘';
                      position: absolute;
                      display: block;
                      width: 100%;
                      height: 1rem;
                      left: 0;
                      bottom: 0;
                      padding: 0;
                      text-align: center;
                      font-weight: 400;
                      font-size: 0.625rem;
                      line-height: 1rem;
                      color: $color-white-100;
                    }
                    .ui-state-default {
                      color: $color-white-100;
                      border: 0 none;
                    }
                    em {
                      position: absolute;
                      display: block;
                      width: 100%;
                      height: 1rem;
                      left: 0;
                      bottom: 0;
                      padding: 0;
                      text-align: center;
                      font-weight: 400;
                      font-size: 0.625rem;
                      line-height: 1rem;
                      color: $color-white-100;
                    }
                    &.reservation-closed {
                      .ui-state-default {
                        box-shadow: none;
                      }
                    }
                  }
                  &.holidays-before {
                    .ui-state-default {
                      color: $color-sub-03;
                      opacity: 0.2;
                    }
                  }
                  &.holidays-after {
                    .ui-state-default {
                      color: $color-sub-03;
                      opacity: 0.2;
                    }
                  }
                  &.ui-datepicker-week-end {
                    &.ui-state-disabled {
                      .ui-state-default {
                        color: $color-sub-03;
                        opacity: 0.2;
                      }
                    }
                    &.holidays-before {
                      .ui-state-default {
                        color: $color-sub-03;
                        opacity: 0.2;
                      }
                    }
                    &.another-month {
                      .ui-state-default {
                        color: $color-sub-03;
                        opacity: 0.2;
                      }
                    }
                    &.ui-datepicker-current-day {
                      &.another-month {
                        .ui-state-default {
                          color: $color-sub-03;
                          opacity: 0.2;
                        }
                      }
                    }
                    & ~ .ui-datepicker-week-end {
                      .ui-state-default {
                        color: $color-black-80;
                      }
                      &.reservation-closed {
                        .ui-state-default {
                          color: $color-black-20;
                        }
                      }
                      &.holidays-before {
                        .ui-state-default {
                          color: $color-sub-03;
                          opacity: 0.2;
                        }
                      }
                      &.holidays-after {
                        .ui-state-default {
                          color: $color-sub-03;
                        }
                      }
                      &.ui-datepicker-other-month {
                        .ui-state-default {
                          color: $color-black-20;
                        }
                      }
                      &.another-month {
                        .ui-state-default {
                          color: $color-black-80;
                          opacity: 1;
                        }
                        &.ui-datepicker-other-month,
                        &.ui-datepicker-unselectable {
                          .ui-state-default {
                            color: $color-black-20;
                            opacity: 1;
                          }
                          &.holidays-after {
                            .ui-state-default {
                              color: $color-sub-03;
                              opacity: 0.2;
                            }
                          }
                        }
                        &.holidays-before {
                          .ui-state-default {
                            color: $color-sub-03;
                            opacity: 0.2;
                          }
                        }
                      }
                    }
                  }
                  &.ui-state-disabled {
                    .ui-state-default {
                      color: $color-black-20;
                    }
                    a,
                    span {
                      color: $color-black-20;
                      font-size: 1rem;
                    }
                    &.ui-datepicker-week-end {
                      .ui-stat-default {
                        color: $color-sub-03;
                      }
                    }
                  }
                  &.ui-datepicker-other-month {
                    .ui-state-default {
                      opacity: 1;
                      color: $color-black-20;
                    }
                    &.ui-datepicker-week-end {
                      .ui-state-default {
                        color: $color-sub-03;
                        opacity: 0.2;
                      }
                    }
                    & ~ .ui-datepicker-other-month {
                      .ui-state-default {
                        color: $color-black-20;
                      }
                    }
                    &.holidays-after {
                      .ui-state-default {
                        color: $color-sub-03;
                        opacity: 0.2;
                      }
                    }
                    &.holidays-before {
                      .ui-state-default {
                        color: $color-black-20;
                        opacity: 1;
                      }
                    }
                    &.ui-datepicker-today {
                      background-color: transparent;
                      span {
                        box-shadow: none;
                        color: $color-black-20;
                      }
                      em {
                        color: $color-black-20;
                      }
                    }
                    &.ui-datepicker-current-day {
                      background-color: transparent;
                      span {
                        box-shadow: none;
                        color: $color-black-20;
                      }
                      em {
                        color: $color-black-20;
                      }
                    }
                  }
                  &.reservation-closed {
                    .ui-state-default {
                      color: $color-black-20;
                    }
                    &.ui-datepicker-today {
                      .ui-state-default {
                        color: $color-white-100;
                      }
                      &.ui-datepicker-current-day {
                        box-shadow: none;
                      }
                      &.ui-datepicker-other-month {
                        .ui-state-default {
                          color: $color-black-20;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .ui-datepicker-select-month,
          .ui-datepicker-select-year {
            margin: 1.25rem 0 0 0;
            table {
              tbody {
                tr {
                  td {
                    opacity: 1;
                    padding: 0;
                    width: calc(100vw / 4 - 1.5rem);
                    a {
                      @include coTxtBody;
                      color: $color-black-80;
                      text-align: center;
                      padding: 1.25rem 0;
                      border: 0 none;
                      background-color: transparent;
                    }
                    &.ui-datepicker-today {
                      a {
                        box-shadow: 0 0 0 0.1875rem $color-sub-02 inset;
                        border-radius: 0.5rem;
                        color: $color-sub-02;
                      }
                    }
                  }
                }
              }
            }
          }
          i {
            position: absolute !important;
            left: 0 !important;
            top: 0 !important;
            width: 1px !important;
            height: 1px !important;
            font-size: 1px !important;
            line-height: 1px !important;
            opacity: 0 !important;
            color: transparent !important;
            display: block !important;
          }
        }
      }
    }

    // + datepicker bottom info
    .c-datepicker-info {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: right;
      padding: 0 1.75rem;
      ul {
        position: relative;
        & ~ ul {
          margin-left: 1.25rem;
        }
        li {
          & ~ li {
            position: relative;
            @include coTxtLabelMedium;
            padding-left: 0.75rem;
            &::after {
              content: '';
              display: block;
              position: absolute;
              left: 0;
              top: 0.375rem;
              width: 0.5rem;
              height: 0.5rem;
              border-radius: 0.125rem;
            }
          }
        }
        @each $class, $color in $colors {
          &.is-#{$class} {
            li {
              color: #{$color};
              &::after {
                background-color: #{$color};
              }
            }
          }
        }
      }
    }
  }
}

.ui-datepicker-select-month,
.ui-datepicker-select-year {
  margin: 1.25rem 0 0 0;
  table {
    tbody {
      tr {
        td {
          opacity: 1;
          padding: 0;
          width: calc(100vw / 4 - 1.5rem);
          a,
          span {
            @include coTxtBody;
            color: $color-black-80;
            text-align: center;
            padding: 1.25rem 0;
            border: 0 none;
            background-color: transparent;
          }
          &.ui-datepicker-today {
            a {
              box-shadow: 0 0 0 0.1875rem $color-sub-02 inset;
              border-radius: 0.5rem;
              color: $color-sub-02;
            }
          }
        }
      }
      .ui-state-default {
        font-size: 1rem;
        font-weight: 300;
        letter-spacing: -0.0187rem;
        line-height: 1.75rem;
        color: rgba(28, 28, 28, 0.8);
        text-align: center;
        padding: 1.25rem 0;
        border: 0 none;
        background-color: transparent;
      }
    }
  }
}

.ui-datepicker-year {
  & + .ui-datepicker-month {
    position: relative;
    &:before {
      content: '.';
      margin: {
        left: -0.75rem;
        right: 0.125rem;
      }
      // font-size: $fs-title-large;
      // margin: {
      //   left: -5px;
      //   right: 6px;
      // }
    }
  }
}

.ui-datepicker-today {
  &.ui-state-disabled {
    .ui-state-default {
      color: $color-black-80 !important;
    }
  }
}
.ui-datepicker-unselectable {
  &.ui-state-disabled {
    &.ui-datepicker-current-day {
      &.ui-datepicker-today {
        .ui-state-default {
          color: $color-white-100 !important;
        }
      }
    }
  }
}
