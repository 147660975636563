.co-img-card {
  &.type-basic {
    position: relative;
    display: block;
    text-align: left;
    padding: 2.5rem 0;
    img {
      width: 100%;
      height: auto;
      display: block;
      border-radius: 0.75rem;
      overflow: hidden;
    }
    span {
      &.date,
      &.address {
        @include coTxtLabelMedium;
        color: $color-sub-02;
        display: inline-block;
        margin-top: 1rem;
      }
    }
    h2 {
      &.media-tit {
        @include coTxtBody;
        color: $color-black-80;
        // 1줄 말줄임
        width: 100%;
        @include ellipsisLine01;
      }
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 1px;
      background-color: $color-black-05;
    }
    &:first-child {
      padding-top: 0;
      &::after {
        display: none;
        width: 0;
        height: 0;
      }
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
  &.type-check {
    position: relative;
    input[type='checkbox'],
    input[type='radio'] {
      @include hide-input;
      & + label {
        position: relative;
        display: block;
        .inner {
          background-color: $color-white-100;
          img,
          ul {
            overflow: hidden;
          }
          img {
            width: 100%;
            height: auto;
            display: block;
            background: $color-black-10;
            border-top-left-radius: 0.75rem;
            border-top-right-radius: 0.75rem;
            border: 1px solid $color-black-05;
            border-bottom: 0 none;
          }
          ul {
            display: block;
            padding: 1.25rem;
            border-bottom-left-radius: 0.75rem;
            border-bottom-right-radius: 0.75rem;
            border: 1px solid $color-black-10;
            li {
              text-align: left;
              width: 100%;
              &:nth-child(1) {
                @include coTxtLabelMedium;
                color: $color-sub-02;
              }
              &:nth-child(2) {
                @include coTxtBody;
                color: $color-black-80;
                margin-top: 0.25rem;
                @include shortening-two;
              }
            }
          }
        }
        &::after {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          border-radius: 0.75rem;
          border: 0.1875rem solid transparent;
          background: linear-gradient(62deg, $color-sub-02 1%, $color-main 113%) border-box;
          -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
          mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
          mask-composite: exclude;
          box-sizing: border-box;
          z-index: 2;
          opacity: 0;
          transition: opacity $transition-3 ease-in-out;
        }
      }
      &:checked {
        & + label {
          &::after {
            opacity: 1;
          }
        }
      }
    }
  }
  &.type-tag {
    .img-card-tit,
    p {
      text-align: left;
    }
    .img-card-tit {
      @include coTxtTitleLarge;
      color: $color-black-80;
    }
    p {
      @include coTxtBody;
      color: $color-black-60;
      margin-top: 0.5rem;
      width: 100%;
      // 2줄 말줄임
      @include shortening-two;
    }
    img {
      background-color: $color-black-10;
      border: 1px solid $color-black-05;
      border-radius: 0.75rem;
      width: 100%;
      height: auto;
      display: block;
      overflow: hidden;
      margin-top: 1.25rem;
    }
    .tag-list {
      margin-top: 1.25rem;
    }
  }
}
