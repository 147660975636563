.btn-list {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  a {
    @include coTxtLabelMedium;
    color: $color-black-60;
    position: relative;
    text-align: center;
    padding: 0 0.75rem;
    white-space: nowrap;
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      width: 1px;
      height: 0.75rem;
      margin-top: -0.375rem;
      background-color: $color-black-20;
    }
    &:first-child {
      padding-left: 0;
      &::after {
        display: none;
        width: 0;
        height: 0;
      }
    }
    &:last-child {
      padding-right: 0;
    }
  }
  &::after {
    content: '';
    clear: both;
    display: block;
  }
  &.type-footer {
    margin-top: 3.75rem;
    a {
      @include coTxtLabelLarge;
      color: $color-white-80;
      padding: 0 0.625rem;
    }
    &:after {
      display: none;
      width: 0;
      height: 0;
    }
  }
}