.c-dropdown {
  position: absolute;
  left: 0;
  top: 3.5rem;
  width: 100%;
  height: auto;
  max-height: 16.25rem;
  border-radius: 0.75rem;
  display: block;
  background-color: $color-white-100;
  box-shadow: 0 0.25rem 0.625rem rgba(0, 0, 0, 0.25);
  overflow-y: auto;
  z-index: 1000;
  button {
    display: block;
    &:nth-child(even) {
      background-color: #fbfbfd;
    }
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.75rem;
    letter-spacing: -0.01875rem;
    color: $color-black-80;
    padding: 0.75rem;
    display: block;
    width: 100%;
    text-align: left;
    &:active,
    &:hover {
      background-color: $color-bg-03;
      color: $color-main;
    }
  }
}
