.co-badge {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  li {
    @include coTxtLabelMedium;
    width: auto;
    height: 1.5rem;
    border-radius: 1.25rem;
    text-align: center;
    padding: 0.125rem 0.625rem;
    &.wait {
      background-color: #dbfAd6;
      color: #12420b;
    }
    &.conf {
      background-color: $color-bg-03;
      color: $color-main;
    }
    &.end {
      background-color: $color-black-60;
      color: $color-white-100;
    }
    &.cencel {
      background-color: #ffe8d7;
      color: #643b1d;
    }
    &.change {
      background-color: #eaec8b;
      color: #6c691d;
    }
  }
}