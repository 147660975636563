@charset "UTF-8";
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video,
a {
		margin: 0;
		padding: 0;
		border: 0;
		outline: 0;
		vertical-align: baseline;
		background: transparent;
		box-sizing: border-box;
}

* {
		touch-action: manipulation;
}

h1,
h2,
h3,
h4,
h5,
h6 {
		font-size: 100%;
		font-weight: inherit;
		box-sizing: border-box;
}

html {
		text-size-adjust: none;
}

html,
body {
		background-color: #fff;
		height: 100%;
		color: #1c1c1c;
		font-size: 16px;
		font-family: NotoSansKR, HelveticaNeue-Light, AppleSDGothicNeo-Light, sans-serif;
		font-weight: 300;
		-webkit-text-size-adjust: none;
		-webkit-font-smoothing: antialiased;
}

html {
		height: 100%;
}

body {
		/* mobile viewport bug fix */
		min-height: -webkit-fill-available;
		height: 100%;
}

html,
body {
		width: 100%;
		box-sizing: inherit;
		overflow-x: hidden;
		-webkit-tap-highlight-color: transparent;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
		display: block;
}

button,
input,
optgroup,
select,
textarea {
		margin: 0;
		padding: 0;
		outline: 0;
}

input,
select,
button,
select {
		font-family: inherit;
		vertical-align: middle;
}

address,
caption,
cite,
code,
dfn {
		font-weight: normal;
		font-style: normal;
}

code,
pre {
		-moz-osx-font-smoothing: auto;
		-webkit-font-smoothing: auto;
}

button,
select {
		outline: 0;
		border: 0;
		border-radius: 0;
		margin: 0;
		padding: 0;
		cursor: pointer;
		background-color: transparent;
		appearance: none;
}

img {
		outline: 0;
		border: 0;
		vertical-align: top;
		max-width: 100%;
}

img,
video {
		height: auto;
		max-width: 100%;
}

blockquote,
q {
		quotes: none;
}
blockquote::before, blockquote::after,
q::before,
q::after {
		content: "";
}

ol,
ul {
		list-style: none;
}

fieldset {
		border: 0;
		outline: 0;
}

button {
		color: inherit;
}
button.btn-txt {
		position: relative;
}

textarea {
		color: #1c1c1c;
}
textarea:focus {
		outline: 0;
}

a {
		background-color: transparent;
		color: inherit;
		text-decoration: none;
}
a:link, a:visited, a:hover, a:active {
		text-decoration: none;
}
a:hover {
		color: inherit;
}

select {
		appearance: none;
}
select::-ms-expand {
		display: none;
}

input[type=checkbox],
input[type=radio] {
		vertical-align: baseline;
}

input,
textarea {
		max-width: 100%;
		box-sizing: border-box;
}
input::placeholder,
textarea::placeholder {
		font: 300 1rem/1.75rem "NotoSansKR";
		color: rgba(28, 28, 28, 0.4);
		letter-spacing: -0.0187rem;
}

iframe {
		border: 0;
}

table {
		border-collapse: collapse;
		border-spacing: 0;
}

td,
th {
		padding: 0;
}
td:not([align]),
th:not([align]) {
		text-align: inherit;
}

em {
		font-style: normal;
}

legend {
		display: table;
		box-sizing: border-box;
		padding: 0;
		max-width: 100%;
		color: inherit;
		white-space: normal;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
		height: auto;
		margin: 0;
		-webkit-appearance: none;
}

[type=search] {
		outline-offset: -2px;
		-webkit-appearance: textfield;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
		-webkit-appearance: none;
}

::-webkit-file-upload-button {
		font: inherit;
		-webkit-appearance: button;
}

div,
p,
span,
em,
i {
		font-style: normal;
}

*::-webkit-scrollbar, *::-webkit-scrollbar-thumb {
		display: none;
}

#container {
		width: 100%;
		height: 100%;
		box-sizing: inherit;
		overflow-x: hidden;
		padding-top: 0;
}
#container .flex-wrap {
		display: flex;
		flex-direction: column;
		height: 100%;
}
#container .flex-wrap .flex-container {
		flex-grow: 1;
}
#container .flex-wrap .flex-container.type-etc {
		display: flex;
		align-items: center;
		justify-content: center;
}
#container .flex-wrap header.header.co-header + .flex-container {
		margin-top: 5.25rem;
}

.is-loading {
		overflow: hidden;
}

@font-face {
		font-family: NotoSansKR;
		font-weight: 100;
		font-style: normal;
		src: url("../fonts/NotoSansKR-Thin.woff") format("woff"), url("../fonts/NotoSansKR-Thin.woff2") format("woff2"), url("../fonts/NotoSansKR-Thin.otf") format("opentype");
}
@font-face {
		font-family: NotoSansKR;
		font-weight: 300;
		font-style: normal;
		src: url("../fonts/NotoSansKR-Light.woff") format("woff"), url("../fonts/NotoSansKR-Light.woff2") format("woff2"), url("../fonts/NotoSansKR-Light.otf") format("opentype");
}
@font-face {
		font-family: NotoSansKR;
		font-weight: 400;
		font-style: normal;
		src: url("../fonts/NotoSansKR-Regular.woff") format("woff"), url("../fonts/NotoSansKR-Regular.woff2") format("woff2"), url("../fonts/NotoSansKR-Regular.otf") format("opentype");
}
@font-face {
		font-family: NotoSansKR;
		font-weight: 500;
		font-style: normal;
		src: url("../fonts/NotoSansKR-Medium.woff") format("woff"), url("../fonts/NotoSansKR-Medium.woff2") format("woff2"), url("../fonts/NotoSansKR-Medium.otf") format("opentype");
}
@font-face {
		font-family: NotoSansKR;
		font-weight: 700;
		font-style: normal;
		src: url("../fonts/NotoSansKR-Bold.woff") format("woff"), url("../fonts/NotoSansKR-Bold.woff2") format("woff2"), url("../fonts/NotoSansKR-Bold.otf") format("opentype");
}
@font-face {
		font-family: NotoSansKR;
		font-weight: 900;
		font-style: normal;
		src: url("../fonts/NotoSansKR-Black.woff") format("woff"), url("../fonts/NotoSansKR-Black.woff2") format("woff2"), url("../fonts/NotoSansKR-Black.otf") format("opentype");
}
.cl-main-1 {
		color: #253981 !important;
}

.cl-sub-1 {
		color: #7e8397 !important;
}

.cl-sub-2 {
		color: #2f7ad3 !important;
}

.cl-sub-3 {
		color: #ba1d3c !important;
}

.cl-sub-5 {
		color: #aacae6 !important;
}

.cl-bg-3 {
		color: #ecf1f8 !important;
}

.cl-bg-4 {
		color: #f6f6f8 !important;
}

.cl-white-1 {
		color: #fff !important;
}

.cl-white-op8 {
		color: rgba(255, 255, 255, 0.8) !important;
}

.cl-white-op7 {
		color: rgba(255, 255, 255, 0.7) !important;
}

.cl-white-op6 {
		color: rgba(255, 255, 255, 0.6) !important;
}

.cl-white-op4 {
		color: rgba(255, 255, 255, 0.4) !important;
}

.cl-white-op2 {
		color: rgba(255, 255, 255, 0.2) !important;
}

.cl-black-1 {
		color: #1c1c1c !important;
}

.cl-black-op8 {
		color: rgba(28, 28, 28, 0.8) !important;
}

.cl-black-op6 {
		color: rgba(28, 28, 28, 0.6) !important;
}

.cl-black-op4 {
		color: rgba(28, 28, 28, 0.4) !important;
}

.cl-black-op2 {
		color: rgba(28, 28, 28, 0.2) !important;
}

.cl-black-op1 {
		color: rgba(28, 28, 28, 0.1) !important;
}

.cl-black-op05 {
		color: rgba(28, 28, 28, 0.05) !important;
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
}

.c-txt-tit-head-l {
		font-size: 2rem;
		font-weight: 500;
		letter-spacing: -0.0313rem;
		line-height: 2.5rem;
}

.c-txt-tit-head-s {
		font-size: 1.5rem;
		font-weight: 500;
		letter-spacing: -0.0313rem;
		line-height: 2rem;
}

.c-txt-tit-l {
		font-size: 1.25rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
}

.c-txt-tit-s {
		font-size: 1.125rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
}

.c-txt-nomal {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
}

.c-txt-nomal-s {
		font-size: 0.75rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.5rem;
}

.c-txt-link {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
}

.c-txt-label-l {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
}

.c-txt-label-m {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
}

.c-txt-btn {
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5rem;
}

.c-txt-btn-s {
		font-size: 0.875rem;
		font-weight: 400;
		line-height: 1.25rem;
}

.ta-l {
		text-align: left !important;
}

.ta-c {
		text-align: center !important;
}

.ta-r {
		text-align: right !important;
}

.justify-cont-s {
		justify-content: flex-start !important;
}

.justify-cont-e {
		justify-content: flex-end !important;
}

.justify-cont-c {
		justify-content: center !important;
}

.justify-cont-sb {
		justify-content: space-between !important;
}

.align-item-s {
		align-items: flex-start !important;
}

.align-item-e {
		align-items: flex-end !important;
}

.align-item-c {
		align-items: center !important;
}

.fw-bold {
		font-weight: 700 !important;
}

.row {
		display: flex;
		flex-wrap: wrap;
		flex: 1 1 auto;
}

.col {
		max-width: 100%;
		flex: 1 1 0;
}

.col-1 {
		flex: 0 0 8.3333333333%;
		max-width: 8.3333333333%;
}

.col-2 {
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%;
}

.col-3 {
		flex: 0 0 25%;
		max-width: 25%;
}

.col-4 {
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
}

.col-5 {
		flex: 0 0 41.6666666667%;
		max-width: 41.6666666667%;
}

.col-6 {
		flex: 0 0 50%;
		max-width: 50%;
}

.col-7 {
		flex: 0 0 58.3333333333%;
		max-width: 58.3333333333%;
}

.col-8 {
		flex: 0 0 66.6666666667%;
		max-width: 66.6666666667%;
}

.col-9 {
		flex: 0 0 75%;
		max-width: 75%;
}

.col-10 {
		flex: 0 0 83.3333333333%;
		max-width: 83.3333333333%;
}

.col-11 {
		flex: 0 0 91.6666666667%;
		max-width: 91.6666666667%;
}

.col-12 {
		flex: 0 0 100%;
		max-width: 100%;
}

.inner-row {
		width: 100%;
		flex: 1 1 auto;
}

.in-input-row ~ .in-input-row {
		margin-top: 3.75rem;
}
input {
		color: rgba(28, 28, 28, 0.8);
}
input[type=text], input[type=password], input[type=number], input[type=search], input[type=tel] {
		outline: 0;
		border: 1px solid rgba(28, 28, 28, 0.2);
		border-radius: 1.25rem;
		padding: 0.6875rem 1.25rem;
		width: 100%;
		font-size: 1rem;
		font-family: "NotoSansKR";
		font-weight: 300;
		line-height: 1.75rem;
		letter-spacing: -0.0187rem;
}

input::placeholder {
		color: rgba(28, 28, 28, 0.4);
}

.co-btn.type02 {
		padding: 1rem 1.25rem;
		background-color: #253981;
		border-radius: 0 1.25rem 1.25rem 0;
		background: url("../images/ico/btn_overlay_01.png") #253981 right bottom/60px auto no-repeat;
}
.co-btn.type02 > span {
		line-height: 1.125rem;
}
.co-btn.type-sm {
		height: 1.75rem;
		padding: 0.25rem 0.75rem;
		width: auto;
}
.co-btn.type-sm > span {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0187rem;
		line-height: 1.25rem;
}
.co-btn.type-small {
		width: 100%;
		padding-top: 0.5rem;
		padding-right: 1.75rem;
		padding-bottom: 0.5rem;
		padding-left: 1.75rem;
}
.co-btn.type-small > span {
		font-size: 1rem;
		font-weight: 500;
		letter-spacing: -0.0187rem;
		line-height: 1.5rem;
}
.co-btn.type-input-btn > span {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0187rem;
		line-height: 1.25rem;
}

.buttons {
		display: flex;
		flex: 1;
}
.buttons .co-btn:only-child {
		width: 100%;
}

button.co-btn,
a.co-btn {
		position: relative;
		width: 100%;
		font-size: 0;
		font-family: "NotoSansKR";
		padding: 0.75rem;
		border-radius: 1.75rem;
		text-align: center;
		color: #fff;
		background-color: #253981;
		display: inline-block;
		vertical-align: top;
}
button.co-btn > span,
a.co-btn > span {
		position: relative;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5rem;
		font-family: "NotoSansKR";
}
button.co-btn > span.alt-txt,
a.co-btn > span.alt-txt {
		position: absolute;
		left: 0;
		top: 0;
		width: 1px;
		height: 1px;
		font-size: 1px;
		line-height: 1px;
		opacity: 0;
		color: transparent;
		display: block;
}
button.co-btn ~ .co-btn,
a.co-btn ~ .co-btn {
		margin-left: 1.25rem;
}
button.co-btn:disabled,
a.co-btn:disabled {
		background-color: #b9c0d9;
}
button.is-disable,
a.is-disable {
		background-color: #b9c0d9;
}
button.is-disable:disabled,
a.is-disable:disabled {
		cursor: not-allowed;
}
button.is-outline-navy-1,
a.is-outline-navy-1 {
		border-width: 1px;
		border-style: solid;
		background-color: #fff;
		border-color: #253981;
		color: #253981;
		padding: 0.6875rem;
}
button.is-outline-disable,
a.is-outline-disable {
		border-width: 1px;
		border-style: solid;
		background-color: #fff;
		border-color: #b9c0d9;
		color: #b9c0d9;
		padding: 0.6875rem;
}
button.is-prev, button.is-next,
a.is-prev,
a.is-next {
		color: #7e8397;
		background-color: rgba(28, 28, 28, 0.05);
		padding-left: 1.5rem;
}
button.is-prev > span, button.is-next > span,
a.is-prev > span,
a.is-next > span {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0187rem;
		line-height: 1.25rem;
		line-height: 1.4rem;
}
button.is-prev.is-disable, button.is-next.is-disable,
a.is-prev.is-disable,
a.is-next.is-disable {
		opacity: 0.3;
}
button.is-prev,
a.is-prev {
		padding-right: 1.5rem;
		padding-left: 0.75rem;
}
button.is-next,
a.is-next {
		padding-left: 1.5rem;
		padding-right: 0.75rem;
}

.skip {
		position: absolute;
		overflow: hidden;
		clip: rect(0 0 0 0);
		margin: -1px;
		padding: 0;
		width: 1px;
		height: 1px;
		border: 0;
		font-size: 1px;
		line-height: 1px;
		color: transparent;
}

.alt-txt {
		position: absolute !important;
		left: 0 !important;
		top: 0 !important;
		width: 1px !important;
		height: 1px !important;
		font-size: 1px !important;
		line-height: 1px !important;
		opacity: 0 !important;
		color: transparent !important;
		display: block !important;
}
.alt-txt.type-title {
		position: relative !important;
		width: 100% !important;
		left: auto !important;
		top: auto !important;
}

.btn-move-top {
		position: fixed;
		right: 1.25rem;
		bottom: 1.25rem;
		width: 2.75rem;
		height: 2.75rem;
		border-radius: 0.75rem;
		opacity: 0.9;
		background-color: #253981;
		background-image: url("../images/ico/ico_arrow_top_white_01.svg");
		background-position: center center;
		background-size: 2.125rem 2.125rem;
		background-repeat: no-repeat;
		z-index: 100;
		transition: bottom 0.3s ease-in-out;
}
.btn-move-top.hide {
		display: none;
}

.kmi-scroll,
.textarea-scroll {
		position: absolute;
		right: 0.25rem;
		top: 0.75rem;
		width: 0.25rem;
		height: calc(100% - 1.5rem);
		background-color: transparent;
		opacity: 0;
		overflow: hidden;
		z-index: 2500;
		transition: opacity 0.1s ease-in-out;
}
.kmi-scroll span,
.textarea-scroll span {
		display: block;
		position: absolute;
		right: 0;
		top: 0;
		width: 0.25rem;
		height: 2.5rem;
		background-color: rgba(28, 28, 28, 0.1);
		border-radius: 1000px;
}
.kmi-scroll.show,
.textarea-scroll.show {
		opacity: 1;
}

.c-layer-popup .modal-wrap .modal-field .kmi-scroll {
		z-index: 1997;
}

.kmi-popup.show.type-full .kmi-scroll, .kmi-popup.show.type-full-footer .kmi-scroll {
		position: fixed;
		top: 4.75rem;
		height: calc(100% - 5.5rem);
}
.kmi-popup.show .kmi-scroll {
		z-index: 2501;
}

.c-txtarea-field {
		position: relative;
}
.c-txtarea-field .textarea-scroll {
		height: calc(100% - 2.5rem);
		top: 1.25rem;
}

.c-loc {
		margin: 0;
		padding: 0;
		width: 100%;
		height: auto;
		line-height: 0;
}
.c-loc.type-right {
		text-align: right;
}

.co-list-card > li {
		white-space: initial;
}
.co-list-card[class*=circle] > li {
		position: relative;
}
.co-list-card[class*=circle] > li:before {
		content: "";
		width: 4px;
		height: 4px;
		border-radius: 50%;
		background-color: rgba(28, 28, 28, 0.2);
		display: inline-block;
		position: absolute;
}
.co-list-card.circle li {
		color: inherit;
		padding-left: 12px;
}
.co-list-card.circle li ~ li {
		margin-top: 0.75rem;
}
.co-list-card.circle li:before {
		top: 11px;
		left: 0;
}
.co-list-card.circle01 li {
		color: rgba(28, 28, 28, 0.6);
		font-size: 0.75rem;
		font-weight: 400;
		line-height: 18px;
		padding-left: 14px;
}
.co-list-card.circle01 li:before {
		top: 11px;
		left: 0;
}
.co-list-card.circle02 li {
		font-size: 14px;
		font-weight: 300;
		line-height: 30px;
		padding-left: 12px;
}
.co-list-card.circle02 li:before {
		top: 14px;
		left: 0;
}
.co-list-card.circle03 li {
		padding-left: 12px;
}
.co-list-card.circle03 li:before {
		top: 14px;
		left: 0;
}
.co-list-card.number01 > li {
		list-style: none;
		counter-increment: list-cnt01;
		position: relative;
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
}
.co-list-card.number01 > li:before {
		content: counter(list-cnt01);
		display: inline-block;
		text-align: center;
		border-radius: 50%;
		border: 1px solid rgba(28, 28, 28, 0.5);
}
.co-list-card.number02 > li {
		list-style: none;
		counter-increment: list-cnt02;
		position: relative;
		font-size: 1rem;
}
.co-list-card.number02 > li:before {
		content: counter(list-cnt02) ".";
		display: inline-block;
		text-align: left;
		line-height: 1;
		padding-right: 4px;
}

.co-article > h2 {
		font-size: 1.25rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
		margin-bottom: 1.25rem;
}
.co-article ~ .co-article {
		margin-top: 3.75rem;
}

.tit + .co-article {
		margin-top: 2.5rem;
}

.null-contents {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 1.25rem 0;
}
.null-contents img {
		width: 7rem;
		height: auto;
		opacity: 0.8;
}
.null-contents p {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		color: rgba(28, 28, 28, 0.8);
		margin-top: 0.75rem;
}
.null-contents.type-round, .null-contents.type-shadow {
		border: 1px solid rgba(28, 28, 28, 0.05);
		border-radius: 0.75rem;
		background: #fff;
		padding: 1.25rem 0;
}
.null-contents.type-shadow {
		box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
}

.co-description {
		padding: 2.5rem 0;
		position: relative;
}
.cont-box > .co-description:first-of-type {
		padding-top: 0;
}

.co-description .c-title {
		color: #2f7ad3;
		margin-bottom: 1rem;
		font-size: 1.25rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
}
.co-description .c-title + .c-list {
		margin-top: 1rem;
}
.co-description ~ .co-description:before {
		content: "";
		display: block;
		position: absolute;
		width: 100%;
		height: 1px;
		top: 0;
		left: 0;
		background-color: rgba(28, 28, 28, 0.1);
}

.c-list > li {
		margin-top: 0.5rem;
		color: rgba(28, 28, 28, 0.8);
}
.co-complete-block .box p {
		color: rgba(28, 28, 28, 0.6);
}

.c-complete-field {
		text-align: center;
}
.c-complete-field .tit {
		text-align: left;
}
.c-complete-field .cont {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
}
.c-complete-field > img {
		margin: 2.5rem auto;
		width: 10.9375rem;
		max-width: 175px;
}
.c-complete-field h2 {
		font-size: 1.25rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
		color: #2f7ad3;
}
.c-complete-field h2 + .cont {
		margin-top: 0.75rem;
		color: rgba(28, 28, 28, 0.6);
}
.c-complete-field + .box {
		margin-top: 20px;
}

.c-txt-link.lined {
		text-decoration: underline;
}

.co-btn {
		display: block;
}

.c-button-list .per-column {
		flex: 0 0 100%;
		max-width: 100%;
}
.c-button-list .per-column ~ .per-column {
		margin-top: 1.25rem;
}
.c-button-list .per-column ~ .c-input-email-group {
		margin-top: 1.25rem;
}
.c-button-list.chk-list-full > .col + .col {
		margin-top: 1.25rem;
}
.c-button-list .label-input ~ .label-input {
		margin-top: 1.75rem;
}

.c-ico-btn-type.circle {
		position: relative;
		width: 2.25rem;
		height: 2.25rem;
		background-repeat: no-repeat;
		background-position: center;
		box-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
		border-radius: 1.5rem;
}
.c-ico-btn-type.ico-person-01 {
		background-image: url("../images/ico/ico_person_01.svg");
		background-size: 20px auto;
		display: inline-block;
}

.box {
		padding-top: 2rem;
		padding-right: 1.25rem;
		padding-bottom: 2rem;
		padding-left: 1.25rem;
		background-color: rgba(28, 28, 28, 0.05);
		border-radius: 0.75rem;
}
.box em {
		font-weight: 500;
}
.box.type1 .box-txt {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: #777776;
}
.box.type1 .list {
		margin-top: 0.75rem;
}
.box.type2 .box-txt {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.6);
}
.box.type2 .box-txt + .box-txt {
		margin-top: 0.75rem;
}
.box.type3 {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
		background-color: #f6f6f8;
		padding: 1rem 1.25rem;
}
.box.type3 p ~ p {
		margin-top: 0.75rem;
}
.box.type3 .c-txt-label-l + ul {
		margin-top: 0.75rem;
}
.box.type4 {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		color: rgba(28, 28, 28, 0.6);
		padding: 1rem 1.25rem;
}
.box.type5 {
		padding: 1.25rem;
}
.box.type6 {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
		padding: 0.75rem 1.25rem;
}
.box.type6 a {
		display: inline-block;
		color: #2f7ad3;
		text-decoration: underline;
}
.box.type6 p ~ p {
		margin-top: 0.75rem;
}
.box.type7 {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		color: rgba(28, 28, 28, 0.6);
		padding: 1rem 1.25rem;
}

.list.type1 {
		display: block;
}
.list.type1 li {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: rgba(28, 28, 28, 0.6);
		position: relative;
		padding-left: 0.75rem;
		margin-top: 0.75rem;
}
.list.type1 li:first-child {
		margin-top: 0;
}
.list.type1 li::after {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: 0.5rem;
		width: 0.25rem;
		height: 0.25rem;
		border-radius: 100%;
		background-color: rgba(28, 28, 28, 0.2);
}
.list.default {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.6);
}

.t-i-menu01 {
		background-position: 0 0;
}

.ico-type01 {
		background-image: url("../images/ico/ico_sprite_01.png");
		background-size: auto 166px;
		background-repeat: no-repeat;
		width: 34px;
		height: 34px;
		display: inline-block;
		overflow: hidden;
		text-indent: 100%;
		white-space: nowrap;
		display: inline-block;
}
.ico-type01.t-i-sub_menu {
		background-position: -44px 0;
}
.ico-type01.t-i-add01 {
		background-position: -88px 0;
}
.ico-type01.t-i-add02 {
		background-position: -132px 0;
}
.ico-type01.t-i-check01 {
		background-position: -176px 0;
}
.ico-type01.t-i-check02 {
		background-position: -220px 0;
}
.ico-type01.t-i-check03 {
		background-position: -264px 0;
}
.ico-type01.t-i-date {
		background-position: -308px 0;
}
.ico-type01.t-i-date-check {
		background-position: -352px 0;
}
.ico-type01.t-i-document-search {
		background-position: -396px 0;
}
.ico-type01.t-i-arrow01 {
		background-position: -440px 0;
}
.ico-type01.t-i-arrow02 {
		background-position: -484px 0;
}
.ico-type01.t-i-arrow03 {
		background-position: -528px 0;
}
.ico-type01.t-i-arrow04 {
		background-position: -572px 0;
}
.ico-type01.t-i-arrow05 {
		background-position: -616px 0;
}
.ico-type01.t-i-close01 {
		background-position: -660px 0;
}
.ico-type01.t-i-close02 {
		background-position: -704px 0;
}
.ico-type01.t-i-search {
		background-position: -748px 0;
}
.ico-type01.t-i-home {
		background-position: -792px 0;
}
.ico-type01.t-i-nav01 {
		background-position: -836px 0;
}
.ico-type01.t-i-nav02 {
		background-position: -880px 0;
}
.ico-type01.t-i-nav03 {
		background-position: -924px 0;
}
.ico-type01.t-i-nav04 {
		background-position: -968px 0;
}
.ico-type01.t-i-nav05 {
		background-position: -1012px 0;
}
.ico-type01.t-i-logout {
		background-position: -1056px 0;
}
.ico-type01.t-i-info01 {
		background-position: -1100px 0;
}
.ico-type01.t-i-info02 {
		background-position: -1144px 0;
}
.ico-type01.t-i-info03 {
		background-position: -1188px 0;
}
.ico-type01.t-i-filter {
		background-position: -1232px 0;
}
.ico-type01.t-i-eye {
		background-position: -1276px 0;
}
.ico-type01.t-i-upload {
		background-position: -1320px 0;
}
.ico-type01.t-i-menu01-02 {
		background-position: 0 -44px;
}

.ico-type01.t-i-check01-2 {
		background-position: -176px -44px;
}

.ico-type01.t-i-check01-3 {
		background-position: -176px -88px;
}

.ico-type01.t-i-check02-2 {
		background-position: -220px -44px;
}

.ico-type01.t-i-check02-3 {
		background-position: -220px -88px;
}

.ico-type01.t-i-check03-2 {
		background-position: -264px -44px;
}

.ico-type01.t-i-date-2 {
		background-position: -308px -44px;
}

.ico-type01.t-i-date-check-2 {
		background-position: -352px -44px;
}

.ico-type01.t-i-document-search-2 {
		background-position: -395px -44px;
}

.ico-type01.t-i-arrow01-2 {
		background-position: -440px -44px;
}

.ico-type01.t-i-arrow01-3 {
		background-position: -440px -88px;
}

.ico-type01.t-i-arrow02-2 {
		background-position: -484px -44px;
}

.ico-type01.t-i-arrow02-3 {
		background-position: -484px -88px;
}

.ico-type01.t-i-arrow03-2 {
		background-position: -528px -44px;
}

.ico-type01.t-i-arrow03-3 {
		background-position: -528px -88px;
}

.ico-type01.t-i-arrow03-4 {
		background-position: -528px -132px;
}

.ico-type01.t-i-arrow04-2 {
		background-position: -4572px -44px;
}

.ico-type01.t-i-arrow04-3 {
		background-position: -4572px -88px;
}

.ico-type01.t-i-home-2 {
		background-position: -792px -44px;
}

.ico-type01.t-i-nav01-2 {
		background-position: -836px -44px;
}

.ico-type01.t-i-nav02-2 {
		background-position: -880px -44px;
}

.ico-type01.t-i-nav03-2 {
		background-position: -924px -44px;
}

.ico-type01.t-i-nav04-2 {
		background-position: -968px -44px;
}

.ico-type01.t-i-nav05-2 {
		background-position: -1012px -44px;
}

.ico-type01.t-i-info01-2 {
		background-position: -1100px -44px;
}

.ico-type01.t-i-info01-3 {
		background-position: -1100px -88px;
}

.ico-check-01 {
		width: 17px;
		height: 13px;
		background: url("../images/ico/ico_check_01.png") center/17px auto no-repeat;
		display: inline-block;
}

.c-input {
		width: 100%;
}
.c-input:focus {
		border-color: #253981;
		transition: border-color 0.3s;
}
.c-input.is-error {
		border-color: #ba1d3c;
}
.c-input + .input-err-txt, .c-input + .input-ref-txt {
		margin-top: 0.25rem;
}
.c-input:disabled {
		border-color: rgba(28, 28, 28, 0.1);
		background-color: rgba(28, 28, 28, 0.05);
		opacity: 1;
		-webkit-text-color: inherit;
}
.c-input:disabled.type-white {
		background-color: #fff;
		border-color: rgba(28, 28, 28, 0.2);
}
.c-input:disabled.type-white.is-error {
		border-color: #ba1d3c;
}
.c-input[readonly=readonly] {
		border-color: rgba(28, 28, 28, 0.1);
		background-color: rgba(28, 28, 28, 0.05);
}

.c-select + .input-ref-txt, .c-select + .input-err-txt,
.c-input-search + .input-ref-txt,
.c-input-search + .input-err-txt,
.c-input-date + .input-ref-txt,
.c-input-date + .input-err-txt,
.c-input-btn-group + .input-ref-txt,
.c-input-btn-group + .input-err-txt,
.c-txt-area + .input-ref-txt,
.c-txt-area + .input-err-txt {
		margin-top: 0.25rem;
}

.input-err-txt,
.input-ref-txt {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
}

.input-err-txt {
		color: #ba1d3c;
}

.input-ref-txt {
		color: rgba(28, 28, 28, 0.6);
}

.input-ref-txt + .input-err-txt {
		margin-top: 0.25rem;
}

.input-err-txt + .input-ref-txt {
		margin-top: 0.25rem;
}

.c-input-list .per-column {
		flex: 0 0 100%;
		max-width: 100%;
}
.c-input-list .per-column ~ .per-column {
		margin-top: 1.25rem;
}
.c-input-list .per-column ~ .c-input-email-group {
		margin-top: 1.25rem;
}
.c-input-list.chk-list-full > .col + .col {
		margin-top: 1.25rem;
}
.c-input-list .label-input ~ .label-input {
		margin-top: 1.75rem;
}
.c-input-list .label-input:only-child {
		margin-top: 1.25rem;
}

.c-input-sub-group .col ~ .col,
.c-input-sub-group .per-column ~ .per-column {
		margin-top: 0.75rem;
}
.c-input-search {
		position: relative;
		display: flex;
		align-items: stretch;
		width: 100%;
		display: flex;
		align-items: center;
		position: relative;
}
.c-input-search input[type=text] {
		flex: 1 1 auto;
		padding-right: 3.5rem;
}
.c-input-search input[type=text][readonly=readonly]:focus {
		border-color: rgba(28, 28, 28, 0.2);
		transition: none;
}
.c-input-search input[type=text]:not(.is-error):focus {
		border-color: #253981;
		transition: border-color 0.3s;
}
.c-input-search .btn-search {
		background: url("../images/ico/ico_search_01.png") 0/1.625rem no-repeat;
		position: absolute;
		right: 1.25rem;
		top: 50%;
		transform: translateY(-50%);
		width: 1.625rem;
		height: 1.625rem;
		content: "";
		display: inline-block;
		overflow: hidden;
}
.c-input-search.input-view .btn-search {
		right: 0;
}

.c-input-date {
		width: 100%;
		display: flex;
		align-items: center;
		position: relative;
}
.c-input-date input[type=text] {
		flex: 1 1 auto;
		padding-right: 3.5rem;
}
.c-input-date input[type=text] + span {
		display: inline-block;
}
.c-input-date input[type=text] + span i.date-btn {
		background: url("../images/ico/ico_date_01.png") 0/1.625rem no-repeat;
		position: absolute;
		right: 1.25rem;
		top: 50%;
		transform: translateY(-50%);
		width: 1.625rem;
		height: 1.625rem;
		content: "";
		display: inline-block;
		overflow: hidden;
}
.c-input-date input[type=text]:not(.is-error):focus {
		border-color: #253981;
		transition: border-color 0.3s;
}
.c-input-date input[type=text][readonly=readonly]:focus {
		border-color: rgba(28, 28, 28, 0.2);
		transition: none;
}

.c-select {
		position: relative;
		font-size: 0;
		width: 100%;
}
.c-select:after {
		background: url("../images/ico/ico_arrow_01.svg") 0/1.625rem no-repeat;
		position: absolute;
		right: 1.25rem;
		top: 50%;
		transform: translateY(-50%);
		width: 1.625rem;
		height: 1.625rem;
		content: "";
		display: inline-block;
}
.c-select select::ms-expand {
		display: none;
}
.c-select select {
		outline: 0;
		border: 1px solid rgba(28, 28, 28, 0.2);
		border-radius: 1.25rem;
		padding: 0.6875rem 1.25rem;
		width: 100%;
		font-size: 1rem;
		font-family: "NotoSansKR";
		font-weight: 300;
		line-height: 1.75rem;
		letter-spacing: -0.0187rem;
		color: rgba(28, 28, 28, 0.8);
}
.c-select select:focus {
		border-color: #253981;
		transition: border-color 0.3s;
}
.c-select select:disabled {
		border-color: rgba(28, 28, 28, 0.2);
		transition: none;
}
.c-select.is-error select {
		border-color: #ba1d3c;
}
.c-select.type-shadow select {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		color: rgba(28, 28, 28, 0.8);
		border: 1px solid rgba(28, 28, 28, 0.05);
		box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.08);
		border-radius: 0.75rem;
		-moz-appearance: none;
		-webkit-appearance: none;
		appearance: none;
}
.c-select.type-shadow:after {
		right: 1.25rem;
		width: 1.25rem;
		height: 1.25rem;
		background-image: url("../images/ico/ico_arrow_select_01.svg");
		background-position: center center;
		background-size: 1.25rem 1.25rem;
		background-repeat: no-repeat;
}

.c-input-unit {
		position: relative;
}
.c-input-unit input[type=text] {
		width: 100%;
		padding-right: 1.25rem;
}
.c-input-unit input[type=text][readonly=readonly]:focus {
		border-color: rgba(28, 28, 28, 0.2);
		transition: none;
}
.c-input-unit .unit {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 1.25rem;
		text-align: center;
		color: #7e8397;
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
}

.c-input-count {
		position: relative;
}
.c-input-count input[type=text],
.c-input-count input[type=number] {
		width: 100%;
		padding-right: 4.25rem;
}
.c-input-count input[type=text][readonly=readonly]:focus,
.c-input-count input[type=number][readonly=readonly]:focus {
		border-color: rgba(28, 28, 28, 0.2);
		transition: none;
}
.c-input-count input[type=text].is-error + .count,
.c-input-count input[type=number].is-error + .count {
		color: #ba1d3c;
}
.c-input-count .count {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 1.25rem;
		text-align: center;
		color: #7e8397;
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
}

.c-txtarea-field {
		height: 100%;
		width: 100%;
}

textarea,
.c-txt-area {
		display: block;
		border: 1px solid rgba(28, 28, 28, 0.2);
		height: 10rem;
		width: 100%;
		overflow-x: hidden;
		overflow-y: auto;
		border-radius: 1.25rem;
		padding: 0.75rem 1.25rem;
		color: #1c1c1c;
		font-size: 1rem;
		font-weight: 300;
		font-family: "NotoSansKR";
		padding: 0.75rem 1.25rem;
		min-width: 100%;
		color: rgba(28, 28, 28, 0.8);
		resize: none;
}
textarea.is-error,
.c-txt-area.is-error {
		border-color: #ba1d3c;
}
textarea:not(.is-error):focus,
.c-txt-area:not(.is-error):focus {
		border-color: #253981;
		transition: border-color 0.3s;
}
textarea textarea[readonly=readonly],
.c-txt-area textarea[readonly=readonly] {
		border-color: rgba(28, 28, 28, 0.2);
		transition: none;
		min-height: initial;
		height: 100%;
		overflow: auto;
}
textarea textarea[readonly=readonly]:focus,
.c-txt-area textarea[readonly=readonly]:focus {
		border-color: #253981;
		transition: border-color 0.3s;
		border: 0;
		box-shadow: none;
		transition: none;
}

.c-input-btn-group {
		display: flex;
		align-items: center;
		flex: 1 1 auto;
		flex-wrap: nowrap;
}
.c-input-btn-group .input {
		flex: 1 1 auto;
}
.c-input-btn-group .btns {
		flex-basis: 5.7rem;
		width: 5.75rem;
		flex: 0 0 5.75rem;
}
.c-input-btn-group .btns > button {
		width: 100%;
		padding: 1rem 0;
}
.c-input-btn-group input[type=text], .c-input-btn-group input[type=password], .c-input-btn-group input[type=tel], .c-input-btn-group input[type=number] {
		border-radius: 1.25rem 0 0 1.25rem;
}
.row.c-input-btn-group {
		flex-wrap: nowrap;
}

.label-input label,
.label-input .c-label-txt {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		margin-bottom: 8px;
		display: flex;
		color: rgba(28, 28, 28, 0.8);
}
.label-input label {
		position: relative;
}
.label-input label .essential {
		color: #ba1d3c;
		display: inline-block;
		margin-left: 0.125rem;
}
.label-input.type-link .label-block {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		margin-bottom: 8px;
}
.label-input.type-link .label-block label {
		display: inline-flex;
		margin-bottom: 0;
}
.label-input.type-link .label-block a {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: rgba(28, 28, 28, 0.8);
		color: #2f7ad3;
		text-decoration: underline;
}
.label-input .c-select {
		width: 100%;
}
.label-input .co-btn {
		width: 100%;
}

.label-block.type01 {
		display: flex;
		align-items: center;
		margin-bottom: 0.5rem;
}
.label-block.type01 label {
		margin-bottom: 0;
}
.label-block.type01 label + .co-tooltip {
		margin-top: 0.0625rem;
		margin-left: 0.5rem;
}

.c-input-email-group > .col {
		flex: 0 0 100%;
		max-width: 100%;
}

.c-input-address-group .input-addr-02 {
		margin-top: 0.75rem;
}

.c-chkbox,
.c-rdochk {
		display: inline-block;
		position: relative;
}
.c-chkbox input[type=checkbox],
.c-chkbox input[type=radio],
.c-rdochk input[type=checkbox],
.c-rdochk input[type=radio] {
		position: absolute;
		opacity: 0;
}
.c-chkbox input[type=checkbox] + .chk-label, .c-chkbox input[type=checkbox] + label.chk-label,
.c-chkbox input[type=radio] + .chk-label,
.c-chkbox input[type=radio] + label.chk-label,
.c-rdochk input[type=checkbox] + .chk-label,
.c-rdochk input[type=checkbox] + label.chk-label,
.c-rdochk input[type=radio] + .chk-label,
.c-rdochk input[type=radio] + label.chk-label {
		line-height: 1;
		display: flex;
		align-items: center;
		flex: 0;
}
.c-chkbox input[type=checkbox] + .chk-label i, .c-chkbox input[type=checkbox] + label.chk-label i,
.c-chkbox input[type=radio] + .chk-label i,
.c-chkbox input[type=radio] + label.chk-label i,
.c-rdochk input[type=checkbox] + .chk-label i,
.c-rdochk input[type=checkbox] + label.chk-label i,
.c-rdochk input[type=radio] + .chk-label i,
.c-rdochk input[type=radio] + label.chk-label i {
		cursor: pointer;
		display: inline-block;
		background-repeat: no-repeat;
}
.c-chkbox input[type=checkbox] + .chk-label > span, .c-chkbox input[type=checkbox] + label.chk-label > span,
.c-chkbox input[type=radio] + .chk-label > span,
.c-chkbox input[type=radio] + label.chk-label > span,
.c-rdochk input[type=checkbox] + .chk-label > span,
.c-rdochk input[type=checkbox] + label.chk-label > span,
.c-rdochk input[type=radio] + .chk-label > span,
.c-rdochk input[type=radio] + label.chk-label > span {
		margin-left: 8px;
		color: rgba(28, 28, 28, 0.4);
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
}

.co-chk-list.type01 {
		margin-left: -0.625rem;
		margin-right: -0.625rem;
}
.co-chk-list.type01 > .c-rdochk,
.co-chk-list.type01 > .c-chkbox {
		padding-left: 0.625rem;
		padding-right: 0.625rem;
}
.co-chk-list.type02 .c-rdochk {
		margin-left: 1.25rem;
}
.co-chk-list.type02 .c-rdochk:first-child {
		margin-left: 0;
}

.c-chkbox.type01 input[type=checkbox] {
		top: 0;
		left: 0;
		width: 24px;
		height: 24px;
}
.c-chkbox.type01 input[type=checkbox] + .chk-label i, .c-chkbox.type01 input[type=checkbox] + label.chk-label i {
		cursor: pointer;
		display: inline-block;
		width: 24px;
		min-width: 24px;
		height: 24px;
		background-image: url("../images/ico/ico_chkbox_sprite_01.png");
		background-position: -10px -10px;
		background-size: 44px auto;
}
.c-chkbox.type01 input[type=checkbox]:checked + .chk-label i {
		background-position-y: -44px;
}
.c-chkbox.type01 input[type=checkbox]:checked + .chk-label span {
		color: #253981;
}
.c-chkbox.type01 input[type=checkbox]:checked:disabled + .chk-label i {
		background-position-y: -112px;
}
.c-chkbox.type01 input[type=checkbox]:disabled + .chk-label i {
		background-position-y: -78px;
}
.c-chkbox.type-rdo input[type=radio] {
		top: 0;
		left: 0;
		width: 24px;
		height: 24px;
}
.c-chkbox.type-rdo input[type=radio] + .chk-label i, .c-chkbox.type-rdo input[type=radio] + label.chk-label i {
		cursor: pointer;
		display: inline-block;
		width: 24px;
		min-width: 24px;
		height: 24px;
		background-image: url("../images/ico/ico_chkbox_sprite_01.png");
		background-position: -10px -10px;
		background-size: 44px auto;
}
.c-chkbox.type-rdo input[type=radio]:checked + .chk-label i {
		background-position-y: -44px;
}
.c-chkbox.type-rdo input[type=radio]:checked + .chk-label span {
		color: #253981;
}
.c-chkbox.type-rdo input[type=radio]:checked:disabled + .chk-label i {
		background-position-y: -112px;
}
.c-chkbox.type-rdo input[type=radio]:disabled + .chk-label i {
		background-position-y: -78px;
}

.c-rdochk.type01 input[type=radio] {
		top: 0;
		left: 0;
		width: 20px;
		height: 20px;
}
.c-rdochk.type01 input[type=radio] + .chk-label i, .c-rdochk.type01 input[type=radio] + label.chk-label i {
		cursor: pointer;
		display: inline-block;
		width: 20px;
		height: 20px;
		background-image: url("../images/ico/ico_radio_sprite_01.png");
		background-position: -10px -10px;
		background-size: 40px auto;
}
.c-rdochk.type01 input[type=radio]:checked + .chk-label i {
		background-position-y: -40px;
}
.c-rdochk.type01 input[type=radio]:checked + .chk-label span {
		color: #253981;
}
.c-rdochk.type01 input[type=radio]:checked:disabled + .chk-label i {
		background-position-y: -100px;
}
.c-rdochk.type01 input[type=radio]:disabled + .chk-label i {
		background-position-y: -70px;
}

.btn-address .type-input-btn {
		padding: 1rem 0;
		max-height: 52px;
		line-height: 1;
		text-align: center;
}
.btn-address .type-input-btn > span {
		color: #fff;
}

.c-tab {
		position: relative;
		display: flex;
		align-items: center;
}
.c-tab li {
		position: relative;
}
.c-tab li input[type=radio] {
		position: absolute;
		left: 0;
		top: 0;
		width: 1px;
		height: 1px;
		background-color: transparent;
		color: transparent;
		font-size: 1px;
		line-height: 1px;
		opacity: 0;
}
.c-tab::-webkit-scrollbar, .c-tab::-webkit-scrollbar-thumb {
		display: none;
}
.c-tab.type-basic {
		justify-content: center;
}
.c-tab.type-basic li {
		flex-grow: 1;
		text-align: center;
}
.c-tab.type-basic li input[type=radio] + label {
		position: relative;
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		color: rgba(28, 28, 28, 0.6);
		text-align: center;
		display: block;
		width: 100%;
		padding: 0 0 0.5rem;
		box-sizing: border-box;
		transition: color 0.3s ease-in-out;
}
.c-tab.type-basic li input[type=radio]:checked + label {
		color: #253981;
}
.c-tab.type-basic::before, .c-tab.type-basic::after {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		bottom: 0;
}
.c-tab.type-basic::after {
		height: 2px;
		background-color: #253981;
		z-index: 2;
		transition: left 0.1s ease-in;
}
.c-tab.type-basic::before {
		width: 100%;
		height: 1px;
		background-color: #7e8397;
		z-index: 1;
}
.c-tab.type-basic.tab-len-2::after {
		width: 50%;
}
.c-tab.type-basic.tab-active-0::after {
		left: 0;
}
.c-tab.type-basic.tab-active-1::after {
		left: 50%;
}
.c-tab.type-slide {
		justify-content: flex-start;
		overflow-x: auto;
		overflow-y: hidden;
		width: 100%;
}
.c-tab.type-slide li {
		margin-left: 1.25rem;
}
.c-tab.type-slide li input[type=radio] + label {
		padding-top: 0.5rem;
		padding-right: 1rem;
		padding-bottom: 0.5rem;
		padding-left: 1rem;
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		box-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
		border-radius: 1.5rem;
		white-space: nowrap;
		display: block;
		color: #7e8397;
		background-color: #fff;
		background-image: none;
		transition: background-color 0.3s ease-in-out, background-image 0.3s ease-in-out, color 0.3s ease-in-out;
}
.c-tab.type-slide li input[type=radio]:checked + label {
		box-shadow: 0.25rem 0.25rem 0.5rem rgba(37, 57, 129, 0.2);
		color: #fff;
		background-color: #253981;
		background-image: url("../images/ico/tab_overlay_01.png");
		background-position: right bottom;
		background-size: 1.875rem 1.25rem;
		background-repeat: no-repeat;
}
.c-tab.type-slide li a.tab-link {
		padding-top: 0.5rem;
		padding-right: 1rem;
		padding-bottom: 0.5rem;
		padding-left: 1rem;
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		box-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
		border-radius: 1.5rem;
		white-space: nowrap;
		display: block;
		color: #7e8397;
		background-color: #fff;
		background-image: none;
}
.c-tab.type-slide li > a {
		padding-top: 0.5rem;
		padding-right: 1rem;
		padding-bottom: 0.5rem;
		padding-left: 1rem;
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		color: #7e8397;
		background: #fff;
		box-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
		border-radius: 1.5rem;
		white-space: nowrap;
		display: block;
}
.c-tab.type-slide li > a.active {
		color: #fff;
		background: url("../images/ico/tab_overlay_01.png") #253981 right bottom/30px auto no-repeat;
		box-shadow: 0.25rem 0.25rem 0.5rem rgba(37, 57, 129, 0.2);
}
.c-tab.type-slide li > a.is-disable {
		pointer-events: none;
		background: rgba(28, 28, 28, 0.05);
		box-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}
.c-tab.type-slide li > a.is-disable span {
		color: rgba(28, 28, 28, 0.6);
}
.c-tab.type-slide li:first-child {
		margin-left: 0;
}

.tab-panel {
		display: none;
}
.tab-panel.show {
		display: block;
}

.center-block {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
}
.center-block.center-type {
		margin: 0 auto;
		text-align: center;
}

.btn-list {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
}
.btn-list a {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: rgba(28, 28, 28, 0.6);
		position: relative;
		text-align: center;
		padding: 0 0.75rem;
		white-space: nowrap;
}
.btn-list a::after {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: 50%;
		width: 1px;
		height: 0.75rem;
		margin-top: -0.375rem;
		background-color: rgba(28, 28, 28, 0.2);
}
.btn-list a:first-child {
		padding-left: 0;
}
.btn-list a:first-child::after {
		display: none;
		width: 0;
		height: 0;
}
.btn-list a:last-child {
		padding-right: 0;
}
.btn-list::after {
		content: "";
		clear: both;
		display: block;
}
.btn-list.type-footer {
		margin-top: 3.75rem;
}
.btn-list.type-footer a {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		color: rgba(255, 255, 255, 0.8);
		padding: 0 0.625rem;
}
.btn-list.type-footer:after {
		display: none;
		width: 0;
		height: 0;
}

.c-dropdown {
		position: absolute;
		left: 0;
		top: 3.5rem;
		width: 100%;
		height: auto;
		max-height: 16.25rem;
		border-radius: 0.75rem;
		display: block;
		background-color: #fff;
		box-shadow: 0 0.25rem 0.625rem rgba(0, 0, 0, 0.25);
		overflow-y: auto;
		z-index: 1000;
}
.c-dropdown button {
		display: block;
		font-weight: 300;
		font-size: 1rem;
		line-height: 1.75rem;
		letter-spacing: -0.01875rem;
		color: rgba(28, 28, 28, 0.8);
		padding: 0.75rem;
		display: block;
		width: 100%;
		text-align: left;
}
.c-dropdown button:nth-child(even) {
		background-color: #fbfbfd;
}
.c-dropdown button:active, .c-dropdown button:hover {
		background-color: #ecf1f8;
		color: #253981;
}

.terms {
		margin-top: 2.5rem;
}
.terms .c-chkbox {
		display: block;
}
.terms .c-chkbox input[type=checkbox] + label {
		display: inline-flex;
}
.terms.type-basic p {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: rgba(28, 28, 28, 0.4);
		margin-top: 0.75rem;
}
.terms.type-box .terms-box {
		position: relative;
		padding-top: 0;
		padding-right: 0;
		padding-bottom: 0;
		padding-left: 0;
		background-color: rgba(28, 28, 28, 0.05);
		width: 100%;
		max-height: 7.5rem;
		border-radius: 1.25rem;
		border: 1px solid rgba(28, 28, 28, 0.2);
		margin-top: 0.75rem;
		overflow-x: hidden;
		overflow-y: hidden;
}
.terms.type-box .terms-box .inner {
		position: relative;
		max-height: 7.5rem;
		overflow-y: auto;
		padding: 0.75rem 1.25rem;
		background-color: transparent;
		overflow-y: auto;
}
.terms.type-box .terms-box .inner .terms-row {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: rgba(28, 28, 28, 0.6);
}
.terms.type-box .terms-box .inner .terms-row h2,
.terms.type-box .terms-box .inner .terms-row h3,
.terms.type-box .terms-box .inner .terms-row h4,
.terms.type-box .terms-box .inner .terms-row p,
.terms.type-box .terms-box .inner .terms-row span,
.terms.type-box .terms-box .inner .terms-row .terms-ord-list li {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		margin-top: 0;
}
.terms.type-box .terms-box .inner .terms-row .co-list-card > li {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
}
.terms.type-box .terms-box .inner .terms-row .co-list-card > li:before {
		width: 0.75rem;
		height: 0.75rem;
		font-size: 0.625rem;
		line-height: 0.75rem;
		margin-right: 0.125rem;
}
.terms.type-box .terms-box .inner::-webkit-scrollbar, .terms.type-box .terms-box .inner::-webkit-scrollbar-thumb {
		display: none;
}
.terms.type-box .terms-box .terms_scroll {
		position: absolute;
		right: 0.25rem;
		top: 0.75rem;
		width: 0.25rem;
		height: calc(100% - 1.5rem);
		background-color: transparent;
		opacity: 0;
		overflow: hidden;
		transition: opacity 0.1s ease-in-out;
}
.terms.type-box .terms-box .terms_scroll span {
		display: block;
		position: absolute;
		right: 0;
		top: 0;
		width: 0.25rem;
		height: 2.5rem;
		background-color: rgba(28, 28, 28, 0.1);
		border-radius: 1000px;
}
.terms.type-box .terms-box .terms_scroll.show {
		opacity: 1;
}
.terms .terms-box + .chk-list {
		margin-top: 0.75rem;
}
.terms .chk-list > .c-chkbox ~ .c-chkbox {
		margin-top: 0.75rem;
}
.terms .co-terms-tit {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		color: rgba(28, 28, 28, 0.4);
}
.terms .co-terms-tit + .terms-box {
		margin-top: 0.75rem;
}

.terms-block .c-terms-section > h1 {
		font-size: 1.5rem;
		font-weight: 500;
		letter-spacing: -0.0313rem;
		line-height: 2rem;
}
.terms-block .c-terms-section ~ .c-terms-section {
		margin-top: 0;
}
.terms-block .c-terms-section h1,
.terms-block .c-terms-section h2,
.terms-block .c-terms-section h3 {
		margin-top: 0;
		padding-top: 0;
}

.terms-box .c-terms-section h1,
.terms-box .c-terms-section h2,
.terms-box .c-terms-section h3,
.terms-box .c-terms-section p,
.terms-box .c-terms-section span,
.terms-box .c-terms-section li {
		color: rgba(28, 28, 28, 0.6);
}
.terms-box hr {
		border: 0;
		height: 1px;
		background-color: #e9e9e9;
		margin: 1rem 0;
}
.terms-box b {
		color: #494949;
		margin-bottom: 0.25rem;
		font-weight: 400;
}

.rv-block .c-terms-section h1,
.rv-block .c-terms-section h2,
.rv-block .c-terms-section h3 {
		padding-top: 0;
}

.co-terms-tbl {
		margin-top: 1rem;
		margin-bottom: 1rem;
}
.co-terms-tbl caption {
		position: absolute;
		overflow: hidden;
		clip: rect(0 0 0 0);
		margin: -1px;
		padding: 0;
		width: 1px;
		height: 1px;
		border: 0;
		font-size: 1px;
		line-height: 1px;
		color: transparent;
}
.co-terms-tbl thead th {
		padding: 4px;
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		border-top: 1px solid #e9e9e9;
		color: #494949;
		text-align: center;
}
.co-terms-tbl tbody {
		border-top: 2px solid #d2d2d1;
}
.co-terms-tbl tbody th,
.co-terms-tbl tbody td {
		padding: 4px 0;
		border-bottom: 1px solid #e9e9e9;
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: rgba(28, 28, 28, 0.6);
}
.co-terms-tbl tbody th {
		text-align: center;
}
.co-terms-tbl tbody th[scope=col] {
		color: rgba(28, 28, 28, 0.8);
}

.terms-field .c-terms-section h2 {
		font-size: 1.25rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
}
.terms-field .c-terms-section h2 ~ h3 {
		margin-top: 20px;
}
.terms-field .c-terms-section h2,
.terms-field .c-terms-section h3 {
		margin-top: 2.5rem;
}
.terms-field .c-terms-section ~ .c-terms-section {
		margin-top: 3.75rem;
}
.terms-field .terms-ord-list + h3 {
		margin-top: 2.5rem;
}
.terms-field .terms-ord-list {
		margin-top: 0.25rem;
}

.card_01 {
		position: relative;
}
.card_01 input[type=checkbox],
.card_01 input[type=radio] {
		position: absolute;
		left: 0;
		top: 0;
		width: 1px;
		height: 1px;
		background-color: transparent;
		color: transparent;
		font-size: 1px;
		line-height: 1px;
		opacity: 0;
}
.card_01 input[type=checkbox] + label,
.card_01 input[type=radio] + label {
		background-color: #fff;
		padding: 0.1875rem;
		border: 1px solid rgba(28, 28, 28, 0.05);
		box-sizing: border-box;
		box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
		border-radius: 1.25rem;
		display: flex;
		flex-direction: column;
		overflow: hidden;
}
.card_01 input[type=checkbox] + label .inner,
.card_01 input[type=radio] + label .inner {
		position: relative;
		border-radius: 1.125rem;
		background-color: #fff;
		padding-top: 0.75rem;
		padding-right: 1rem;
		padding-bottom: 0.75rem;
		padding-left: 1rem;
}
.card_01 input[type=checkbox] + label .inner ul.case,
.card_01 input[type=radio] + label .inner ul.case {
		display: flex;
		align-items: center;
		justify-content: space-between;
}
.card_01 input[type=checkbox] + label .inner ul.case li.ico-checkbox,
.card_01 input[type=radio] + label .inner ul.case li.ico-checkbox {
		width: 1.25rem;
		height: 1.25rem;
		background-image: url("../images/ico/ico_checkbox_sub_card_20x20.svg");
		background-size: 1.25rem 1.25rem;
		background-position: center center;
		background-repeat: no-repeat;
		transition: background-image 0.3s ease-in-out;
}
.card_01 input[type=checkbox] + label .inner ul.case li:first-child,
.card_01 input[type=radio] + label .inner ul.case li:first-child {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: #2f7ad3;
}
.card_01 input[type=checkbox] + label .inner ul.profile,
.card_01 input[type=radio] + label .inner ul.profile {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 0.25rem;
}
.card_01 input[type=checkbox] + label .inner ul.profile li,
.card_01 input[type=radio] + label .inner ul.profile li {
		font-size: 1.25rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
}
.card_01 input[type=checkbox] + label .inner ul.profile li ~ li,
.card_01 input[type=radio] + label .inner ul.profile li ~ li {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: rgba(28, 28, 28, 0.6);
}
.card_01 input[type=checkbox] + label .inner p,
.card_01 input[type=radio] + label .inner p {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: rgba(28, 28, 28, 0.6);
		margin-top: 0.75rem;
}
.card_01 input[type=checkbox] + label::before,
.card_01 input[type=radio] + label::before {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
		border: 3px solid transparent;
		border-radius: 1.25rem;
		background-image: linear-gradient(to bottom, #fff, #fff), linear-gradient(65deg, #2f7ad3 1%, #253981 113%);
		background-origin: border-box;
		background-clip: content-box, border-box;
		border-radius: 1.25rem;
}
.card_01 input[type=checkbox]:checked + label,
.card_01 input[type=radio]:checked + label {
		background-color: transparent;
		border-color: transparent;
}
.card_01 input[type=checkbox]:checked + label .inner ul.case li.ico-checkbox,
.card_01 input[type=radio]:checked + label .inner ul.case li.ico-checkbox {
		background-image: url("../images/ico/ico_checkbox_sub_card_20x20_default_on.svg");
}
.card_01 input[type=checkbox]:checked + label::before,
.card_01 input[type=radio]:checked + label::before {
		opacity: 1;
}

.card_02 {
		position: relative;
}
.card_02 input[type=checkbox] {
		position: absolute;
		left: 0;
		top: 0;
		width: 1px;
		height: 1px;
		background-color: transparent;
		color: transparent;
		font-size: 1px;
		line-height: 1px;
		opacity: 0;
}
.card_02 input[type=checkbox] + label {
		background-color: #fff;
		padding: 0.1875rem;
		border: 1px solid rgba(28, 28, 28, 0.05);
		box-sizing: border-box;
		box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
		border-radius: 1.25rem;
		display: block;
		overflow: hidden;
}
.card_02 input[type=checkbox] + label .inner {
		position: relative;
		border-radius: 1.125rem;
		background-color: #fff;
		padding: 0.75rem 1rem;
}
.card_02 input[type=checkbox] + label .inner p {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		color: #1c1c1c;
}
.card_02 input[type=checkbox] + label .inner ul {
		display: flex;
		align-items: center;
		justify-content: space-between;
}
.card_02 input[type=checkbox] + label .inner ul li.price {
		display: flex;
		align-items: center;
}
.card_02 input[type=checkbox] + label .inner ul li.price span {
		font-size: 1.25rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
		color: #2f7ad3;
}
.card_02 input[type=checkbox] + label .inner ul li.price em {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.6);
		margin-left: 0.25rem;
}
.card_02 input[type=checkbox] + label .inner ul li.icon {
		display: flex;
		align-items: center;
}
.card_02 input[type=checkbox] + label .inner ul li.icon .ico-document,
.card_02 input[type=checkbox] + label .inner ul li.icon .ico-checkbox {
		display: block;
		width: 2rem;
		height: 2rem;
		background-size: 2rem 2rem;
		background-position: center center;
		background-repeat: no-repeat;
}
.card_02 input[type=checkbox] + label .inner ul li.icon .ico-document {
		background-image: url("../images/ico/ico_card_document_01.svg");
}
.card_02 input[type=checkbox] + label .inner ul li.icon .ico-checkbox {
		background-image: url("../images/ico/ico_card_checkbox_01.svg");
		margin-left: 0.75rem;
		transition: background-image 0.3s ease-in-out;
}
.card_02 input[type=checkbox] + label .inner ul.case li.ico-checkbox {
		width: 1.25rem;
		height: 1.25rem;
		background-image: url("../images/ico/ico_checkbox_sub_card_20x20.svg");
		background-size: 1.25rem 1.25rem;
		background-position: center center;
		background-repeat: no-repeat;
		transition: background-image 0.3s ease-in-out;
}
.card_02 input[type=checkbox] + label .inner ul.case li:first-child {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: rgba(28, 28, 28, 0.6);
}
.card_02 input[type=checkbox] + label .inner ul.case ~ ul {
		margin-top: 0.25rem;
}
.card_02 input[type=checkbox] + label::before {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
		border: 3px solid transparent;
		border-radius: 1.25rem;
		background-image: linear-gradient(to bottom, #fff, #fff), linear-gradient(65deg, #2f7ad3 1%, #253981 113%);
		background-origin: border-box;
		background-clip: content-box, border-box;
		border-radius: 1.25rem;
}
.card_02 input[type=checkbox]:checked + label {
		background-color: transparent;
		border-color: transparent;
}
.card_02 input[type=checkbox]:checked + label .inner ul.case li.ico-checkbox {
		background-image: url("../images/ico/ico_checkbox_sub_card_20x20_on.svg");
}
.card_02 input[type=checkbox]:checked + label::before {
		opacity: 1;
}

.card_03 {
		position: relative;
		display: block;
		background: #ffffff;
		box-shadow: 0.125rem 0.125rem 0.625rem rgba(0, 0, 0, 0.1);
		border-radius: 2rem;
		overflow: hidden;
		padding-top: 1.25rem;
		padding-right: 1.25rem;
		padding-bottom: 1.25rem;
		padding-left: 1.25rem;
}
.card_03.type-link {
		padding-right: 3.625rem;
		background-image: url("../images/ico/ico_arrow_right_01_34x34.svg");
		background-position: right 0.75rem center;
		background-size: 2.125rem 2.125rem;
		background-repeat: no-repeat;
}
.card_03.type-link p {
		font-size: 1.25rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.2);
}
.card_03.type-link p ~ p {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		color: rgba(28, 28, 28, 0.6);
		margin-top: 0.25rem;
}
.card_03.type-link p ~ p span {
		color: #2f7ad3;
		display: inline-block;
		margin-right: 0.25rem;
}
.card_03.type-card {
		padding-bottom: 0.5rem;
}
.card_03.type-card p,
.card_03.type-card span {
		display: flex;
		align-items: center;
		justify-content: flex-start;
}
.card_03.type-card p em {
		font-size: 1.25rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
		color: #253981;
		position: relative;
}
.card_03.type-card p em ~ em {
		padding-left: 1.375rem;
}
.card_03.type-card p em ~ em::after {
		content: "/";
		display: block;
		position: absolute;
		left: 0.5rem;
		top: 0.125rem;
		width: 0.375rem;
		height: 1.5rem;
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		color: rgba(28, 28, 28, 0.4);
}
.card_03.type-card span,
.card_03.type-card span em {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
}
.card_03.type-card span {
		color: rgba(28, 28, 28, 0.4);
		padding-bottom: 0.75rem;
}
.card_03.type-card span em {
		color: #7e8397;
		margin-right: 0.25rem;
}
.card_03.type-card ul.co-badge {
		margin-top: 0.5rem;
		margin-left: -0.375rem;
		width: calc(100% + 0.75rem);
}
.card_03.type-card ul.co-badge li {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		background-color: #ecf1f8;
		color: #253981;
		padding: 0.125rem 0.625rem;
		height: 1.75rem;
		margin-top: 0;
		margin-right: 0.375rem;
		margin-bottom: 0.75rem;
		margin-left: 0.375rem;
}

.card_04 {
		width: 16.25rem;
		border-radius: 1.25rem;
		background-color: #fff;
		padding: 0.1875rem;
		box-shadow: 0.125rem 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.1);
		overflow: hidden;
}
.card_04.swiper-slide {
		position: relative;
}
.card_04.swiper-slide::before {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
		border: 3px solid transparent;
		border-radius: 1.25rem;
		background-image: linear-gradient(to bottom, #fff, #fff), linear-gradient(65deg, #2f7ad3 1%, #253981 113%);
		background-origin: border-box;
		background-clip: content-box, border-box;
		transition: none;
}
.card_04.swiper-slide.swiper-slide-active::before {
		opacity: 1;
}
.card_04 .inner {
		position: relative;
		border-radius: 1.125rem;
		background-color: #fff;
		padding: 1.25rem;
}
.card_04 .inner p {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: break-word;
}
.card_04 .inner .hospital-date {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		color: #2f7ad3;
		text-align: left;
}
.card_04 .inner .hospital-name {
		font-size: 1.25rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
		margin-top: 0.25rem;
}
.card_04 .inner .badges {
		width: max-content;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		width: 100%;
		margin-top: 1.25rem;
}
.card_04 .inner .badges span {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: break-word;
		color: #253981;
		padding: 0.125rem 0.625rem;
		background-color: #ecf1f8;
		border-radius: 1.25rem;
}

.card_05 {
		position: relative;
}
.card_05 input[type=checkbox],
.card_05 input[type=radio] {
		position: absolute;
		left: 0;
		top: 0;
		width: 1px;
		height: 1px;
		background-color: transparent;
		color: transparent;
		font-size: 1px;
		line-height: 1px;
		opacity: 0;
}
.card_05 input[type=checkbox] + label,
.card_05 input[type=radio] + label {
		background-color: #fff;
		padding: 0.1875rem;
		border: 1px solid rgba(28, 28, 28, 0.05);
		box-sizing: border-box;
		box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
		border-radius: 1.25rem;
		display: block;
		overflow: hidden;
}
.card_05 input[type=checkbox] + label .inner,
.card_05 input[type=radio] + label .inner {
		position: relative;
		border-radius: 1.125rem;
		background-color: #fff;
		padding: 1rem 1.25rem;
}
.card_05 input[type=checkbox] + label .inner p,
.card_05 input[type=radio] + label .inner p {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
}
.card_05 input[type=checkbox] + label .inner p span,
.card_05 input[type=radio] + label .inner p span {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		color: #1c1c1c;
		padding-right: 1rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
}
.card_05 input[type=checkbox] + label .inner p em,
.card_05 input[type=radio] + label .inner p em {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: #fff;
		background-color: #253981;
		display: inline-block;
		padding: 0 0.5rem;
		border-radius: 0.5rem;
		white-space: nowrap;
}
.card_05 input[type=checkbox] + label .inner p + ul,
.card_05 input[type=radio] + label .inner p + ul {
		margin-top: 0.75rem;
		position: relative;
}
.card_05 input[type=checkbox] + label .inner .co-caption-txt,
.card_05 input[type=radio] + label .inner .co-caption-txt {
		font-size: 0.75rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.5rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: block;
}
.card_05 input[type=checkbox] + label .inner ul,
.card_05 input[type=radio] + label .inner ul {
		display: flex;
		align-items: center;
		justify-content: space-between;
}
.card_05 input[type=checkbox] + label .inner ul li.price,
.card_05 input[type=radio] + label .inner ul li.price {
		display: flex;
		align-items: center;
}
.card_05 input[type=checkbox] + label .inner ul li.price span,
.card_05 input[type=radio] + label .inner ul li.price span {
		font-size: 1.25rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
		color: #2f7ad3;
}
.card_05 input[type=checkbox] + label .inner ul li.price em,
.card_05 input[type=radio] + label .inner ul li.price em {
		font-size: 1.25rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.4);
		margin-left: 0.25rem;
}
.card_05 input[type=checkbox] + label .inner ul li.icon,
.card_05 input[type=radio] + label .inner ul li.icon {
		display: flex;
		align-items: center;
}
.card_05 input[type=checkbox] + label .inner ul li.icon .ico-document,
.card_05 input[type=checkbox] + label .inner ul li.icon .ico-checkbox,
.card_05 input[type=radio] + label .inner ul li.icon .ico-document,
.card_05 input[type=radio] + label .inner ul li.icon .ico-checkbox {
		display: block;
		width: 2rem;
		height: 2rem;
		background-size: 2rem 2rem;
		background-position: center center;
		background-repeat: no-repeat;
}
.card_05 input[type=checkbox] + label .inner ul li.icon .ico-document,
.card_05 input[type=radio] + label .inner ul li.icon .ico-document {
		background-image: url("../images/ico/ico_card_document_01.svg");
}
.card_05 input[type=checkbox] + label .inner ul li.icon .ico-checkbox,
.card_05 input[type=radio] + label .inner ul li.icon .ico-checkbox {
		background-image: url("../images/ico/ico_card_checkbox_01.svg");
		margin-left: 0.75rem;
		transition: background-image 0.3s ease-in-out;
}
.card_05 input[type=checkbox] + label::before,
.card_05 input[type=radio] + label::before {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
		border: 3px solid transparent;
		border-radius: 1.25rem;
		background-image: linear-gradient(to bottom, #fff, #fff), linear-gradient(65deg, #2f7ad3 1%, #253981 113%);
		background-origin: border-box;
		background-clip: content-box, border-box;
		border-radius: 1.25rem;
}
.card_05 input[type=checkbox]:checked + label,
.card_05 input[type=radio]:checked + label {
		background-color: transparent;
		border-color: transparent;
}
.card_05 input[type=checkbox]:checked + label .inner ul li.icon .ico-checkbox,
.card_05 input[type=radio]:checked + label .inner ul li.icon .ico-checkbox {
		background-image: url("../images/ico/ico_card_checkbox_01_on.svg");
}
.card_05 input[type=checkbox]:checked + label::before,
.card_05 input[type=radio]:checked + label::before {
		opacity: 1;
}

.card_06 {
		background: #fff;
		border: 1px solid rgba(28, 28, 28, 0.05);
		box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
		border-radius: 0.75rem;
		padding: 0.75rem 1.25rem;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: flex-start;
}
.card_06 li {
		flex-grow: 1;
		width: 100%;
}
.card_06 li .card-tit {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: #2f7ad3;
		display: flex;
		align-items: center;
		justify-content: flex-start;
}
.card_06 li .card-tit .co-tooltip {
		margin-left: 0.5rem;
}
.card_06 li ~ li {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
}
.card_06 li ~ li .card-res {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
		padding-right: 0.25rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
}
.card_06 li ~ li .co-btn {
		background: #ecf1f8;
		border-radius: 0.5rem;
		border: 0 none;
		white-space: nowrap;
}
.card_06 li ~ li .co-btn span {
		color: #253981;
}
.card_06 ~ .card_06 {
		margin-top: 0.75rem;
}

.card_07 {
		display: block;
		padding: 0.75rem 1.25rem;
		width: 100%;
		height: 4.5rem;
		background-color: #fff;
		border: 1px solid rgba(28, 28, 28, 0.05);
		box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.16);
		border-radius: 0.75rem;
}
.card_07 ul {
		display: flex;
		align-items: center;
		justify-content: space-between;
}
.card_07 ul li {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
}
.card_07 ul li.sub-tit {
		color: #2f7ad3;
}
.card_07 ul li.date {
		color: rgba(28, 28, 28, 0.4);
}
.card_07 ul li.date span {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: rgba(28, 28, 28, 0.4);
}
.card_07 .card-tit {
		color: rgba(28, 28, 28, 0.8);
		font-size: 0.875rem;
		line-height: 1.5rem;
		font-weight: 400;
		font-style: normal;
		text-align: left;
		margin-top: 0.25rem;
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
}
.card_07 ~ .card_07 {
		margin-top: 1.25rem;
}

.card_08 {
		position: relative;
		background: #fff;
		border: 1px solid rgba(28, 28, 28, 0.05);
		box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
		border-radius: 0.75rem;
		padding: 2rem 1.25rem;
}
.card_08 .card-date {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: #2f7ad3;
}
.card_08 .card-tit {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: #1c1c1c;
}
.card_08 .list {
		margin-top: 1.25rem;
}
.card_08 .list li {
		margin-top: 0.5rem;
}
.card_08 + .card_08 {
		margin-top: 2.5rem;
}

.card_09 {
		position: relative;
		display: block;
		box-shadow: 0.125rem 0.125rem 0.625rem rgba(0, 0, 0, 0.1);
		border-radius: 2rem;
		background-color: #fff;
		padding-top: 1.5rem;
		padding-right: 1.25rem;
		padding-bottom: 1.5rem;
		padding-left: 5.375rem;
}
.card_09 img {
		position: absolute;
		left: 1.25rem;
		top: 50%;
		width: 2.875rem;
		height: 2.875rem;
		transform: translateY(-50%);
		border-radius: 1rem;
		overflow: hidden;
}
.card_09 p {
		font-size: 1.125rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
		color: #253981;
}
.card_09 p ~ p {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		color: #7e8397;
}
.card_09 ~ .card_09 {
		margin-top: 1rem;
}

a.card_09 {
		padding-right: 4.125rem;
		background-image: url("../../images/ico/ico_arrow_main_result_34x34.svg");
		background-size: 2.125rem 2.125rem;
		background-position: right 0.75rem center;
		background-repeat: no-repeat;
}

.card-inspection {
		position: relative;
}
.card-inspection input[type=checkbox],
.card-inspection input[type=radio] {
		position: absolute;
		left: 0;
		top: 0;
		width: 1px;
		height: 1px;
		background-color: transparent;
		color: transparent;
		font-size: 1px;
		line-height: 1px;
		opacity: 0;
}
.card-inspection input[type=checkbox] + label,
.card-inspection input[type=radio] + label {
		background-color: #fff;
		padding: 0.1875rem;
		border: 1px solid rgba(28, 28, 28, 0.05);
		box-sizing: border-box;
		box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
		border-radius: 0.75rem;
		display: flex;
		flex-direction: column;
		overflow: hidden;
}
.card-inspection input[type=checkbox] + label .inner,
.card-inspection input[type=radio] + label .inner {
		position: relative;
		border-radius: 0.625rem;
		background-color: #fff;
		padding-top: 0.8125rem;
		padding-right: 1.0625rem;
		padding-bottom: 0;
		padding-left: 1.0625rem;
}
.card-inspection input[type=checkbox] + label .inner p,
.card-inspection input[type=checkbox] + label .inner p em,
.card-inspection input[type=radio] + label .inner p,
.card-inspection input[type=radio] + label .inner p em {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
}
.card-inspection input[type=checkbox] + label .inner p,
.card-inspection input[type=radio] + label .inner p {
		display: flex;
		align-items: center;
		color: rgba(28, 28, 28, 0.4);
}
.card-inspection input[type=checkbox] + label .inner p em,
.card-inspection input[type=radio] + label .inner p em {
		color: rgba(28, 28, 28, 0.6);
		margin-right: 0.25rem;
}
.card-inspection input[type=checkbox] + label .inner ul.price,
.card-inspection input[type=radio] + label .inner ul.price {
		display: flex;
		align-items: center;
		margin-top: 0.25rem;
		padding-bottom: 0.75rem;
}
.card-inspection input[type=checkbox] + label .inner ul.price li,
.card-inspection input[type=radio] + label .inner ul.price li {
		font-size: 1.5rem;
		font-weight: 500;
		letter-spacing: -0.0313rem;
		line-height: 2rem;
		color: #1c1c1c;
}
.card-inspection input[type=checkbox] + label .inner ul.price li ~ li,
.card-inspection input[type=radio] + label .inner ul.price li ~ li {
		font-size: 1.125rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.6);
		margin-left: 0.25rem;
}
.card-inspection input[type=checkbox] + label .inner ul.price + .tag-list,
.card-inspection input[type=radio] + label .inner ul.price + .tag-list {
		margin-top: 0.5rem;
}
.card-inspection input[type=checkbox] + label .inner ul.co-badge,
.card-inspection input[type=radio] + label .inner ul.co-badge {
		margin-top: 0.25rem;
		margin-left: -0.375rem;
		width: calc(100% + 0.75rem);
}
.card-inspection input[type=checkbox] + label .inner ul.co-badge li,
.card-inspection input[type=radio] + label .inner ul.co-badge li {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		background-color: #ecf1f8;
		color: #253981;
		padding: 0.125rem 0.625rem;
		height: 1.75rem;
		margin-top: 0;
		margin-right: 0.375rem;
		margin-bottom: 0.75rem;
		margin-left: 0.375rem;
}
.card-inspection input[type=checkbox] + label .inner .co-tooltip,
.card-inspection input[type=radio] + label .inner .co-tooltip {
		position: absolute;
		right: 1.25rem;
		top: 1rem;
}
.card-inspection input[type=checkbox] + label::before,
.card-inspection input[type=radio] + label::before {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
		border: 3px solid transparent;
		border-radius: 0.75rem;
		background-image: linear-gradient(to bottom, #fff, #fff), linear-gradient(65deg, #2f7ad3 1%, #253981 113%);
		background-origin: border-box;
		background-clip: content-box, border-box;
}
.card-inspection input[type=checkbox]:checked + label,
.card-inspection input[type=radio]:checked + label {
		background-color: transparent;
		border-color: transparent;
}
.card-inspection input[type=checkbox]:checked + label::before,
.card-inspection input[type=radio]:checked + label::before {
		opacity: 1;
}

.sub-card dl {
		position: relative;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		padding-top: 1.75rem;
}
.sub-card dl dt,
.sub-card dl dd {
		color: rgba(28, 28, 28, 0.8);
		text-align: left;
}
.sub-card dl dt {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		white-space: nowrap;
		align-self: stretch;
		line-height: 1.625rem;
		padding-right: 0.5rem;
}
.sub-card dl dd {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		position: relative;
}
.sub-card dl::after {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: 0.5rem;
		width: 100%;
		height: 1px;
		background-color: rgba(28, 28, 28, 0.1);
}
.sub-card dl ~ dl {
		padding-top: 0;
}
.sub-card dl ~ dl::after {
		display: none;
		width: 0;
		height: 0;
}
.sub-card.type1, .sub-card.type3 {
		position: relative;
}
.sub-card.type1 input[type=radio],
.sub-card.type1 input[type=checkbox], .sub-card.type3 input[type=radio],
.sub-card.type3 input[type=checkbox] {
		position: absolute;
		left: 0;
		top: 0;
		width: 1px;
		height: 1px;
		background-color: transparent;
		color: transparent;
		font-size: 1px;
		line-height: 1px;
		opacity: 0;
}
.sub-card.type1 input[type=radio] + label,
.sub-card.type1 input[type=checkbox] + label, .sub-card.type3 input[type=radio] + label,
.sub-card.type3 input[type=checkbox] + label {
		background-color: #fff;
		padding: 0.1875rem;
		border: 1px solid rgba(28, 28, 28, 0.05);
		box-sizing: border-box;
		box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
		overflow: hidden;
		display: flex;
		flex-direction: column;
		overflow: hidden;
}
.sub-card.type1 input[type=radio] + label .inner,
.sub-card.type1 input[type=checkbox] + label .inner, .sub-card.type3 input[type=radio] + label .inner,
.sub-card.type3 input[type=checkbox] + label .inner {
		position: relative;
		background-color: #fff;
		padding-top: 1.3125rem;
		padding-right: 1.25rem;
		padding-bottom: 0.5625rem;
		padding-left: 1.25rem;
}
.sub-card.type1 input[type=radio] + label .inner ul.case,
.sub-card.type1 input[type=checkbox] + label .inner ul.case, .sub-card.type3 input[type=radio] + label .inner ul.case,
.sub-card.type3 input[type=checkbox] + label .inner ul.case {
		display: flex;
		align-items: center;
}
.sub-card.type1 input[type=radio] + label .inner ul.case li.ico-checkbox,
.sub-card.type1 input[type=checkbox] + label .inner ul.case li.ico-checkbox, .sub-card.type3 input[type=radio] + label .inner ul.case li.ico-checkbox,
.sub-card.type3 input[type=checkbox] + label .inner ul.case li.ico-checkbox {
		margin-left: 0.25rem;
		width: 1.25rem;
		height: 1.25rem;
		background-size: 1.25rem;
		background-position: center;
		background-repeat: no-repeat;
		transition: background-image 0.3s ease-in-out;
}
.sub-card.type1 input[type=radio] + label .inner ul.case li:first-child,
.sub-card.type1 input[type=checkbox] + label .inner ul.case li:first-child, .sub-card.type3 input[type=radio] + label .inner ul.case li:first-child,
.sub-card.type3 input[type=checkbox] + label .inner ul.case li:first-child {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: #2f7ad3;
}
.sub-card.type1 input[type=radio] + label .inner ul.profile,
.sub-card.type1 input[type=checkbox] + label .inner ul.profile, .sub-card.type3 input[type=radio] + label .inner ul.profile,
.sub-card.type3 input[type=checkbox] + label .inner ul.profile {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding-bottom: 0.5rem;
		margin-top: 0.25rem;
}
.sub-card.type1 input[type=radio] + label .inner ul.profile li,
.sub-card.type1 input[type=checkbox] + label .inner ul.profile li, .sub-card.type3 input[type=radio] + label .inner ul.profile li,
.sub-card.type3 input[type=checkbox] + label .inner ul.profile li {
		font-size: 1.25rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
		position: relative;
}
.sub-card.type1 input[type=radio] + label .inner ul.profile li ~ li span,
.sub-card.type1 input[type=checkbox] + label .inner ul.profile li ~ li span, .sub-card.type3 input[type=radio] + label .inner ul.profile li ~ li span,
.sub-card.type3 input[type=checkbox] + label .inner ul.profile li ~ li span {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		margin-left: 0.75rem;
}
.sub-card.type1 input[type=radio] + label .inner ul.tag-list,
.sub-card.type1 input[type=checkbox] + label .inner ul.tag-list, .sub-card.type3 input[type=radio] + label .inner ul.tag-list,
.sub-card.type3 input[type=checkbox] + label .inner ul.tag-list {
		margin-top: 0.75rem;
		margin-right: 0;
		margin-bottom: 0.75rem;
		margin-left: 0;
		width: 100%;
		max-height: 24px;
		overflow-y: hidden;
		overflow-x: scroll;
		flex-wrap: nowrap;
}
.sub-card.type1 input[type=radio] + label .inner ul.tag-list li,
.sub-card.type1 input[type=checkbox] + label .inner ul.tag-list li, .sub-card.type3 input[type=radio] + label .inner ul.tag-list li,
.sub-card.type3 input[type=checkbox] + label .inner ul.tag-list li {
		margin: 0;
		flex: 0 0 auto;
}
.sub-card.type1 input[type=radio] + label .inner ul.tag-list li ~ li,
.sub-card.type1 input[type=checkbox] + label .inner ul.tag-list li ~ li, .sub-card.type3 input[type=radio] + label .inner ul.tag-list li ~ li,
.sub-card.type3 input[type=checkbox] + label .inner ul.tag-list li ~ li {
		margin-left: 0.75rem;
}
.sub-card.type1 input[type=radio] + label .inner dl dt,
.sub-card.type1 input[type=checkbox] + label .inner dl dt, .sub-card.type3 input[type=radio] + label .inner dl dt,
.sub-card.type3 input[type=checkbox] + label .inner dl dt {
		width: 1.625rem;
}
.sub-card.type1 input[type=radio] + label .inner dl dd span,
.sub-card.type1 input[type=checkbox] + label .inner dl dd span, .sub-card.type3 input[type=radio] + label .inner dl dd span,
.sub-card.type3 input[type=checkbox] + label .inner dl dd span {
		font-size: 0.75rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.5rem;
		width: calc(100% - 2.125rem);
}
.sub-card.type1 input[type=radio] + label::before,
.sub-card.type1 input[type=checkbox] + label::before, .sub-card.type3 input[type=radio] + label::before,
.sub-card.type3 input[type=checkbox] + label::before {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
		border: 3px solid transparent;
		border-radius: 1.25rem;
		background-image: linear-gradient(to bottom, #fff, #fff), linear-gradient(65deg, #2f7ad3 1%, #253981 113%);
		background-origin: border-box;
		background-clip: content-box, border-box;
}
.sub-card.type1 input[type=radio]:checked + label,
.sub-card.type1 input[type=checkbox]:checked + label, .sub-card.type3 input[type=radio]:checked + label,
.sub-card.type3 input[type=checkbox]:checked + label {
		background-color: transparent;
		border-color: transparent;
		box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
}
.sub-card.type1 input[type=radio]:checked + label .inner ul.case li.ico-checkbox,
.sub-card.type1 input[type=checkbox]:checked + label .inner ul.case li.ico-checkbox, .sub-card.type3 input[type=radio]:checked + label .inner ul.case li.ico-checkbox,
.sub-card.type3 input[type=checkbox]:checked + label .inner ul.case li.ico-checkbox {
		background-image: url("../images/ico/ico_checkbox_sub_card_20x20_on.svg");
}
.sub-card.type1 input[type=radio]:checked + label::before,
.sub-card.type1 input[type=checkbox]:checked + label::before, .sub-card.type3 input[type=radio]:checked + label::before,
.sub-card.type3 input[type=checkbox]:checked + label::before {
		opacity: 1;
}
.sub-card.type1 input[type=radio] + label,
.sub-card.type1 input[type=checkbox] + label {
		border-radius: 1.125rem;
}
.sub-card.type1 input[type=radio] + label:before,
.sub-card.type1 input[type=checkbox] + label:before {
		border-radius: 1.125rem;
}
.sub-card.type1 input[type=radio]:checked + label:before,
.sub-card.type1 input[type=checkbox]:checked + label:before {
		border-radius: 1.125rem;
}
.sub-card.type1:before {
		border-radius: 1.125rem;
}
.sub-card.type1 .inner {
		border-radius: 1.125rem;
}
.sub-card.type3 input[type=radio] + label,
.sub-card.type3 input[type=checkbox] + label {
		border-radius: 0.75rem;
}
.sub-card.type3 input[type=radio] + label:before,
.sub-card.type3 input[type=checkbox] + label:before {
		border-radius: 0.75rem;
}
.sub-card.type3 input[type=radio]:checked + label:before,
.sub-card.type3 input[type=checkbox]:checked + label:before {
		border-radius: 0.75rem;
}
.sub-card.type3:before {
		border-radius: 0.75rem;
}
.sub-card.type3 .inner {
		border-radius: 0.75rem;
}
.sub-card.type3 input[type=radio]:checked + label,
.sub-card.type3 input[type=checkbox]:checked + label {
		background-color: transparent;
		border-color: transparent;
		box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
}
.sub-card.type3 input[type=radio]:checked + label::before,
.sub-card.type3 input[type=checkbox]:checked + label::before {
		opacity: 1;
}
.sub-card.type2 {
		background: #ffffff;
		border: 1px solid rgba(28, 28, 28, 0.05);
		box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
		border-radius: 1.25rem;
		padding: 1.5rem 1.25rem 0;
		overflow: hidden;
}
.sub-card.type2 p.location {
		font-size: 1.25rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
		margin-top: 0.5rem;
		margin-bottom: 0.75rem;
}
.sub-card.type2 .info-list {
		padding-bottom: 1.5rem;
}
.sub-card.type2 .info-list dl dt,
.sub-card.type2 .info-list dl dd {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
}
.sub-card.type2 .info-list dl dt {
		width: 4rem;
		color: rgba(28, 28, 28, 0.4);
}
.sub-card.type2 .info-list dl dd {
		position: relative;
		padding-left: 0.625rem;
		display: flex;
		align-items: center;
		justify-content: flex-start;
}
.sub-card.type2 .info-list dl dd span {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: rgba(28, 28, 28, 0.8);
		position: relative;
}
.sub-card.type2 .info-list dl dd span ~ span {
		padding-left: 0.8125rem;
}
.sub-card.type2 .info-list dl dd span ~ span::after {
		content: "/";
		display: block;
		position: absolute;
		left: 0.25rem;
		top: 0;
		width: 0.3125rem;
		height: 1.25rem;
		color: rgba(28, 28, 28, 0.2);
}
.sub-card.type2 .info-list dl::after {
		left: -1.375rem;
		width: calc(100% + 2.75rem);
}
.sub-card.type2 .button-list {
		background-color: #f6f6f8;
		margin-left: -1.25rem;
		width: calc(100% + 2.5rem);
		height: 2.75rem;
		display: flex;
		align-items: center;
		border-top: 1px solid rgba(28, 28, 28, 0.1);
}
.sub-card.type2 .button-list a {
		font-size: 0.875rem;
		font-weight: 400;
		line-height: 1.25rem;
		color: rgba(28, 28, 28, 0.8);
		height: 2.75rem;
		flex-grow: 1;
		display: flex;
		align-items: center;
		justify-content: center;
}
.sub-card.type2 .button-list a ~ a {
		border-left: 1px solid rgba(28, 28, 28, 0.1);
}
.sub-card.type4 {
		margin-top: 1.25rem;
		position: relative;
}
.sub-card.type4 input[type=radio] + label,
.sub-card.type4 input[type=checkbox] + label {
		border-radius: 1.125rem;
}
.sub-card.type4 input[type=radio] + label:before,
.sub-card.type4 input[type=checkbox] + label:before {
		border-radius: 1.125rem;
}
.sub-card.type4 input[type=radio]:checked + label:before,
.sub-card.type4 input[type=checkbox]:checked + label:before {
		border-radius: 1.125rem;
}
.sub-card.type4:before {
		border-radius: 1.125rem;
}
.sub-card.type4 .inner {
		border-radius: 1.125rem;
}
.sub-card.type4 input[type=radio],
.sub-card.type4 input[type=checkbox] {
		position: absolute;
		left: 0;
		top: 0;
		width: 1px;
		height: 1px;
		background-color: transparent;
		color: transparent;
		font-size: 1px;
		line-height: 1px;
		opacity: 0;
}
.sub-card.type4 input[type=radio] + label,
.sub-card.type4 input[type=checkbox] + label {
		background-color: #fff;
		padding: 0.1875rem;
		border: 1px solid rgba(28, 28, 28, 0.05);
		box-sizing: border-box;
		box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
		overflow: hidden;
		display: flex;
		flex-direction: column;
		overflow: hidden;
}
.sub-card.type4 input[type=radio] + label .inner,
.sub-card.type4 input[type=checkbox] + label .inner {
		position: relative;
		background-color: #fff;
		padding-top: 1.3125rem;
		padding-right: 1.25rem;
		padding-bottom: 0.5625rem;
		padding-left: 1.25rem;
}
.sub-card.type4 input[type=radio] + label .inner ul.case,
.sub-card.type4 input[type=checkbox] + label .inner ul.case {
		display: flex;
		align-items: center;
		justify-content: space-between;
}
.sub-card.type4 input[type=radio] + label .inner ul.case li.ico-checkbox,
.sub-card.type4 input[type=checkbox] + label .inner ul.case li.ico-checkbox {
		width: 1.25rem;
		height: 1.25rem;
		background-image: url("../images/ico/ico_checkbox_sub_card_20x20_on.svg");
		background-size: 1.25rem 1.25rem;
		background-position: center center;
		background-repeat: no-repeat;
		transition: background-image 0.3s ease-in-out;
}
.sub-card.type4 input[type=radio] + label .inner ul.case li:first-child,
.sub-card.type4 input[type=checkbox] + label .inner ul.case li:first-child {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: #2f7ad3;
}
.sub-card.type4 input[type=radio] + label .inner ul.profile,
.sub-card.type4 input[type=checkbox] + label .inner ul.profile {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding-bottom: 0.5rem;
		margin-top: 0.25rem;
}
.sub-card.type4 input[type=radio] + label .inner ul.profile li,
.sub-card.type4 input[type=checkbox] + label .inner ul.profile li {
		font-size: 1.25rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
		position: relative;
}
.sub-card.type4 input[type=radio] + label .inner ul.profile li ~ li span,
.sub-card.type4 input[type=checkbox] + label .inner ul.profile li ~ li span {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		margin-left: 0.75rem;
}
.sub-card.type4 input[type=radio] + label .inner ul.tag-list,
.sub-card.type4 input[type=checkbox] + label .inner ul.tag-list {
		margin-top: 0.75rem;
		margin-right: 0;
		margin-bottom: 0.75rem;
		margin-left: 0;
		width: 100%;
		max-height: 24px;
		overflow-y: hidden;
		overflow-x: scroll;
		flex-wrap: nowrap;
}
.sub-card.type4 input[type=radio] + label .inner ul.tag-list li,
.sub-card.type4 input[type=checkbox] + label .inner ul.tag-list li {
		margin: 0;
		flex: 0 0 auto;
}
.sub-card.type4 input[type=radio] + label .inner ul.tag-list li ~ li,
.sub-card.type4 input[type=checkbox] + label .inner ul.tag-list li ~ li {
		margin-left: 0.75rem;
}
.sub-card.type4 input[type=radio] + label .inner dl dt,
.sub-card.type4 input[type=checkbox] + label .inner dl dt {
		width: 1.625rem;
}
.sub-card.type4 input[type=radio] + label .inner dl dd span,
.sub-card.type4 input[type=checkbox] + label .inner dl dd span {
		font-size: 0.75rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.5rem;
		width: calc(100% - 2.125rem);
}
.sub-card.type4 input[type=radio] + label::before,
.sub-card.type4 input[type=checkbox] + label::before {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
		border: 3px solid transparent;
		border-radius: 1.25rem;
		background-image: linear-gradient(to bottom, #fff, #fff), linear-gradient(65deg, #2f7ad3 1%, #253981 113%);
		background-origin: border-box;
		background-clip: content-box, border-box;
}
.sub-card.type4 input[type=radio]:checked + label,
.sub-card.type4 input[type=checkbox]:checked + label {
		background-color: transparent;
		border-color: transparent;
		box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
}
.sub-card.type4 input[type=radio]:checked + label .inner ul.case li.target,
.sub-card.type4 input[type=checkbox]:checked + label .inner ul.case li.target {
		display: flex;
}
.sub-card.type4 input[type=radio]:checked + label .inner ul.case li.target:after,
.sub-card.type4 input[type=checkbox]:checked + label .inner ul.case li.target:after {
		content: "";
		margin-left: 4px;
		width: 1.25rem;
		height: 1.25rem;
		background-image: url("../images/ico/ico_checkbox_sub_card_20x20_on.svg");
		background-size: 1.25rem 1.25rem;
		background-position: center center;
		background-repeat: no-repeat;
		transition: background-image 0.3s ease-in-out;
}
.sub-card.type4 input[type=radio]:checked + label::before,
.sub-card.type4 input[type=checkbox]:checked + label::before {
		opacity: 1;
}

.accordion .accordion-header img.img-acc-arrow {
		transform: rotate(0);
}
.accordion .accordion-body {
		max-height: 0;
		opacity: 0;
		overflow: hidden;
		transition: max-height 0.3s, padding 0.3s, opacity 0.3s;
}
.accordion.open .accordion-header img.img-acc-arrow {
		transform: rotate(180deg);
}
.accordion.open .accordion-body {
		opacity: 1;
		max-height: max-content;
}
.accordion.type1 {
		border: 1px solid rgba(28, 28, 28, 0.2);
		border-radius: 0.75rem;
}
.accordion.type1 .accordion-header {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		padding: 0.6875rem 1.25rem;
}
.accordion.type1 .accordion-header .acc-tit {
		font-size: 0.875rem;
		line-height: 1.75rem;
		letter-spacing: -0.01875rem;
		color: rgba(28, 28, 28, 0.8);
		text-align: left;
		flex-grow: 1;
		white-space: nowrap;
}
.accordion.type1 .accordion-header .co-btn {
		margin-right: 1.25rem;
		background: #ecf1f8;
		border-radius: 1.25rem;
		border: 0 none;
}
.accordion.type1 .accordion-header .co-btn span {
		color: #253981;
}
.accordion.type1 .accordion-header img.img-acc-arrow {
		width: 2.125rem;
		height: 2.125rem;
		transform: rotate(180deg);
}
.accordion.type1 .accordion-body {
		background-color: rgba(28, 28, 28, 0.05);
		border-top: 0 none;
		padding: 0 0.75rem;
}
.accordion.type1 .accordion-body .card_06:first-child {
		margin-top: 1.25rem;
}
.accordion.type1 .accordion-body .card_06:last-child {
		margin-bottom: 1.25rem;
}
.accordion.type1 .accordion-body > .list.type1 {
		margin-top: 1.25rem;
		margin-bottom: 1.25rem;
}
.accordion.type1.open .accordion-header img.img-acc-arrow {
		transform: rotate(0);
}
.accordion.type1.open .accordion-body {
		position: relative;
		border-top: 0 none;
}
.accordion.type1.open .accordion-body .card_06:first-child::after,
.accordion.type1.open .accordion-body .list:first-child::after {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 1px;
		background-color: rgba(28, 28, 28, 0.2);
}
.accordion.type1 ~ .type1 {
		margin-top: 1.25rem;
}
.accordion.type2 .accordion-header {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		padding: 0.6875rem 1.25rem;
		border-radius: 0.75rem;
		border: 1px solid rgba(28, 28, 28, 0.2);
		transition: border-color 0.3s;
}
.accordion.type2 .accordion-header .acc-tit {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: #1c1c1c;
		text-align: left;
		flex-grow: 1;
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		transition: color 0.3s;
}
.accordion.type2 .accordion-body {
		background-color: #f6f6f8;
		border-radius: 0.75rem;
		padding: 0 1.25rem;
		margin-top: 0;
		transition: max-height 0.3s, margin-top 0.3s, opacity 0.3s;
}
.accordion.type2 .accordion-body p.acc-body-tit, .accordion.type2 .accordion-body p.acc-body-con {
		color: rgba(28, 28, 28, 0.8);
}
.accordion.type2 .accordion-body p.acc-body-tit {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		margin-top: 1.25rem;
}
.accordion.type2 .accordion-body p.acc-body-con {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		margin-top: 0.75rem;
		margin-bottom: 0.75rem;
}
.accordion.type2.open .accordion-header {
		border-color: #2f7ad3;
}
.accordion.type2.open .accordion-header .acc-tit {
		color: #2f7ad3;
}
.accordion.type2.open .accordion-body {
		margin-top: 0.75rem;
}
.accordion.type2 ~ .type2 {
		margin-top: 1.25rem;
}
.accordion.type3 .accordion-header {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		padding: 0.6875rem 1.25rem;
		border-radius: 0.75rem;
		border: 1px solid rgba(28, 28, 28, 0.2);
}
.accordion.type3 .accordion-header ul {
		flex-grow: 1;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		overflow: hidden;
		position: relative;
}
.accordion.type3 .accordion-header ul li {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: #1c1c1c;
		text-align: left;
		white-space: nowrap;
}
.accordion.type3 .accordion-header ul li.state {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
}
.accordion.type3 .accordion-header ul li.state span {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: #fff;
		display: inline-block;
		padding: 0 0.75rem;
		border-radius: 1rem;
}
.accordion.type3 .accordion-header ul li.state span.ing {
		background-color: rgba(28, 28, 28, 0.6);
}
.accordion.type3 .accordion-header ul li.state span.end {
		background-color: #253981;
}
.accordion.type3 .accordion-header ul li.case {
		flex-grow: 1;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
}
.accordion.type3 .accordion-header ul li ~ li {
		margin-left: 0.75rem;
}
.accordion.type3 .accordion-body {
		padding: 0;
		transition: max-height 0.3s, opacity 0.3s;
}
.accordion.type3 .accordion-body .contact-ques,
.accordion.type3 .accordion-body .contact-answer {
		padding: 1.25rem;
		border: 1px solid rgba(28, 28, 28, 0.2);
		border-radius: 0.75rem;
		margin-top: 0.5rem;
}
.accordion.type3 .accordion-body .contact-ques ul,
.accordion.type3 .accordion-body .contact-answer ul {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
}
.accordion.type3 .accordion-body .contact-ques ul li,
.accordion.type3 .accordion-body .contact-answer ul li {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		color: rgba(28, 28, 28, 0.4);
}
.accordion.type3 .accordion-body .contact-ques ul li:first-child,
.accordion.type3 .accordion-body .contact-answer ul li:first-child {
		color: #2f7ad3;
}
.accordion.type3 .accordion-body .contact-ques p,
.accordion.type3 .accordion-body .contact-answer p {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
		margin-top: 0.75rem;
}
.accordion.type3 .accordion-body .contact-answer {
		background-color: rgba(28, 28, 28, 0.05);
}
.accordion.type3 ~ .type3 {
		margin-top: 1.25rem;
}
.accordion.type4 .accordion-header,
.accordion.type4 .accordion-body {
		border-radius: 0.75rem;
		border: 1px solid rgba(28, 28, 28, 0.2);
}
.accordion.type4 .accordion-header {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		padding: 0.6875rem 1.25rem;
}
.accordion.type4 .accordion-header .acc-tit {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: #1c1c1c;
		text-align: left;
		flex-grow: 1;
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
}
.accordion.type4 .accordion-body {
		padding: 0 1.25rem;
		margin-top: 0;
		transition: max-height 0.3s, margin-top 0.3s, opacity 0.3s;
}
.accordion.type4 .accordion-body .list.default {
		margin-top: 1.25rem;
		margin-bottom: 1.25rem;
}
.accordion.type4.open .accordion-body {
		margin-top: 0.5rem;
}
.accordion.type5 .accordion-header {
		padding-top: 0.75rem;
		padding-right: 3rem;
		padding-bottom: 0.75rem;
		padding-left: 1.25rem;
		position: relative;
		display: flex;
		align-items: center;
		width: 100%;
		border-radius: 0.75rem;
		box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.08);
		background-color: #fff;
		background-image: url("../images/ico/ico_arrow_accordion_20x20.svg");
		background-size: 1.25rem 1.25rem;
		background-position: right 1.25rem center;
		background-repeat: no-repeat;
}
.accordion.type5 .accordion-header span {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		color: rgba(28, 28, 28, 0.4);
		padding-right: 0.5rem;
		max-width: calc(100% - 1rem);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		transition: color 0.3s;
}
.accordion.type5 .accordion-body {
		padding: 0;
		margin-top: 0;
		overflow: inherit;
		transition: max-height 0.3s, margin-top 0.3s, opacity 0.3s;
}
.accordion.type5 .accordion-body .c-loc {
		padding-top: 1rem;
}
.accordion.type5 .accordion-body .c-loc + .card_05 {
		margin-top: 1.25rem;
}
.accordion.type5 .accordion-body .card_05 {
		margin-top: 1rem;
}
.accordion.type5.open .accordion-header {
		background-image: url("../images/ico/ico_arrow_accordion_20x20_on.svg");
}
.accordion.type5.open .accordion-header span {
		color: rgba(28, 28, 28, 0.8);
}
.accordion.type6 .accordion-header,
.accordion.type6 .accordion-body {
		border-radius: 0.75rem;
}
.accordion.type6 .accordion-header {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		padding: 0.6875rem 1.25rem;
		border: 1px solid rgba(28, 28, 28, 0.05);
		box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.08);
}
.accordion.type6 .accordion-header .acc-tit {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		position: relative;
		text-align: left;
		flex-grow: 1;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
}
.accordion.type6 .accordion-header .acc-tit span {
		color: #7e8397;
		padding-right: 0.5rem;
}
.accordion.type6 .accordion-header .acc-tit span em.active {
		color: #253981;
}
.accordion.type6 .accordion-header .acc-tit p {
		color: rgba(28, 28, 28, 0.8);
}
.accordion.type6 .accordion-body {
		padding: 0;
		margin-top: 0;
		transition: max-height 0.3s, margin-top 0.3s, opacity 0.3s;
}
.accordion.type6 .accordion-body .info-txt {
		margin: 1.625rem 0 0.875rem;
		position: relative;
		display: block;
		text-align: right;
}
.accordion.type6 .accordion-body .info-txt li ~ li {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: rgba(28, 28, 28, 0.6);
}
.accordion.type6 .accordion-body .info-txt li ~ li span {
		color: #2f7ad3;
		display: inline-block;
		margin: 0 0.25rem;
}
.accordion.type6 .accordion-body .card-box {
		background: #f6f6f8;
		border: 1px solid rgba(28, 28, 28, 0.1);
		border-radius: 0.75rem;
		padding: 1.5rem 1.25rem;
}
.accordion.type6 .accordion-body .card-box .card-box-tit {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		color: rgba(28, 28, 28, 0.8);
}
.accordion.type6 .accordion-body .card-box .card-box-tit + p {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: #2f7ad3;
		margin-top: 0.25rem;
}
.accordion.type6 .accordion-body .card-box .card-box-tit + p + .card-inspection {
		margin-top: 1.25rem;
}
.accordion.type6 .accordion-body .card-box .card-inspection {
		margin-top: 0.75rem;
}
.accordion.type6 .accordion-body .card-box .card-inspection ~ .card-inspection {
		margin-top: 1.25rem;
}
.accordion.type6 .accordion-body .card-box .card-inspection + p {
		font-size: 0.75rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.5rem;
		color: rgba(28, 28, 28, 0.8);
		margin-top: 0.75rem;
}
.accordion.type6 .accordion-body .card-box .card-inspection + p + .card-inspection {
		margin-top: 1.25rem;
}
.accordion.type6 .accordion-body .card-box ~ .card-box {
		margin-top: 2.5rem;
}
.accordion.type-profile .accordion-body > .box-tit, .accordion.type-equipment .accordion-body > .box-tit {
		margin-top: 2.5rem;
}
.accordion.type-profile .accordion-body .box-tit, .accordion.type-equipment .accordion-body .box-tit {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		color: rgba(28, 28, 28, 0.8);
}
.accordion.type-profile .accordion-body .box-tit ~ .box-tit, .accordion.type-equipment .accordion-body .box-tit ~ .box-tit {
		padding-top: 0.5rem;
		margin-top: 0;
}
.accordion.type-profile .accordion-body .box-tit + .box, .accordion.type-equipment .accordion-body .box-tit + .box {
		margin-top: 0.5rem;
		margin-bottom: 1.25rem;
}
.accordion.type-profile .accordion-body .list li, .accordion.type-equipment .accordion-body .list li {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
}
.accordion.type-profile .accordion-body .list li:after, .accordion.type-equipment .accordion-body .list li:after {
		top: 0.75rem;
}
.accordion.type-equipment .accordion-header {
		width: 100%;
		position: relative;
		display: block;
}
.accordion.type-equipment .accordion-header .in-cont {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
}
.accordion.type-equipment .accordion-header img,
.accordion.type-equipment .accordion-header ul {
		overflow: hidden;
}
.accordion.type-equipment .accordion-header img {
		width: 100%;
		height: auto;
		display: block;
		background: rgba(28, 28, 28, 0.1);
		border-top-left-radius: 0.75rem;
		border-top-right-radius: 0.75rem;
		border: 1px solid rgba(28, 28, 28, 0.05);
		border-bottom: 0 none;
}
.accordion.type-equipment .accordion-header ul {
		display: block;
		padding: 1.25rem;
		border-bottom-left-radius: 0.75rem;
		border-bottom-right-radius: 0.75rem;
		border: 1px solid rgba(28, 28, 28, 0.1);
		position: relative;
}
.accordion.type-equipment .accordion-header ul li {
		text-align: left;
		width: 100%;
}
.accordion.type-equipment .accordion-header ul li:nth-child(1) {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: #2f7ad3;
}
.accordion.type-equipment .accordion-header ul li.info-txt {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
		margin-top: 0.25rem;
		display: flex;
}
.accordion.type-equipment .accordion-header ul li.info-txt > div {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		line-height: 1.75rem;
		max-height: 3.5rem;
}
.accordion.type-equipment .accordion-header::after {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border-radius: 0.75rem;
		border: 0.1875rem solid transparent;
		background: linear-gradient(62deg, #2f7ad3 1%, #253981 113%) border-box;
		-webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
		mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
		-webkit-mask-composite: xor;
		mask-composite: exclude;
		box-sizing: border-box;
		z-index: 2;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
}
.accordion.type-equipment.open .accordion-header::after {
		opacity: 1;
}
.accordion.type-profile .accordion-header {
		position: relative;
		padding: 1rem;
		background-color: #f6f6f8;
		border: 1px solid rgba(28, 28, 28, 0.1);
		border-radius: 0.75rem;
		width: 100%;
}
.accordion.type-profile .accordion-header img,
.accordion.type-profile .accordion-header p {
		position: relative;
		z-index: 3;
}
.accordion.type-profile .accordion-header img {
		position: absolute;
		width: 3.625rem;
		height: 3.625rem;
		left: 1rem;
		top: 0.9375rem;
}
.accordion.type-profile .accordion-header p {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		color: #2f7ad3;
		padding-left: 4.875rem;
		text-align: left;
}
.accordion.type-profile .accordion-header p ~ p {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: #1c1c1c;
		margin-top: 0.25rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
}
.accordion.type-profile .accordion-header::before, .accordion.type-profile .accordion-header::after {
		content: "";
		display: block;
		position: absolute;
}
.accordion.type-profile .accordion-header::after {
		width: calc(100% - 0.25rem);
		height: calc(100% - 0.25rem);
		left: 0.125rem;
		top: 0.125rem;
		background-color: #f6f6f8;
		border-radius: 0.625rem;
		z-index: 2;
}
.accordion.type-profile .accordion-header::before {
		left: -1px;
		top: -1px;
		width: calc(100% + 0.125rem);
		height: calc(100% + 0.125rem);
		border-radius: 0.75rem;
		background-image: linear-gradient(62deg, #2f7ad3 1%, #253981 113%);
		z-index: 1;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
}
.accordion.type-profile.open .accordion-header::before {
		opacity: 1;
}

.type-equipment.type-open-one .thumbnail {
		padding-bottom: 46.052%;
		position: relative;
		overflow: hidden;
		border: 1px solid rgba(28, 28, 28, 0.05);
		border-radius: 0.75rem;
		border-radius: 0.75rem 0.75rem 0 0;
		border: 0;
}
.type-equipment.type-open-one .thumbnail > img {
		min-width: 100%;
		min-height: 100%;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		object-fit: cover;
}

.co-tooltip {
		width: 1rem;
		height: 1rem;
		background-image: url("../images/ico/ico_tooltip_question_20x20.svg");
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 1.25rem 1.25rem;
}
.co-tooltip.type-left-txt {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: #fff;
		width: auto;
		height: 1.25rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-right: 1.5rem;
		background-image: url("../images/ico/ico_tooltip_question_white_20x20.svg");
		background-position: right center;
}

.item-info {
		display: flex;
		flex-wrap: wrap;
		align-content: stretch;
}
.item-info a {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: calc(50vw - 0.625rem - 1.75rem);
		height: calc(50vw - 0.625rem - 1.75rem);
		min-height: 8rem;
		max-height: 8.875rem;
		text-align: center;
		border: 1px solid rgba(28, 28, 28, 0.1);
		border-radius: 0.75rem;
		margin-top: 1.25rem;
		padding: 0.8125rem 0.75rem;
}
.item-info a:nth-child(1n) {
		margin-right: 0.625rem;
		margin-left: 0.625rem;
}
.item-info a:nth-child(2n) {
		margin-left: 0.625rem;
		margin-right: 0.625rem;
}
.item-info a img {
		width: 2.875rem;
		height: 2.875rem;
}
.item-info a p {
		font-size: 1rem;
		font-weight: 500;
		line-height: 1.5rem;
		color: rgba(28, 28, 28, 0.6);
		margin-top: 0.75rem;
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
}
.item-info a p span {
		font-size: 0.75rem;
		font-weight: 400;
		line-height: 18px;
		display: block;
}

.co-img-card.type-basic {
		position: relative;
		display: block;
		text-align: left;
		padding: 2.5rem 0;
}
.co-img-card.type-basic img {
		width: 100%;
		height: auto;
		display: block;
		border-radius: 0.75rem;
		overflow: hidden;
}
.co-img-card.type-basic span.date, .co-img-card.type-basic span.address {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: #2f7ad3;
		display: inline-block;
		margin-top: 1rem;
}
.co-img-card.type-basic h2.media-tit {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: break-word;
}
.co-img-card.type-basic::after {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 1px;
		background-color: rgba(28, 28, 28, 0.05);
}
.co-img-card.type-basic:first-child {
		padding-top: 0;
}
.co-img-card.type-basic:first-child::after {
		display: none;
		width: 0;
		height: 0;
}
.co-img-card.type-basic:last-child {
		padding-bottom: 0;
}
.co-img-card.type-check {
		position: relative;
}
.co-img-card.type-check input[type=checkbox],
.co-img-card.type-check input[type=radio] {
		position: absolute;
		left: 0;
		top: 0;
		width: 1px;
		height: 1px;
		background-color: transparent;
		color: transparent;
		font-size: 1px;
		line-height: 1px;
		opacity: 0;
}
.co-img-card.type-check input[type=checkbox] + label,
.co-img-card.type-check input[type=radio] + label {
		position: relative;
		display: block;
}
.co-img-card.type-check input[type=checkbox] + label .inner,
.co-img-card.type-check input[type=radio] + label .inner {
		background-color: #fff;
}
.co-img-card.type-check input[type=checkbox] + label .inner img,
.co-img-card.type-check input[type=checkbox] + label .inner ul,
.co-img-card.type-check input[type=radio] + label .inner img,
.co-img-card.type-check input[type=radio] + label .inner ul {
		overflow: hidden;
}
.co-img-card.type-check input[type=checkbox] + label .inner img,
.co-img-card.type-check input[type=radio] + label .inner img {
		width: 100%;
		height: auto;
		display: block;
		background: rgba(28, 28, 28, 0.1);
		border-top-left-radius: 0.75rem;
		border-top-right-radius: 0.75rem;
		border: 1px solid rgba(28, 28, 28, 0.05);
		border-bottom: 0 none;
}
.co-img-card.type-check input[type=checkbox] + label .inner ul,
.co-img-card.type-check input[type=radio] + label .inner ul {
		display: block;
		padding: 1.25rem;
		border-bottom-left-radius: 0.75rem;
		border-bottom-right-radius: 0.75rem;
		border: 1px solid rgba(28, 28, 28, 0.1);
}
.co-img-card.type-check input[type=checkbox] + label .inner ul li,
.co-img-card.type-check input[type=radio] + label .inner ul li {
		text-align: left;
		width: 100%;
}
.co-img-card.type-check input[type=checkbox] + label .inner ul li:nth-child(1),
.co-img-card.type-check input[type=radio] + label .inner ul li:nth-child(1) {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: #2f7ad3;
}
.co-img-card.type-check input[type=checkbox] + label .inner ul li:nth-child(2),
.co-img-card.type-check input[type=radio] + label .inner ul li:nth-child(2) {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
		margin-top: 0.25rem;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
}
.co-img-card.type-check input[type=checkbox] + label::after,
.co-img-card.type-check input[type=radio] + label::after {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border-radius: 0.75rem;
		border: 0.1875rem solid transparent;
		background: linear-gradient(62deg, #2f7ad3 1%, #253981 113%) border-box;
		-webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
		mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
		-webkit-mask-composite: xor;
		mask-composite: exclude;
		box-sizing: border-box;
		z-index: 2;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
}
.co-img-card.type-check input[type=checkbox]:checked + label::after,
.co-img-card.type-check input[type=radio]:checked + label::after {
		opacity: 1;
}
.co-img-card.type-tag .img-card-tit,
.co-img-card.type-tag p {
		text-align: left;
}
.co-img-card.type-tag .img-card-tit {
		font-size: 1.25rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
}
.co-img-card.type-tag p {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.6);
		margin-top: 0.5rem;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
}
.co-img-card.type-tag img {
		background-color: rgba(28, 28, 28, 0.1);
		border: 1px solid rgba(28, 28, 28, 0.05);
		border-radius: 0.75rem;
		width: 100%;
		height: auto;
		display: block;
		overflow: hidden;
		margin-top: 1.25rem;
}
.co-img-card.type-tag .tag-list {
		margin-top: 1.25rem;
}

.co-badge {
		display: flex;
		align-items: center;
		justify-content: flex-start;
}
.co-badge li {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		width: auto;
		height: 1.5rem;
		border-radius: 1.25rem;
		text-align: center;
		padding: 0.125rem 0.625rem;
}
.co-badge li.wait {
		background-color: #dbfAd6;
		color: #12420b;
}
.co-badge li.conf {
		background-color: #ecf1f8;
		color: #253981;
}
.co-badge li.end {
		background-color: rgba(28, 28, 28, 0.6);
		color: #fff;
}
.co-badge li.cencel {
		background-color: #ffe8d7;
		color: #643b1d;
}
.co-badge li.change {
		background-color: #eaec8b;
		color: #6c691d;
}

.co-main-card {
		position: relative;
		width: 100%;
		height: 12.25em;
		border-radius: 1.25rem;
		background: linear-gradient(76.21deg, #ffffff -39.15%, #2f7ad3 12.07%, #253981 99.51%);
		padding: 1.25rem;
		overflow: hidden;
		box-sizing: border-box;
}
.co-main-card img {
		position: absolute;
		opacity: 0.35;
		filter: drop-shadow(0 0.25rem 0.625rem rgba(0, 0, 0, 0.25));
}
.co-main-card img.img-view-test-results {
		top: -2.125rem;
		right: -0.5rem;
		width: auto;
		height: 10.5rem;
}
.co-main-card img.img-non-registration {
		top: -2rem;
		right: -0.5rem;
		width: 10rem;
		height: auto;
}
.co-main-card img.img-delivery-results {
		top: -2.125rem;
		right: -0.5rem;
		width: 10rem;
		height: auto;
}
.co-main-card img.img-waiting-reservation {
		top: -2.5rem;
		right: -0.75rem;
		width: 10.625rem;
		height: auto;
}
.co-main-card img.img-make-reservation {
		top: -2.5rem;
		right: -0.75rem;
		width: auto;
		height: 10.5rem;
}
.co-main-card span,
.co-main-card dl,
.co-main-card dt,
.co-main-card dd,
.co-main-card p.location {
		position: relative;
		color: #fff;
}
.co-main-card span,
.co-main-card p.location {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
}
.co-main-card span {
		display: inline-block;
		margin-top: -0.125rem;
}
.co-main-card dl {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-top: 0.25rem;
}
.co-main-card dl dt.name {
		font-style: normal;
		font-weight: 500;
		font-size: 1.5rem;
		line-height: 2.25rem;
}
.co-main-card dl dd.date {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		color: rgba(255, 255, 255, 0.8);
		margin-left: 0.5rem;
}
.co-main-card a.result,
.co-main-card p.result {
		position: relative;
		background: rgba(255, 255, 255, 0.7);
		box-shadow: 0 0.25rem 0.625rem rgba(0, 0, 0, 0.25);
		border-radius: 0.75rem;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 3rem;
		margin-top: 1.75rem;
}
.co-main-card a.result span,
.co-main-card p.result span {
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5rem;
		color: #253981;
}
.co-main-card .co-tooltip.type-left-txt {
		position: absolute;
		right: 1.25rem;
		height: 1.25rem;
}

.co-popup-datepicker .cont-box {
		padding-bottom: 2.5rem;
}
.co-popup-datepicker .cont-box h1,
.co-popup-datepicker .cont-box h1 ~ p,
.co-popup-datepicker .cont-box h1 ~ p ~ .datepicker-list {
		padding-left: 1.75rem;
		padding-right: 1.75rem;
}
.co-popup-datepicker .cont-box h1 {
		font-size: 1.125rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
		position: relative;
}
.co-popup-datepicker .cont-box h1 ~ p {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: rgba(28, 28, 28, 0.6);
		margin-top: 0.5rem;
}
.co-popup-datepicker .cont-box h1 ~ p span {
		color: #2f7ad3;
}
.co-popup-datepicker .cont-box h1 ~ h1 {
		margin-top: 3.5rem;
}
.co-popup-datepicker .cont-box h1 ~ h1::after {
		content: "";
		display: block;
		position: absolute;
		left: 1.75rem;
		top: -1.75rem;
		width: calc(100% - 2.5rem);
		height: 1px;
		background-color: rgba(28, 28, 28, 0.1);
}
.co-popup-datepicker .cont-box .datepicker-list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 1.25rem;
}
.co-popup-datepicker .cont-box .datepicker-list .input-block {
		flex-grow: 1;
		position: relative;
}
.co-popup-datepicker .cont-box .datepicker-list .input-block input[type=radio],
.co-popup-datepicker .cont-box .datepicker-list .input-block input[type=checkbox] {
		position: absolute;
		left: 0;
		top: 0;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 1px;
		height: 1px;
		background-color: transparent;
		color: transparent;
		border: 0 none;
}
.co-popup-datepicker .cont-box .datepicker-list .input-block input[type=radio] + label,
.co-popup-datepicker .cont-box .datepicker-list .input-block input[type=checkbox] + label {
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid #253981;
		border-radius: 1.75rem;
		padding: 0.5rem;
}
.co-popup-datepicker .cont-box .datepicker-list .input-block input[type=radio] + label span,
.co-popup-datepicker .cont-box .datepicker-list .input-block input[type=checkbox] + label span {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		color: rgba(28, 28, 28, 0.6);
		color: #253981;
}
.co-popup-datepicker .cont-box .datepicker-list .input-block input[type=radio]:checked + label,
.co-popup-datepicker .cont-box .datepicker-list .input-block input[type=checkbox]:checked + label {
		background-color: #253981;
}
.co-popup-datepicker .cont-box .datepicker-list .input-block input[type=radio]:checked + label span,
.co-popup-datepicker .cont-box .datepicker-list .input-block input[type=checkbox]:checked + label span {
		color: #fff;
}
.co-popup-datepicker .cont-box .datepicker-list .input-block ~ .input-block {
		margin-left: 0.5rem;
}
.co-popup-datepicker .cont-box .ui-widget {
		font-family: "NotoSansKR";
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker {
		margin-top: 1.25rem;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline {
		padding-top: 0;
		padding-right: 0.5rem;
		padding-bottom: 0.75rem;
		padding-left: 0.5rem;
		width: 100%;
		border: 0 none;
		border-radius: 0;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-header {
		font-size: 2rem;
		font-weight: 500;
		letter-spacing: -0.0313rem;
		line-height: 2.5rem;
		color: #253981;
		padding: 0;
		margin: 0 auto;
		text-align: center;
		border-radius: 0;
		border: 0 none;
		background-color: transparent;
		display: flex;
		align-items: center;
		justify-content: center;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-header .ui-datepicker-prev,
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-header .ui-datepicker-next {
		width: 2.125rem;
		height: 2.125rem;
		top: 0;
		display: inline-block;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-header .ui-datepicker-prev .ui-icon,
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-header .ui-datepicker-next .ui-icon {
		width: 2.125rem;
		height: 2.125rem;
		left: 0;
		top: 0;
		margin: 0;
		background-size: 2.125rem 2.125rem;
		background-position: center center;
		background-repeat: no-repeat;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-header .ui-datepicker-prev:hover,
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-header .ui-datepicker-next:hover {
		background-color: transparent;
		border: 0 none;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-header .ui-datepicker-prev {
		margin-right: 0.5rem;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-header .ui-datepicker-prev .ui-icon {
		background-image: url("../images/ico/datepicker/ico_arrow_year_prev_34x34.svg");
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-header .ui-datepicker-next {
		margin-left: 0.5rem;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-header .ui-datepicker-next .ui-icon {
		background-image: url("../images/ico/datepicker/ico_arrow_year_next_34x34.svg");
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-header .ui-datepicker-title {
		font-size: 1.5rem;
		font-weight: 500;
		letter-spacing: -0.0313rem;
		line-height: 2rem;
		margin: 0;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-header .ui-datepicker-title a,
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-header .ui-datepicker-title a span {
		color: #253981;
		display: inline-block;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-header .ui-datepicker-title .ui-datepicker-month {
		margin-left: 0.5rem;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-header .ui-datepicker-title a.ui-datepicker-title span {
		color: rgba(28, 28, 28, 0.4);
		display: inline-block;
		margin: 0 0.5rem;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline table {
		border-spacing: 0;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline table td {
		position: relative;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar {
		margin: 0.625rem 0 0 0;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar thead tr th,
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td,
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td .ui-state-default {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
		text-align: center;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar thead tr th,
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td .ui-state-default {
		padding: 0.625rem 0;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar thead tr th.ui-datepicker-week-end {
		color: #ba1d3c;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar thead tr th.ui-datepicker-week-end ~ .ui-datepicker-week-end {
		color: rgba(28, 28, 28, 0.8);
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td {
		opacity: 1;
		padding: 0;
		width: calc(14.2857142857vw - 1.5rem);
		border: 0 none;
		box-sizing: border-box;
		background-color: transparent;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td .ui-state-default {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		text-align: center;
		border: 0 none;
		box-sizing: border-box;
		background-color: transparent;
		color: rgba(28, 28, 28, 0.8);
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-datepicker-current-day {
		box-shadow: 0 0 0 0.1875rem #2f7ad3 inset;
		border-radius: 0.5rem;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-datepicker-current-day .ui-state-default {
		border-radius: 0.5rem;
		color: #2f7ad3;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-datepicker-current-day.reservation-closed .ui-state-default {
		box-shadow: none;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-datepicker-today {
		position: relative;
		background-color: #253981;
		border-radius: 0.5rem;
		position: relative;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-datepicker-today:after {
		content: "오늘";
		position: absolute;
		display: block;
		width: 100%;
		height: 1rem;
		left: 0;
		bottom: 0;
		padding: 0;
		text-align: center;
		font-weight: 400;
		font-size: 0.625rem;
		line-height: 1rem;
		color: #fff;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-datepicker-today .ui-state-default {
		color: #fff;
		border: 0 none;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-datepicker-today em {
		position: absolute;
		display: block;
		width: 100%;
		height: 1rem;
		left: 0;
		bottom: 0;
		padding: 0;
		text-align: center;
		font-weight: 400;
		font-size: 0.625rem;
		line-height: 1rem;
		color: #fff;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-datepicker-today.reservation-closed .ui-state-default {
		box-shadow: none;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.holidays-before .ui-state-default {
		color: #ba1d3c;
		opacity: 0.2;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.holidays-after .ui-state-default {
		color: #ba1d3c;
		opacity: 0.2;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-datepicker-week-end.ui-state-disabled .ui-state-default {
		color: #ba1d3c;
		opacity: 0.2;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-datepicker-week-end.holidays-before .ui-state-default {
		color: #ba1d3c;
		opacity: 0.2;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-datepicker-week-end.another-month .ui-state-default {
		color: #ba1d3c;
		opacity: 0.2;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-datepicker-week-end.ui-datepicker-current-day.another-month .ui-state-default {
		color: #ba1d3c;
		opacity: 0.2;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-datepicker-week-end ~ .ui-datepicker-week-end .ui-state-default {
		color: rgba(28, 28, 28, 0.8);
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-datepicker-week-end ~ .ui-datepicker-week-end.reservation-closed .ui-state-default {
		color: rgba(28, 28, 28, 0.2);
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-datepicker-week-end ~ .ui-datepicker-week-end.holidays-before .ui-state-default {
		color: #ba1d3c;
		opacity: 0.2;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-datepicker-week-end ~ .ui-datepicker-week-end.holidays-after .ui-state-default {
		color: #ba1d3c;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-datepicker-week-end ~ .ui-datepicker-week-end.ui-datepicker-other-month .ui-state-default {
		color: rgba(28, 28, 28, 0.2);
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-datepicker-week-end ~ .ui-datepicker-week-end.another-month .ui-state-default {
		color: rgba(28, 28, 28, 0.8);
		opacity: 1;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-datepicker-week-end ~ .ui-datepicker-week-end.another-month.ui-datepicker-other-month .ui-state-default, .co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-datepicker-week-end ~ .ui-datepicker-week-end.another-month.ui-datepicker-unselectable .ui-state-default {
		color: rgba(28, 28, 28, 0.2);
		opacity: 1;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-datepicker-week-end ~ .ui-datepicker-week-end.another-month.ui-datepicker-other-month.holidays-after .ui-state-default, .co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-datepicker-week-end ~ .ui-datepicker-week-end.another-month.ui-datepicker-unselectable.holidays-after .ui-state-default {
		color: #ba1d3c;
		opacity: 0.2;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-datepicker-week-end ~ .ui-datepicker-week-end.another-month.holidays-before .ui-state-default {
		color: #ba1d3c;
		opacity: 0.2;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-state-disabled .ui-state-default {
		color: rgba(28, 28, 28, 0.2);
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-state-disabled a,
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-state-disabled span {
		color: rgba(28, 28, 28, 0.2);
		font-size: 1rem;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-state-disabled.ui-datepicker-week-end .ui-stat-default {
		color: #ba1d3c;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-datepicker-other-month .ui-state-default {
		opacity: 1;
		color: rgba(28, 28, 28, 0.2);
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-datepicker-other-month.ui-datepicker-week-end .ui-state-default {
		color: #ba1d3c;
		opacity: 0.2;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-datepicker-other-month ~ .ui-datepicker-other-month .ui-state-default {
		color: rgba(28, 28, 28, 0.2);
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-datepicker-other-month.holidays-after .ui-state-default {
		color: #ba1d3c;
		opacity: 0.2;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-datepicker-other-month.holidays-before .ui-state-default {
		color: rgba(28, 28, 28, 0.2);
		opacity: 1;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-datepicker-other-month.ui-datepicker-today {
		background-color: transparent;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-datepicker-other-month.ui-datepicker-today span {
		box-shadow: none;
		color: rgba(28, 28, 28, 0.2);
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-datepicker-other-month.ui-datepicker-today em {
		color: rgba(28, 28, 28, 0.2);
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-datepicker-other-month.ui-datepicker-current-day {
		background-color: transparent;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-datepicker-other-month.ui-datepicker-current-day span {
		box-shadow: none;
		color: rgba(28, 28, 28, 0.2);
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.ui-datepicker-other-month.ui-datepicker-current-day em {
		color: rgba(28, 28, 28, 0.2);
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.reservation-closed .ui-state-default {
		color: rgba(28, 28, 28, 0.2);
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.reservation-closed.ui-datepicker-today .ui-state-default {
		color: #fff;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.reservation-closed.ui-datepicker-today.ui-datepicker-current-day {
		box-shadow: none;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-calendar tbody tr td.reservation-closed.ui-datepicker-today.ui-datepicker-other-month .ui-state-default {
		color: rgba(28, 28, 28, 0.2);
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-select-month,
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-select-year {
		margin: 1.25rem 0 0 0;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-select-month table tbody tr td,
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-select-year table tbody tr td {
		opacity: 1;
		padding: 0;
		width: calc(25vw - 1.5rem);
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-select-month table tbody tr td a,
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-select-year table tbody tr td a {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
		text-align: center;
		padding: 1.25rem 0;
		border: 0 none;
		background-color: transparent;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-select-month table tbody tr td.ui-datepicker-today a,
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline .ui-datepicker-select-year table tbody tr td.ui-datepicker-today a {
		box-shadow: 0 0 0 0.1875rem #2f7ad3 inset;
		border-radius: 0.5rem;
		color: #2f7ad3;
}
.co-popup-datepicker .cont-box .co-datepicker.hasDatepicker .ui-datepicker-inline i {
		position: absolute !important;
		left: 0 !important;
		top: 0 !important;
		width: 1px !important;
		height: 1px !important;
		font-size: 1px !important;
		line-height: 1px !important;
		opacity: 0 !important;
		color: transparent !important;
		display: block !important;
}
.co-popup-datepicker .cont-box .c-datepicker-info {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: right;
		padding: 0 1.75rem;
}
.co-popup-datepicker .cont-box .c-datepicker-info ul {
		position: relative;
}
.co-popup-datepicker .cont-box .c-datepicker-info ul ~ ul {
		margin-left: 1.25rem;
}
.co-popup-datepicker .cont-box .c-datepicker-info ul li ~ li {
		position: relative;
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		padding-left: 0.75rem;
}
.co-popup-datepicker .cont-box .c-datepicker-info ul li ~ li::after {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: 0.375rem;
		width: 0.5rem;
		height: 0.5rem;
		border-radius: 0.125rem;
}
.co-popup-datepicker .cont-box .c-datepicker-info ul.is-selected li {
		color: #2f7ad3;
}
.co-popup-datepicker .cont-box .c-datepicker-info ul.is-selected li::after {
		background-color: #2f7ad3;
}
.co-popup-datepicker .cont-box .c-datepicker-info ul.is-holiday li {
		color: #ba1d3c;
}
.co-popup-datepicker .cont-box .c-datepicker-info ul.is-holiday li::after {
		background-color: #ba1d3c;
}
.co-popup-datepicker .cont-box .c-datepicker-info ul.is-disabled li {
		color: rgba(28, 28, 28, 0.2);
}
.co-popup-datepicker .cont-box .c-datepicker-info ul.is-disabled li::after {
		background-color: rgba(28, 28, 28, 0.2);
}

.ui-datepicker-select-month,
.ui-datepicker-select-year {
		margin: 1.25rem 0 0 0;
}
.ui-datepicker-select-month table tbody tr td,
.ui-datepicker-select-year table tbody tr td {
		opacity: 1;
		padding: 0;
		width: calc(25vw - 1.5rem);
}
.ui-datepicker-select-month table tbody tr td a,
.ui-datepicker-select-month table tbody tr td span,
.ui-datepicker-select-year table tbody tr td a,
.ui-datepicker-select-year table tbody tr td span {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
		text-align: center;
		padding: 1.25rem 0;
		border: 0 none;
		background-color: transparent;
}
.ui-datepicker-select-month table tbody tr td.ui-datepicker-today a,
.ui-datepicker-select-year table tbody tr td.ui-datepicker-today a {
		box-shadow: 0 0 0 0.1875rem #2f7ad3 inset;
		border-radius: 0.5rem;
		color: #2f7ad3;
}
.ui-datepicker-select-month table tbody .ui-state-default,
.ui-datepicker-select-year table tbody .ui-state-default {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
		text-align: center;
		padding: 1.25rem 0;
		border: 0 none;
		background-color: transparent;
}

.ui-datepicker-year + .ui-datepicker-month {
		position: relative;
}
.ui-datepicker-year + .ui-datepicker-month:before {
		content: ".";
		margin-left: -0.75rem;
		margin-right: 0.125rem;
}

.ui-datepicker-today.ui-state-disabled .ui-state-default {
		color: rgba(28, 28, 28, 0.8) !important;
}

.ui-datepicker-unselectable.ui-state-disabled.ui-datepicker-current-day.ui-datepicker-today .ui-state-default {
		color: #fff !important;
}

.swiper.type-hide-arr .swiper-button-prev,
.swiper.type-hide-arr .swiper-button-next {
		width: 1px;
		height: 1px;
		background-size: 1px 1px;
		margin-top: -1px;
		opacity: 0;
}

.co-gnb {
		position: fixed;
		right: calc(0rem - (100% - 3.75rem));
		top: 0;
		width: calc(100% - 3.75rem);
		height: 100%;
		display: flex;
		flex-direction: column;
		text-align: left;
		transition: right 0.3s ease-in-out;
}
.co-gnb .gnb-bg {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(28, 28, 28, 0.6);
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
}
.co-gnb .gnb-header {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: #253981;
		width: 100%;
		height: 5.25rem;
		padding: 1.5625rem 1.25rem;
		z-index: 2001;
}
.co-gnb .gnb-header h1.profile a img {
		width: 26px;
		height: 26px;
}
.co-gnb .gnb-header h1.profile a span,
.co-gnb .gnb-header h1.profile a span em {
		font-size: 1rem;
		font-weight: 500;
		line-height: 1.5rem;
		color: #fff;
}
.co-gnb .gnb-header h1.profile a span {
		display: inline-block;
		margin-left: 0.5rem;
}
.co-gnb .gnb-header button img {
		width: 2.125rem;
		height: 2.125rem;
		min-width: 34px;
		min-height: 34px;
}
.co-gnb .gnb-body {
		position: relative;
		display: block;
		background-color: #fff;
		width: 100%;
		height: 100%;
		overflow-y: auto;
		z-index: 2001;
}
.co-gnb .gnb-body li {
		display: block;
}
.co-gnb .gnb-body li > a,
.co-gnb .gnb-body li button {
		display: flex;
		align-items: center;
		width: 100%;
		height: 3.875rem;
		padding: 0.875rem 1.25rem;
		text-align: left;
}
.co-gnb .gnb-body li > a h2,
.co-gnb .gnb-body li button h2 {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0187rem;
		line-height: 1.25rem;
		color: #7e8397;
		flex-grow: 1;
		padding-left: 2.375rem;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		height: 2.125rem;
		background-size: 2.125rem 2.125rem;
		background-position: left center;
		background-repeat: no-repeat;
		transition: all 0.3s ease-out;
}
.co-gnb .gnb-body li > a:active h2,
.co-gnb .gnb-body li button:active h2 {
		color: #253981;
}
.co-gnb .gnb-body li > a .gnb-mark,
.co-gnb .gnb-body li button .gnb-mark {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-grow: 1;
}
.co-gnb .gnb-body li > a .gnb-mark > h2,
.co-gnb .gnb-body li button .gnb-mark > h2 {
		display: inline-flex;
		flex-grow: 0;
}
.co-gnb .gnb-body li .gnb-list {
		width: 100%;
}
.co-gnb .gnb-body li .gnb-list a {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		color: #7e8397;
		background-color: #f6f6f8;
		padding: 0.875rem 1.25rem;
		display: block;
}
.co-gnb .gnb-body li .gnb-list a.active {
		background-color: #ecf1f8;
		color: #253981;
}
.co-gnb .gnb-body li:nth-child(1) > a h2,
.co-gnb .gnb-body li:nth-child(1) button h2 {
		background-image: url("../images/ico//gnb/ico_gnb_main.svg");
}
.co-gnb .gnb-body li:nth-child(1) > a:active h2,
.co-gnb .gnb-body li:nth-child(1) button:active h2 {
		color: #253981;
		background-image: url("../images/ico//gnb/ico_gnb_main_on.svg");
}
.co-gnb .gnb-body li:nth-child(1).open > a h2,
.co-gnb .gnb-body li:nth-child(1).open button h2 {
		color: #253981;
		background-image: url("../images/ico//gnb/ico_gnb_main_on.svg");
}
.co-gnb .gnb-body li:nth-child(2) > a h2,
.co-gnb .gnb-body li:nth-child(2) button h2 {
		background-image: url("../images/ico//gnb/ico_gnb_health_check_system.svg");
}
.co-gnb .gnb-body li:nth-child(2) > a:active h2,
.co-gnb .gnb-body li:nth-child(2) button:active h2 {
		color: #253981;
		background-image: url("../images/ico//gnb/ico_gnb_health_check_system_on.svg");
}
.co-gnb .gnb-body li:nth-child(2).open > a h2,
.co-gnb .gnb-body li:nth-child(2).open button h2 {
		color: #253981;
		background-image: url("../images/ico//gnb/ico_gnb_health_check_system_on.svg");
}
.co-gnb .gnb-body li:nth-child(3) > a h2,
.co-gnb .gnb-body li:nth-child(3) button h2 {
		background-image: url("../images/ico//gnb/ico_gnb_examination.svg");
}
.co-gnb .gnb-body li:nth-child(3) > a:active h2,
.co-gnb .gnb-body li:nth-child(3) button:active h2 {
		color: #253981;
		background-image: url("../images/ico//gnb/ico_gnb_examination_on.svg");
}
.co-gnb .gnb-body li:nth-child(3).open > a h2,
.co-gnb .gnb-body li:nth-child(3).open button h2 {
		color: #253981;
		background-image: url("../images/ico//gnb/ico_gnb_examination_on.svg");
}
.co-gnb .gnb-body li:nth-child(4) > a h2,
.co-gnb .gnb-body li:nth-child(4) button h2 {
		background-image: url("../images/ico//gnb/ico_gnb_jobstress.svg");
}
.co-gnb .gnb-body li:nth-child(4) > a:active h2,
.co-gnb .gnb-body li:nth-child(4) button:active h2 {
		color: #253981;
		background-image: url("../images/ico//gnb/ico_gnb_jobstress_on.svg");
}
.co-gnb .gnb-body li:nth-child(4).open > a h2,
.co-gnb .gnb-body li:nth-child(4).open button h2 {
		color: #253981;
		background-image: url("../images/ico//gnb/ico_gnb_jobstress_on.svg");
}
.co-gnb .gnb-body li:nth-child(5) > a h2,
.co-gnb .gnb-body li:nth-child(5) button h2 {
		background-image: url("../images/ico//gnb/ico_gnb_result_consultation.svg");
}
.co-gnb .gnb-body li:nth-child(5) > a:active h2,
.co-gnb .gnb-body li:nth-child(5) button:active h2 {
		color: #253981;
		background-image: url("../images/ico//gnb/ico_gnb_result_consultation_on.svg");
}
.co-gnb .gnb-body li:nth-child(5).open > a h2,
.co-gnb .gnb-body li:nth-child(5).open button h2 {
		color: #253981;
		background-image: url("../images/ico//gnb/ico_gnb_result_consultation_on.svg");
}
.co-gnb .gnb-body li:nth-child(6) > a h2,
.co-gnb .gnb-body li:nth-child(6) button h2 {
		background-image: url("../images/ico//gnb/ico_gnb_health_information.svg");
}
.co-gnb .gnb-body li:nth-child(6) > a:active h2,
.co-gnb .gnb-body li:nth-child(6) button:active h2 {
		color: #253981;
		background-image: url("../images/ico//gnb/ico_gnb_health_information_on.svg");
}
.co-gnb .gnb-body li:nth-child(6).open > a h2,
.co-gnb .gnb-body li:nth-child(6).open button h2 {
		color: #253981;
		background-image: url("../images/ico//gnb/ico_gnb_health_information_on.svg");
}
.co-gnb .gnb-body li:nth-child(7) > a h2,
.co-gnb .gnb-body li:nth-child(7) button h2 {
		background-image: url("../images/ico//gnb/ico_gnb_community.svg");
}
.co-gnb .gnb-body li:nth-child(7) > a:active h2,
.co-gnb .gnb-body li:nth-child(7) button:active h2 {
		color: #253981;
		background-image: url("../images/ico//gnb/ico_gnb_community_on.svg");
}
.co-gnb .gnb-body li:nth-child(7).open > a h2,
.co-gnb .gnb-body li:nth-child(7).open button h2 {
		color: #253981;
		background-image: url("../images/ico//gnb/ico_gnb_community_on.svg");
}
.co-gnb .gnb-btn-logout {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		background-color: #fff;
		width: 100%;
		height: 3.625rem;
		padding: 0.75rem 1.25rem;
		border-top: 1px solid rgba(28, 28, 28, 0.1);
		z-index: 2001;
}
.co-gnb .gnb-btn-logout img {
		width: 2.125rem;
		height: 2.125rem;
}
.co-gnb .gnb-btn-logout span {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0187rem;
		line-height: 1.25rem;
		color: #7e8397;
}
.co-gnb.show {
		right: 0;
}
.co-gnb.show .gnb-bg {
		opacity: 1;
}
.co-gnb .badge {
		margin-left: 1rem;
		display: inline-flex;
		font-size: 0;
}
.co-gnb .badge > em {
		padding: 0 0.4375rem;
		display: inline-block;
		border-radius: 0.25rem;
		background-color: #ff6859;
		font-size: 0.75rem;
		font-weight: 500;
		line-height: 1.25rem;
		text-align: center;
		color: #fff;
		font-style: normal;
		position: relative;
}
.co-gnb .badge > em:before {
		content: "";
		position: absolute;
		top: 0.25rem;
		left: -0.4375rem;
		border-top: 0.375rem solid transparent;
		border-bottom: 0.375rem solid transparent;
		border-right: 0.5rem solid #ff6859;
}

header.header.co-header {
		position: fixed;
		left: 0;
		top: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: #253981;
		width: 100%;
		height: 5.25rem;
		padding: 1.5625rem 1.75rem;
		z-index: 990;
		transition: top 0.3s linear;
		will-change: scroll-position;
}
header.header.co-header h1.logo {
		display: flex;
}
header.header.co-header h1.logo a {
		display: block;
		padding: 0.5rem 0.75rem;
		background-color: #fff;
		border-radius: 0.5rem;
		overflow: hidden;
}
header.header.co-header h1.logo a img {
		width: auto;
		height: 1.5rem;
		min-height: 24px;
}
header.header.co-header h1.logo.type-color {
		display: block;
}
header.header.co-header .menu button.btn-open-gnb img {
		width: 34px;
		height: 34px;
}
header.header.co-header.scroll-hide {
		top: -5.25rem;
}

.footer.type-default {
		background-color: #1c1c1c;
		text-align: center;
		padding: 3.75rem 0.75rem 2rem;
}
.footer.type-default a.img-logo {
		display: inline-block;
}
.footer.type-default a.img-logo img {
		width: 11.25rem;
		height: auto;
}
.footer.type-default .footer-txt,
.footer.type-default .copyright {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: rgba(255, 255, 255, 0.8);
		text-align: center;
}
.footer.type-default .footer-txt {
		margin-top: 1rem;
}
.footer.type-default .copyright {
		margin-top: 2.5rem;
}
.footer.type-default .personal-policy {
		color: #2f7ad3;
}
.footer.type-login {
		position: relative;
		width: 100%;
		padding-top: 1.6875rem;
		padding-right: 0;
		padding-bottom: 1.75rem;
		padding-left: 0;
		display: block;
		text-align: center;
		align-items: center;
		justify-content: center;
		background-color: #fff;
}
.footer.type-login img {
		width: 12.5rem;
		height: auto;
}
.footer.type-login.bottom-fixed {
		position: fixed;
		left: 0;
		bottom: 0;
		bottom: var(--safe-bottom);
}
.footer.type-login.border-top-type {
		border-top: 1px solid rgba(28, 28, 28, 0.1);
}
.footer.type-login .copyright {
		margin-top: 0.75rem;
		font-size: 0.75rem;
		font-weight: 300;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: rgba(28, 28, 28, 0.4);
}
.footer.type-popup {
		margin-left: -1.75rem;
		margin-right: -1.75rem;
}

.c-layer-popup {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		outline: 0;
		opacity: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: opacity 0.3s ease-in-out;
}
.c-layer-popup .buttons {
		justify-content: flex-end;
}
.c-layer-popup .buttons .c-btn {
		min-width: 80px;
}
.c-layer-popup .in-cont > p ~ p {
		margin-top: 12px;
}
.c-layer-popup .in-cont.type-detail {
		text-align: left;
}
.c-layer-popup .in-cont.type-detail h1 {
		font-size: 1.125rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
}
.c-layer-popup .in-cont.type-detail h1 + p {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
		margin-top: 0.75rem;
}
.c-layer-popup .c-modal-body {
		word-break: keep-all;
}
.c-layer-popup .modal-field {
		top: 100px;
		transition: top 0.3s ease-in-out;
}
.c-layer-popup .popup-bg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba(28, 28, 28, 0.8);
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
}
.c-layer-popup.type-modal-hf-fixed .modal-field {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
}
.c-layer-popup.type-modal-hf-fixed .modal-field .c-modal-body {
		height: 100%;
}
.c-layer-popup.show {
		opacity: 1;
}
.c-layer-popup.show .modal-wrap .popup-bg {
		opacity: 1;
}
.c-layer-popup.show .modal-wrap .modal-field {
		z-index: 1999;
		top: 0;
}
.c-layer-popup.input-focus {
		overflow-y: auto;
}
.c-layer-popup.input-focus .modal-field {
		display: block;
		overflow-y: auto;
}

.modal-wrap {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		width: 100%;
}
.modal-wrap::before {
		content: none;
}

.modal-field {
		position: relative;
		background-color: #fff;
		border-radius: 1.25rem;
		z-index: 2001;
		display: block;
		overflow-y: auto;
		transition: all 0.3s ease-out;
		width: calc(100% - 2rem);
		max-height: calc(100% - 2rem);
}
.modal-field .btn-modal-cls {
		position: absolute;
		top: 1rem;
		right: 1rem;
		z-index: 10;
		background: url("../images/ico/ico_sprite_01.png") -660px 0/auto 166px no-repeat;
		width: 34px;
		height: 34px;
}
.modal-field .btn-modal-cls:hover {
		opacity: 0.6;
}

.c-modal-body {
		padding-top: 4.5rem;
		padding-left: 2rem;
		padding-right: 2rem;
		padding-bottom: 2.5rem;
		text-align: center;
}
.c-modal-body.co-modal-scroll {
		overflow-x: auto;
		margin-bottom: 10px;
}
.c-modal-body.co-modal-scroll .c-modal-inner {
		padding-bottom: 0;
}
.c-modal-body.co-modal-scroll .co-table-field {
		overflow: initial;
}
.c-modal-body .c-sub-title {
		font-size: 1.25rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
		color: #253981;
}

.c-modal-inner {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
}

.c-modal-header {
		display: flex;
		align-items: flex-start;
		border: 0;
		flex-shrink: 0;
		padding: 2.5rem 2rem 1.25rem;
		position: sticky;
		left: 0;
		top: 0;
		background-color: #fff;
		z-index: 1998;
}
.c-modal-header .c-modal-title-field {
		width: 100%;
}
.c-modal-header .c-modal-title-field .c-title {
		text-align: left;
		font-size: 1.5rem;
		font-weight: 500;
		letter-spacing: -0.0313rem;
		line-height: 2rem;
}
.c-modal-header + .c-modal-body {
		padding-top: 1.25rem;
		padding-bottom: 1.75rem;
		text-align: left;
		overflow-y: auto;
}
.c-modal-header + .c-modal-body .c-input-sub-group {
		margin-top: 2.5rem;
}
.c-modal-header + .c-modal-body .c-input-list + .c-input-sub-group {
		margin-top: 1.25rem;
}
.c-modal-header ~ .c-modal-footer {
		padding-top: 0.75rem;
		padding-bottom: 2.5rem;
}
.c-modal-header.isScroll::after {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		bottom: -2.25rem;
		width: 100%;
		height: 2.25rem;
		background-image: linear-gradient(to bottom, rgba(25, 25, 25, 0.05) 0%, rgba(217, 217, 217, 0) 38.05%);
}

.c-modal-footer {
		padding-left: 2rem;
		padding-right: 2rem;
		padding-bottom: 1.25rem;
}

.c-layer-popup.type03 .modal-field {
		max-height: calc(100% - 5rem);
		height: calc(100% - 5rem);
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
}
.c-layer-popup.type03 .modal-field .c-modal-body-field {
		display: flex;
		flex-direction: column;
		flex: 1;
		overflow-x: hidden;
		overflow-y: auto;
		margin: 0.625rem 0.625rem 2.5rem;
}
.c-layer-popup.type03 .modal-field .c-modal-body-field .c-modal-body {
		padding: 0 1.125rem 2.5rem;
		flex-grow: 1;
		justify-content: stretch;
}
.c-layer-popup.type03 .modal-field .c-modal-body-field .c-modal-footer {
		padding: 0 1.125rem 0;
}
.c-layer-popup.type03 .c-modal-header {
		position: relative;
}
.c-layer-popup.type03 .c-modal-header:after {
		bottom: 0;
		transition: bottom 5s;
}
.c-layer-popup.type03 .c-modal-header.is-scroll:hover {
		transition: bottom 5s;
}
.c-layer-popup.type03 .c-modal-header.is-scroll:after {
		content: "";
		position: absolute;
		bottom: -35px;
		left: 0;
		right: 0;
		width: 100%;
		background: linear-gradient(to bottom, rgba(25, 25, 25, 0.05) 0%, rgba(217, 217, 217, 0) 38.05%);
		height: 35px;
}
.c-layer-popup .c-modal-body.scroll {
		flex: 1;
		overflow-x: hidden;
		overflow-y: auto;
}

.c-full-layer {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		overflow: hidden;
		width: 100%;
		transform: translateX(100%);
		background-color: #fff;
		transition: transform 0.3s;
}
.c-full-layer.show {
		display: block;
		z-index: 100;
		transform: translateX(0);
}
.c-full-layer .c-container {
		position: relative;
		display: flex;
		flex: 1;
		flex-direction: column;
		height: 100%;
}
.c-full-layer .full-header {
		background-color: #fff;
		height: 64px;
		display: flex;
		align-items: center;
		z-index: 101;
		padding-left: 1.25rem;
		padding-right: 1.25rem;
		box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.25);
}
.c-full-layer .full-body {
		position: relative;
		flex: 1 1 0;
		overflow-x: hidden;
		overflow-y: auto;
		padding-bottom: 1.75rem;
}
.c-full-layer .full-body .top-title-box {
		font-size: 2rem;
		font-weight: 500;
		letter-spacing: -0.0313rem;
		line-height: 2.5rem;
		color: rgba(28, 28, 28, 0.8);
		padding-top: 2.5rem;
		padding-right: 1.75rem;
		padding-bottom: 0;
		padding-left: 1.75rem;
}
.c-full-layer .full-body .top-title-box.type-step {
		display: flex;
		justify-content: space-between;
		align-items: center;
}
.c-full-layer .full-body .top-title-box.type-step .step {
		font-size: 1.25rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.4);
}
.c-full-layer .full-body .top-title-box.type-sm {
		font-size: 1.5rem;
		font-weight: 500;
		letter-spacing: -0.0313rem;
		line-height: 2rem;
}
.c-full-layer .full-body.type-flex {
		display: flex;
		flex-direction: column;
		padding-bottom: 0;
}
.c-full-layer .full-body.type-flex .cont-box {
		flex-grow: 1;
}
.c-full-layer .cont-box {
		padding-top: 2.5rem;
		padding-right: 1.75rem;
		padding-bottom: 0;
		padding-left: 1.75rem;
}
.c-full-layer .cont-box .tit {
		font-size: 1.25rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
}
.c-full-layer .cont-box .tit + .txt {
		margin-top: 0.5rem;
}
.c-full-layer .cont-box .tit + .txt + .c-input-sub-group {
		margin-top: 2.5rem;
}
.c-full-layer .cont-box .txt {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
}
.c-full-layer .cont-box .txt + .c-input-list {
		margin-top: 1.25rem;
}
.c-full-layer .cont-box .txt + .txt {
		margin-top: 0.75rem;
}
.c-full-layer .cont-box .txt + .box {
		margin-top: 3.75rem;
		margin-right: 1.75rem;
		margin-bottom: 0;
		margin-left: 1.75rem;
}
.c-full-layer .cont-box .c-tab.type-slide {
		padding-top: 1.25rem;
		padding-right: 1.75rem;
		padding-bottom: 2.5rem;
		padding-left: 1.75rem;
		width: 100vw;
		margin-left: -1.75rem;
}
.c-full-layer .cont-box .c-input-list + .co-btn {
		margin-top: 0.75rem;
}
.c-full-layer .cont-box .c-input-list + .co-btn + .c-input-list.type01 {
		margin-top: 1.25rem;
}
.c-full-layer .cont-box .c-input-sub-group {
		margin-top: 1.25rem;
}
.c-full-layer .cont-box .c-input-sub-group + .box {
		margin-top: 3.75rem;
}
.c-full-layer .cont-box .c-input-sub-group.type1 + .box {
		margin-top: 2.5rem;
}
.c-full-layer .cont-box .box + .c-input-list {
		margin-top: 2.5rem;
}
.c-full-layer .cont-box .co-chk-list {
		margin-top: 2.5rem;
}
.c-full-layer .cont-box .examResSwiper {
		position: relative;
}
.c-full-layer .cont-box .examResSwiper + .c-tab {
		margin-top: 0;
}
.c-full-layer .cont-box.type-tab {
		padding-top: 0;
}
.c-full-layer .cont-box.type-tab > .c-tab.type-slide {
		padding-top: 1.75rem;
		padding-bottom: 3.75rem;
}
.c-full-layer .cont-box.type-swiper {
		padding-top: 1.25rem;
		padding-bottom: 2rem;
}
.c-full-layer .cont-box.type-swiper .swiper + .c-tab {
		margin-top: 2.5rem;
		padding-bottom: 1.25rem;
}
.c-full-layer .cont-box .list-tit {
		margin-top: 2.5rem;
}
.c-full-layer .cont-box.type-btn-floating {
		padding-bottom: 1.75rem;
}
.c-full-layer .full-footer {
		padding-top: 0.75rem;
		padding-right: 1.75rem;
		padding-bottom: 2.5rem;
		padding-left: 1.75rem;
		background-color: #fff;
		position: relative;
}
.c-full-layer .full-footer.scroll {
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
}
.c-full-layer .full-footer.scroll:before {
		background: linear-gradient(0, rgba(25, 25, 25, 0.05) 0%, rgba(217, 217, 217, 0) 38.05%);
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: -36px;
		height: 36px;
}
.c-full-layer .full-footer .buttons button {
		white-space: nowrap;
}
.c-full-layer .btn-go-back {
		display: inline-block;
		padding: 1.375rem 1.25rem 1.375rem 0;
}
.c-full-layer .btn-go-back span {
		position: relative;
		padding-left: 34px;
		font-size: 14px;
		font-weight: 500;
		line-height: 18px;
		color: #7e8397;
}
.c-full-layer .btn-go-back span:before {
		width: 34px;
		height: 34px;
		display: inline-block;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		background: url("../images/ico/ico_sprite_01.png") -484px 0/auto 166px no-repeat;
		content: "";
}
.c-full-layer.type-scroll {
		overflow-y: auto;
}
.c-full-layer.type-scroll .c-container {
		display: block;
		overflow-y: auto;
}
.c-full-layer.type-scroll .c-container .full-header {
		position: sticky;
		left: 0;
		top: 0;
}
.c-full-layer.type-scroll .c-container .full-body {
		overflow-y: hidden;
}
.c-full-layer.type-scroll .full-footer.scroll {
		position: fixed;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 4.375rem;
}
.c-full-layer .btn-floating {
		position: relative;
		width: 100%;
		background-color: #fff;
		padding-top: 0.75rem;
		padding-right: 1.75rem;
		padding-bottom: 2.5rem;
		padding-left: 1.75rem;
}
.c-full-layer .btn-floating.isScroll {
		position: sticky;
		left: 0;
		bottom: -1.75rem;
		padding-top: 0.75rem;
		padding-right: 1.75rem;
		padding-bottom: 0.75rem;
		padding-left: 1.75rem;
}
.c-full-layer .btn-floating.isScroll::after {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: -2.25rem;
		width: 100%;
		height: 2.25rem;
		background-image: linear-gradient(0deg, rgba(25, 25, 25, 0.05) 0%, rgba(217, 217, 217, 0) 38.05%);
		transition: background-image 0.3s ease-in-out;
}
.c-full-layer .btn-floating.isScroll.isScrollEnd::after {
		background-image: linear-gradient(0deg, rgba(25, 25, 25, 0) 0%, rgba(217, 217, 217, 0) 38.05%);
}
.c-full-layer.input-focus .c-container {
		flex: none;
		display: block;
		overflow-y: auto;
}
.c-full-layer.input-focus .c-container .full-header {
		position: sticky;
		left: 0;
		top: 0;
}
.c-full-layer.input-focus .c-container .full-body {
		display: block;
}
.c-full-layer.input-focus .c-container .full-body .btn-floating::after {
		display: none;
		width: 0;
		height: 0;
}
.c-full-layer.input-focus .c-container .full-body .btn-floating.isScrollEnd {
		position: relative;
		padding-top: 0.75rem;
		padding-right: 1.75rem;
		padding-bottom: 0.75rem;
		padding-left: 1.75rem;
}
.c-full-layer.type-floating .c-container .full-header {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 4rem;
		transition: top 0.3s ease-in-out;
}
.c-full-layer.type-floating .c-container .full-body {
		padding-top: 4rem;
}
.c-full-layer.type-floating .c-container .full-body .top-title-box {
		padding-bottom: 0.5rem;
}
.c-full-layer.type-floating .c-container .full-body .top-title-box ~ .cont-box {
		padding-top: 1.25rem;
}
.c-full-layer.type-floating .c-container .full-body .top-title-box ~ .cont-box > .tab-floating {
		position: sticky;
		width: 100vw;
		left: 0;
		top: 0;
		background-color: #fff;
		z-index: 100;
		margin-left: -1.75rem;
		transition: top 0.3s ease-in-out;
}
.c-full-layer.type-floating .c-container .full-body .top-title-box ~ .cont-box > .tab-floating .c-tab {
		margin-left: 0;
		padding: 0.75rem 1.75rem;
		transition: padding 0.3s ease-in-out;
}
.c-full-layer.type-floating .c-container .full-body .top-title-box ~ .cont-box > .tab-floating + .tab-panel.show {
		padding-top: 1.75rem;
}
.c-full-layer.type-floating .c-container .full-body .top-title-box ~ .cont-box > .tab-floating::after {
		bottom: 0;
		height: 0;
		background-image: linear-gradient(to bottom, rgba(25, 25, 25, 0) 0%, rgba(217, 217, 217, 0) 0%);
}
.c-full-layer.type-floating .c-container.scroll-hide .full-header {
		top: -64px;
}
.c-full-layer.type-floating .c-container.scroll-hide .top-title-box ~ .cont-box > .tab-floating {
		top: -8rem;
}
.c-full-layer.type-floating .c-container.isTop .top-title-box ~ .cont-box > .tab-floating .c-tab {
		padding: 0.75rem;
}
.c-full-layer.type-floating .c-container.isTop .top-title-box ~ .cont-box > .tab-floating::after {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		bottom: -2.25rem;
		width: 100%;
		height: 2.25rem;
		background-image: linear-gradient(to bottom, rgba(25, 25, 25, 0.05) 0%, rgba(217, 217, 217, 0) 38.05%);
		transition: height 0.3s ease-in-out, background-image 0.3s ease-in-out;
}
.c-full-layer.type-datepicker .cont-box {
		padding-top: 2rem;
		padding-right: 0;
		padding-left: 0;
}

.popup-cls-btn {
		position: absolute;
		top: 0.75rem;
		right: 0.75rem;
		z-index: 10;
		background: url("../images/ico/ico_close_01.svg") center/28px no-repeat;
		width: 34px;
		height: 34px;
}

.type-h-fixed .c-modal-middle {
		margin-top: 2.5rem;
}
.type-h-fixed .c-modal-middle .c-sub-title {
		margin-bottom: 1.25rem;
}

.type-404,
.type-update {
		padding: 0 2.5rem;
		align-items: center;
		justify-content: center;
		text-align: center;
}
.type-404 > img,
.type-update > img {
		height: auto;
}
.type-404 h4,
.type-404 p,
.type-update h4,
.type-update p {
		text-align: center;
		word-break: keep-all;
}
.type-404 h4,
.type-update h4 {
		font-size: 1.5rem;
		font-weight: 500;
		letter-spacing: -0.0313rem;
		line-height: 2rem;
		color: rgba(28, 28, 28, 0.8);
		margin-top: 3.75rem;
}
.type-404 p,
.type-update p {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.6);
		margin-top: 0.75rem;
}

.type-404 > img {
		width: 10rem;
}
.type-404 a {
		margin-top: 2.5rem;
}

.type-update > img {
		width: 12.25rem;
}

.type-loading {
		position: fixed;
		width: 100vw;
		height: 100vh;
		left: 0;
		top: 0;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		z-index: 3001;
		padding-left: 2.5rem;
		padding-right: 2.5rem;
}
.type-loading > img {
		width: 280px;
		max-width: 100%;
}
.type-loading p {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.6);
		text-align: center;
}

.loading-bg {
		position: fixed;
		left: 0;
		top: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba(255, 255, 255, 0.8);
		z-index: 3000;
}

.login-banner {
		position: relative;
		display: block;
		padding: 2rem 1.5rem;
		background-color: #e0ebfa;
		height: 6.875rem;
}
.login-banner p {
		position: relative;
		font-weight: 500;
		font-size: 1.125rem;
		line-height: 1.625rem;
		color: #253981;
		z-index: 2;
}
.login-banner p:first-child {
		font-style: normal;
		font-weight: 400;
		font-size: 0.875rem;
		line-height: 1.125rem;
		color: rgba(28, 28, 28, 0.4);
}
.login-banner img {
		position: absolute;
		right: 1.25rem;
		top: 0;
		width: auto;
		height: 6.875rem;
		z-index: 1;
}

.login-banner02 {
		height: 6.875rem;
		background-color: #e0ebfa;
		position: relative;
		display: block;
		width: 100%;
		padding-left: 1.75rem;
		padding-right: 1.75rem;
		padding-top: 2rem;
}
.login-banner02 p:first-of-type {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: rgba(28, 28, 28, 0.4);
}
.login-banner02 p:last-of-type {
		font-size: 1.125rem;
		font-weight: 500;
		line-height: 1.625rem;
		color: #253981;
}
.login-banner02 img {
		position: absolute;
		right: 1.75rem;
		top: 0;
		bottom: 0;
		height: 100%;
		width: auto;
}

.btn-view-more {
		margin-top: 3.75rem;
}

.ico-ques {
		display: inline-block;
		width: 1rem;
		height: 1rem;
		background-image: url("../images/ico/ico_tooltip_question.svg");
		background-size: 1rem 1rem;
		background-position: center center;
		background-repeat: no-repeat;
}
.ico-ques.type-big {
		width: 2rem;
		height: 2rem;
}

.media-list.end-state a {
		opacity: 0.3;
		pointer-events: none;
}

.list-tit {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		color: #2f7ad3;
		margin-top: 3.75rem;
}
.list-tit + .c-input-list {
		margin-top: 0.75rem;
}

.tag-list {
		display: flex;
		flex-wrap: wrap;
		margin-left: -0.25rem;
		width: calc(100% + 0.5rem);
}
.tag-list li,
.tag-list button {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: #253981;
		background-color: #ecf1f8;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0.125rem 0.625rem;
		min-height: 1.5rem;
		text-align: center;
		border-radius: 1.25rem;
		margin-top: 0;
		margin-right: 0.25rem;
		margin-bottom: 0.75rem;
		margin-left: 0.25rem;
}
.tag-list li:last-child,
.tag-list button:last-child {
		margin-right: 0;
}
.tag-list.type-main-card {
		position: relative;
		width: 100%;
		height: 1.25rem;
		margin: 0;
		display: block;
		overflow-x: auto;
		overflow-y: hidden;
}
.tag-list.type-main-card::-webkit-scrollbar, .tag-list.type-main-card::-webkit-scrollbar-thumb {
		display: none;
}
.tag-list.type-main-card ul {
		display: block;
		width: max-content;
}
.tag-list.type-main-card ul li {
		position: relative;
		padding-top: 0;
		padding-right: 0;
		padding-bottom: 0;
		padding-left: 0.8125rem;
		margin: 0;
		width: auto;
		height: 1.25rem;
		color: #fff;
		background-color: transparent;
		border-radius: 0;
		float: left;
}
.tag-list.type-main-card ul li::after {
		content: "/";
		display: block;
		position: absolute;
		left: 0.25rem;
		top: 0;
		width: 0.3125rem;
		height: 1.25rem;
		color: rgba(255, 255, 255, 0.4);
}
.tag-list.type-main-card ul li:first-child {
		padding-left: 0;
}
.tag-list.type-main-card ul li:first-child::after {
		display: none;
		width: 0;
		height: 0;
}
.tag-list.type-main-card ul::after {
		content: "";
		display: block;
		clear: both;
		width: 0;
		height: 0;
}
.tag-list.type-main-card.type-main-card li {
		font-weight: 300;
}
.tag-list.type-popup button {
		position: relative;
		background-color: rgba(28, 28, 28, 0.05);
		color: rgba(28, 28, 28, 0.6);
		padding: 0.125rem 0.75rem;
		text-align: left;
}

.header.co-header ~ .flex-container .tit.type-floating {
		padding-bottom: 0.5rem;
}
.header.co-header ~ .flex-container .tit.type-floating ~ .tab-floating {
		position: sticky;
		width: 100vw;
		left: 0;
		top: 5.25rem;
		background-color: #fff;
		z-index: 1500;
		margin-left: -1.75rem;
		transition: top 0.3s ease-in-out;
}
.header.co-header ~ .flex-container .tit.type-floating ~ .tab-floating .c-tab {
		margin-left: 0;
		padding: 0.75rem 1.75rem;
		transition: padding 0.3s ease-in-out;
}
.header.co-header ~ .flex-container .tit.type-floating ~ .tab-floating ~ .tab-panel.show {
		padding-top: 3rem;
}
.header.co-header ~ .flex-container .tit.type-floating ~ .tab-floating.isScroll .c-tab {
		padding: 0.75rem;
}
.header.co-header ~ .flex-container .tit.type-floating ~ .tab-floating.isScroll::after {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		bottom: -2.25rem;
		width: 100%;
		height: 2.25rem;
		background-image: linear-gradient(to bottom, rgba(25, 25, 25, 0.05) 0%, rgba(217, 217, 217, 0) 38.05%);
		transition: height 0.3s ease-in-out, background-image 0.3s ease-in-out;
}
.header.co-header.scroll-hide ~ .flex-container .tit.type-floating ~ .tab-floating {
		top: -4rem;
}
.header.co-header.scroll-hide ~ .flex-container .tit.type-floating ~ .tab-floating::after {
		bottom: 0;
		height: 0;
		background-image: linear-gradient(to bottom, rgba(25, 25, 25, 0) 0%, rgba(217, 217, 217, 0) 0%);
}

.table-wrap {
		position: relative;
		width: 100%;
		overflow-x: auto;
		overflow-y: hidden;
}
.table-wrap::-webkit-scrollbar, .table-wrap::-webkit-scrollbar-thumb {
		display: none;
}
.table-wrap table {
		position: relative;
}
.table-wrap table thead th,
.table-wrap table tbody td {
		color: rgba(28, 28, 28, 0.8);
		vertical-align: middle;
}
.table-wrap table thead {
		border-bottom: 2px solid rgba(28, 28, 28, 0.1);
}
.table-wrap table thead th {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		text-align: center;
		border-top: 1px solid rgba(28, 28, 28, 0.1);
		padding: 0.5rem;
}
.table-wrap table tbody th,
.table-wrap table tbody td {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		border-bottom: 1px solid rgba(28, 28, 28, 0.1);
		text-align: left;
		padding: 0.75rem;
}

button.ico-plus {
		position: relative;
		width: 2.25rem;
		height: 2.25rem;
		background: #fff;
		box-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
		border-radius: 1.5rem;
}
button.ico-plus::before, button.ico-plus::after {
		content: "";
		display: block;
		position: absolute;
		background-color: #7e8397;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
}
button.ico-plus::before {
		width: 0.125rem;
		height: 0.75rem;
}
button.ico-plus::after {
		width: 0.75rem;
		height: 0.125rem;
}

.swiper {
		position: relative;
}
.swiper.examResSwiper .swiper-wrapper .swiper-slide {
		width: 260px;
}
.swiper.examResSwiper .swiper-wrapper + .swiper-pagination {
		display: none;
}
.swiper.examResSwiper.swiper-ready .swiper-wrapper + .swiper-pagination {
		display: flex;
}

.swiper-pagination {
		position: relative;
		bottom: auto;
		left: auto;
		height: 0.5rem;
		margin-top: 0.75rem;
		display: flex;
		align-items: center;
		justify-content: center;
}
.swiper-pagination .swiper-pagination-bullet {
		min-width: 10px;
		width: 10px;
		height: 10px;
		border: 0 none;
		border-radius: 0.5rem;
		background: rgba(28, 28, 28, 0.1);
		opacity: 1;
		margin: 0 6px;
		transition: background-color 0.3s ease-in-out, width 0.3s ease-in-out;
}
.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
		min-width: 16px;
		background: #253981;
		border-radius: 1.25rem;
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
		bottom: 0;
}

.swiper-container-horizontal > .swiper-pagination-bullets > .swiper-pagination-bullet {
		margin: 0 6px;
}

.btn-swiper-stop {
		position: absolute;
		left: 0;
		top: 0;
		width: 1px;
		height: 1px;
		background-color: transparent;
		z-index: 2;
}

#container .btn-bottom-floating {
		position: sticky;
		z-index: 100;
		left: 0;
		bottom: 0;
		padding: 0.75rem 1.75rem;
		margin-left: -1.75rem;
		width: calc(100% + 3.5rem);
		background-color: #fff;
}
#container .btn-bottom-floating::after {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: -2.25rem;
		width: 100%;
		height: 0;
		opacity: 0;
		background-image: linear-gradient(to top, rgba(25, 25, 25, 0.05) 0%, rgba(217, 217, 217, 0) 38.05%);
		transition: opacity 0.3s ease-in-out;
}
#container .btn-bottom-floating a,
#container .btn-bottom-floating button {
		margin: 0;
}
#container.isFloatingScroll .btn-move-top {
		bottom: 5.75rem;
}
#container.isFloatingScroll .btn-bottom-floating::after {
		height: 2.25rem;
		opacity: 1;
}
#container .c-btn-bottom.type01 {
		margin-top: 3.75rem;
}

.examinationPeriod {
		border-radius: 1.25rem;
		background-color: #f6f6f8;
		height: 2.25rem;
		overflow: hidden;
}
.examinationPeriod ul {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0.5rem 1.25rem;
		position: relative;
}
.examinationPeriod ul li {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: rgba(28, 28, 28, 0.4);
		text-align: left;
}
.examinationPeriod ul li ~ li {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: rgba(28, 28, 28, 0.6);
		text-align: right;
}

.region-list {
		margin-left: -1.75rem;
		width: 100vw;
		background-color: rgba(28, 28, 28, 0.05);
		padding: 2rem 1.75rem;
}
.region-list .list h2 {
		position: relative;
}
.region-list .list h2 .region-list-tit {
		font-size: 1.25rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
		display: flex;
		align-items: center;
		justify-content: flex-start;
}
.region-list .list h2 .region-list-tit i {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		color: rgba(28, 28, 28, 0.4);
		margin-left: 0.5rem;
}
.region-list .list .accordion {
		margin-top: 1.25rem;
		transition: margin-top 0.3s ease-in-out;
}
.region-list .list .accordion.open + .accordion {
		margin-top: 2rem;
}
.region-list .list ~ .list {
		margin-top: 3.75rem;
}

.login-block,
.ti-block,
.rv-block,
.rc-block,
.vgt-block,
.ri-block,
.main-block,
.hi-block,
.ni-block,
.mi-block,
.em-block,
.terms-block,
.guide-block,
.co-block,
.jo-block {
		position: relative;
}
.login-block .tit,
.ti-block .tit,
.rv-block .tit,
.rc-block .tit,
.vgt-block .tit,
.ri-block .tit,
.main-block .tit,
.hi-block .tit,
.ni-block .tit,
.mi-block .tit,
.em-block .tit,
.terms-block .tit,
.guide-block .tit,
.co-block .tit,
.jo-block .tit {
		font-size: 2rem;
		font-weight: 500;
		letter-spacing: -0.0313rem;
		line-height: 2.5rem;
		color: rgba(28, 28, 28, 0.8);
}
.login-block .tit.type-step,
.ti-block .tit.type-step,
.rv-block .tit.type-step,
.rc-block .tit.type-step,
.vgt-block .tit.type-step,
.ri-block .tit.type-step,
.main-block .tit.type-step,
.hi-block .tit.type-step,
.ni-block .tit.type-step,
.mi-block .tit.type-step,
.em-block .tit.type-step,
.terms-block .tit.type-step,
.guide-block .tit.type-step,
.co-block .tit.type-step,
.jo-block .tit.type-step {
		display: flex;
		justify-content: space-between;
		align-items: center;
}
.login-block .tit.type-step .step,
.login-block .tit.type-step .step span,
.ti-block .tit.type-step .step,
.ti-block .tit.type-step .step span,
.rv-block .tit.type-step .step,
.rv-block .tit.type-step .step span,
.rc-block .tit.type-step .step,
.rc-block .tit.type-step .step span,
.vgt-block .tit.type-step .step,
.vgt-block .tit.type-step .step span,
.ri-block .tit.type-step .step,
.ri-block .tit.type-step .step span,
.main-block .tit.type-step .step,
.main-block .tit.type-step .step span,
.hi-block .tit.type-step .step,
.hi-block .tit.type-step .step span,
.ni-block .tit.type-step .step,
.ni-block .tit.type-step .step span,
.mi-block .tit.type-step .step,
.mi-block .tit.type-step .step span,
.em-block .tit.type-step .step,
.em-block .tit.type-step .step span,
.terms-block .tit.type-step .step,
.terms-block .tit.type-step .step span,
.guide-block .tit.type-step .step,
.guide-block .tit.type-step .step span,
.co-block .tit.type-step .step,
.co-block .tit.type-step .step span,
.jo-block .tit.type-step .step,
.jo-block .tit.type-step .step span {
		font-size: 1.25rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.2);
}
.login-block .tit.type-step .step span:first-child,
.ti-block .tit.type-step .step span:first-child,
.rv-block .tit.type-step .step span:first-child,
.rc-block .tit.type-step .step span:first-child,
.vgt-block .tit.type-step .step span:first-child,
.ri-block .tit.type-step .step span:first-child,
.main-block .tit.type-step .step span:first-child,
.hi-block .tit.type-step .step span:first-child,
.ni-block .tit.type-step .step span:first-child,
.mi-block .tit.type-step .step span:first-child,
.em-block .tit.type-step .step span:first-child,
.terms-block .tit.type-step .step span:first-child,
.guide-block .tit.type-step .step span:first-child,
.co-block .tit.type-step .step span:first-child,
.jo-block .tit.type-step .step span:first-child {
		color: rgba(28, 28, 28, 0.4);
}
.login-block .c-tab.type-slide,
.ti-block .c-tab.type-slide,
.rv-block .c-tab.type-slide,
.rc-block .c-tab.type-slide,
.vgt-block .c-tab.type-slide,
.ri-block .c-tab.type-slide,
.main-block .c-tab.type-slide,
.hi-block .c-tab.type-slide,
.ni-block .c-tab.type-slide,
.mi-block .c-tab.type-slide,
.em-block .c-tab.type-slide,
.terms-block .c-tab.type-slide,
.guide-block .c-tab.type-slide,
.co-block .c-tab.type-slide,
.jo-block .c-tab.type-slide {
		margin-left: -1.75rem;
		width: 100vw;
		padding-top: 1.25rem;
		padding-right: 1.75rem;
		padding-bottom: 3.75rem;
		padding-left: 1.75rem;
}
.login-block .detail-tit,
.ti-block .detail-tit,
.rv-block .detail-tit,
.rc-block .detail-tit,
.vgt-block .detail-tit,
.ri-block .detail-tit,
.main-block .detail-tit,
.hi-block .detail-tit,
.ni-block .detail-tit,
.mi-block .detail-tit,
.em-block .detail-tit,
.terms-block .detail-tit,
.guide-block .detail-tit,
.co-block .detail-tit,
.jo-block .detail-tit {
		font-size: 1.5rem;
		font-weight: 500;
		letter-spacing: -0.0313rem;
		line-height: 2rem;
		color: rgba(28, 28, 28, 0.8);
		margin-bottom: 1.25rem;
}
.login-block .detail-info,
.ti-block .detail-info,
.rv-block .detail-info,
.rc-block .detail-info,
.vgt-block .detail-info,
.ri-block .detail-info,
.main-block .detail-info,
.hi-block .detail-info,
.ni-block .detail-info,
.mi-block .detail-info,
.em-block .detail-info,
.terms-block .detail-info,
.guide-block .detail-info,
.co-block .detail-info,
.jo-block .detail-info {
		position: relative;
		padding: 1.25rem 0;
		display: flex;
		align-items: center;
		justify-content: flex-end;
}
.login-block .detail-info span,
.ti-block .detail-info span,
.rv-block .detail-info span,
.rc-block .detail-info span,
.vgt-block .detail-info span,
.ri-block .detail-info span,
.main-block .detail-info span,
.hi-block .detail-info span,
.ni-block .detail-info span,
.mi-block .detail-info span,
.em-block .detail-info span,
.terms-block .detail-info span,
.guide-block .detail-info span,
.co-block .detail-info span,
.jo-block .detail-info span {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: #7e8397;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: auto;
		height: 2rem;
		margin-left: 0.75rem;
		padding-left: 2rem;
		background-image: url("../images/ico/ico_calendar_01.svg");
		background-size: 2rem 2rem;
		background-position: left center;
		background-repeat: no-repeat;
}
.login-block .detail-info span:first-of-type,
.ti-block .detail-info span:first-of-type,
.rv-block .detail-info span:first-of-type,
.rc-block .detail-info span:first-of-type,
.vgt-block .detail-info span:first-of-type,
.ri-block .detail-info span:first-of-type,
.main-block .detail-info span:first-of-type,
.hi-block .detail-info span:first-of-type,
.ni-block .detail-info span:first-of-type,
.mi-block .detail-info span:first-of-type,
.em-block .detail-info span:first-of-type,
.terms-block .detail-info span:first-of-type,
.guide-block .detail-info span:first-of-type,
.co-block .detail-info span:first-of-type,
.jo-block .detail-info span:first-of-type {
		margin-left: 0;
		background-image: url("../images/ico/ico_eye_01.svg");
}
.login-block .detail-info::after,
.ti-block .detail-info::after,
.rv-block .detail-info::after,
.rc-block .detail-info::after,
.vgt-block .detail-info::after,
.ri-block .detail-info::after,
.main-block .detail-info::after,
.hi-block .detail-info::after,
.ni-block .detail-info::after,
.mi-block .detail-info::after,
.em-block .detail-info::after,
.terms-block .detail-info::after,
.guide-block .detail-info::after,
.co-block .detail-info::after,
.jo-block .detail-info::after {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 1px;
		background-color: rgba(28, 28, 28, 0.05);
}
.login-block .detail-editor,
.ti-block .detail-editor,
.rv-block .detail-editor,
.rc-block .detail-editor,
.vgt-block .detail-editor,
.ri-block .detail-editor,
.main-block .detail-editor,
.hi-block .detail-editor,
.ni-block .detail-editor,
.mi-block .detail-editor,
.em-block .detail-editor,
.terms-block .detail-editor,
.guide-block .detail-editor,
.co-block .detail-editor,
.jo-block .detail-editor {
		position: relative;
		min-height: 320px;
}
.login-block .detail-editor.type-vdo,
.ti-block .detail-editor.type-vdo,
.rv-block .detail-editor.type-vdo,
.rc-block .detail-editor.type-vdo,
.vgt-block .detail-editor.type-vdo,
.ri-block .detail-editor.type-vdo,
.main-block .detail-editor.type-vdo,
.hi-block .detail-editor.type-vdo,
.ni-block .detail-editor.type-vdo,
.mi-block .detail-editor.type-vdo,
.em-block .detail-editor.type-vdo,
.terms-block .detail-editor.type-vdo,
.guide-block .detail-editor.type-vdo,
.co-block .detail-editor.type-vdo,
.jo-block .detail-editor.type-vdo {
		padding-bottom: 56.25%; /* 16:9 */
		height: 0;
}
.login-block .detail-editor.type-vdo iframe,
.ti-block .detail-editor.type-vdo iframe,
.rv-block .detail-editor.type-vdo iframe,
.rc-block .detail-editor.type-vdo iframe,
.vgt-block .detail-editor.type-vdo iframe,
.ri-block .detail-editor.type-vdo iframe,
.main-block .detail-editor.type-vdo iframe,
.hi-block .detail-editor.type-vdo iframe,
.ni-block .detail-editor.type-vdo iframe,
.mi-block .detail-editor.type-vdo iframe,
.em-block .detail-editor.type-vdo iframe,
.terms-block .detail-editor.type-vdo iframe,
.guide-block .detail-editor.type-vdo iframe,
.co-block .detail-editor.type-vdo iframe,
.jo-block .detail-editor.type-vdo iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
}
.login-block .detail-editor .dummy-area,
.ti-block .detail-editor .dummy-area,
.rv-block .detail-editor .dummy-area,
.rc-block .detail-editor .dummy-area,
.vgt-block .detail-editor .dummy-area,
.ri-block .detail-editor .dummy-area,
.main-block .detail-editor .dummy-area,
.hi-block .detail-editor .dummy-area,
.ni-block .detail-editor .dummy-area,
.mi-block .detail-editor .dummy-area,
.em-block .detail-editor .dummy-area,
.terms-block .detail-editor .dummy-area,
.guide-block .detail-editor .dummy-area,
.co-block .detail-editor .dummy-area,
.jo-block .detail-editor .dummy-area {
		background: url("../images/dummy/dummy_editorarea.png") center/contain rgba(28, 28, 28, 0.05) no-repeat;
		width: 100%;
		min-height: 320px;
}
.login-block .detail-btn,
.ti-block .detail-btn,
.rv-block .detail-btn,
.rc-block .detail-btn,
.vgt-block .detail-btn,
.ri-block .detail-btn,
.main-block .detail-btn,
.hi-block .detail-btn,
.ni-block .detail-btn,
.mi-block .detail-btn,
.em-block .detail-btn,
.terms-block .detail-btn,
.guide-block .detail-btn,
.co-block .detail-btn,
.jo-block .detail-btn {
		margin-top: 2.5rem;
}
.login-block .btn-move-list,
.ti-block .btn-move-list,
.rv-block .btn-move-list,
.rc-block .btn-move-list,
.vgt-block .btn-move-list,
.ri-block .btn-move-list,
.main-block .btn-move-list,
.hi-block .btn-move-list,
.ni-block .btn-move-list,
.mi-block .btn-move-list,
.em-block .btn-move-list,
.terms-block .btn-move-list,
.guide-block .btn-move-list,
.co-block .btn-move-list,
.jo-block .btn-move-list {
		margin-top: 1.25rem;
}

.login-block {
		padding: 3.75rem 1.75rem;
}

.main-block {
		padding: 3.75rem 1.75rem 0;
}

.ti-block,
.rv-block,
.rc-block,
.vgt-block,
.ri-block,
.hi-block,
.ni-block,
.mi-block,
.em-block,
.terms-block,
.guide-block,
.jo-block,
.co-block {
		padding: 3.75rem 1.75rem 5rem;
}

.rv-block .tit + img,
.rc-block .tit + img,
.em-block .tit + img {
		display: block;
		width: 10.9375rem;
		height: auto;
		text-align: center;
		margin: 5rem auto 0;
}
.rv-block .tit + img + h2,
.rc-block .tit + img + h2,
.em-block .tit + img + h2 {
		font-size: 1.25rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
		color: #2f7ad3;
		text-align: center;
		margin-top: 2.5rem;
}
.rv-block .tit + img + h2 + .box,
.rc-block .tit + img + h2 + .box,
.em-block .tit + img + h2 + .box {
		margin-top: 1.25rem;
}
.rv-block .tit + img + h2 + .box + .c-input-list,
.rc-block .tit + img + h2 + .box + .c-input-list,
.em-block .tit + img + h2 + .box + .c-input-list {
		margin-top: 3.75rem;
}
.rv-block h2 + .cont,
.rc-block h2 + .cont,
.em-block h2 + .cont {
		margin-top: 0.75rem;
}

.type-btn-floating {
		padding-bottom: 3.25rem;
}

.login-block .login-logo {
		width: auto;
		height: 2rem;
}
.login-block .c-input-list {
		margin-top: 2.5rem;
}
.login-block .c-input-list .per-column {
		position: relative;
}
.login-block .c-tab {
		margin-top: 2.5rem;
}
.login-block *[aria-labelledby=comn-login-tab-panel] .co-btn {
		margin-top: 2.5rem;
}
.login-block .info-txt {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: rgba(28, 28, 28, 0.6);
		margin-top: 1.25rem;
		word-break: keep-all;
}
.login-block .c-chkbox {
		margin-top: 2.5rem;
}
.login-block .co-btn {
		margin-top: 1.25rem;
}
.login-block .tab-panel .c-input-list {
		margin-top: 1.25rem;
}
.login-block .tab-panel .c-input-list + .c-btn {
		margin-top: 2.5rem;
}
.login-block .tab-panel + .btn-list {
		flex-wrap: wrap;
}
.login-block .tab-panel + .btn-list a {
		padding: 0 0.5rem;
}
.login-block .btn-list {
		margin-top: 0.75rem;
}
.login-block .info-box {
		background: rgba(28, 28, 28, 0.05);
		border-radius: 1.875rem;
		padding: 2rem 0;
		box-sizing: border-box;
		margin-top: 2.5rem;
}
.login-block .info-box p {
		color: rgba(28, 28, 28, 0.6);
		text-align: center;
		word-break: keep-all;
}
.login-block .info-box p:first-child {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: #1c1c1c;
}
.login-block .info-box p:first-child a {
		color: #1c1c1c;
		display: inline-block;
}
.login-block .info-box p:last-child {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
}

.id-list dl {
		margin-top: 2.5rem;
}
.id-list dl dt {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		color: rgba(28, 28, 28, 0.4);
}
.id-list dl dd {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
		margin-top: 0.25rem;
}
.id-list dl dd span {
		color: #253981;
}
.id-list dl:first-child {
		margin-top: 0;
}

.ti-block .tab-panel[aria-labelledby=health-system-tab-panel] h2 {
		font-size: 1.5rem;
		font-weight: 500;
		letter-spacing: -0.0313rem;
		line-height: 2rem;
		color: rgba(28, 28, 28, 0.8);
}
.ti-block .tab-panel[aria-labelledby=health-system-tab-panel] h2 + p + h3 {
		padding-top: 5rem;
		position: relative;
}
.ti-block .tab-panel[aria-labelledby=health-system-tab-panel] h2 + p + h3::after {
		content: "";
		display: block;
		position: absolute;
		width: 100%;
		height: 1px;
		top: 2.5rem;
		left: 0;
		background-color: rgba(28, 28, 28, 0.1);
}
.ti-block .tab-panel[aria-labelledby=health-system-tab-panel] h2 + ul {
		margin-top: 1.25rem;
}
.ti-block .tab-panel[aria-labelledby=health-system-tab-panel] h2 + ul li ~ li {
		margin-top: 0.75rem;
}
.ti-block .tab-panel[aria-labelledby=health-system-tab-panel] h2 + ul + h3 + ul {
		margin-top: 1.25rem;
}
.ti-block .tab-panel[aria-labelledby=health-system-tab-panel] h2 + ul + h3 + ul li ~ li {
		margin-top: 0.75rem;
}
.ti-block .tab-panel[aria-labelledby=health-system-tab-panel] h2 + h3 {
		margin-top: 1.25rem;
}
.ti-block .tab-panel[aria-labelledby=health-system-tab-panel] h2 + h3 + ul li + li {
		margin-top: 0.75rem;
}
.ti-block .tab-panel[aria-labelledby=health-system-tab-panel] h3 {
		font-size: 1.125rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
		color: #2f7ad3;
}
.ti-block .tab-panel[aria-labelledby=health-system-tab-panel] h3 ~ h3 {
		margin-top: 3.75rem;
}
.ti-block .tab-panel[aria-labelledby=health-system-tab-panel] h3 + ul {
		margin-top: 1rem;
}
.ti-block .tab-panel[aria-labelledby=health-system-tab-panel] h3 + ul li ~ li {
		margin-top: 0.5rem;
}
.ti-block .tab-panel[aria-labelledby=health-system-tab-panel] h3 + ul.list.type1 li::after {
		top: 0.75rem;
}
.ti-block .tab-panel[aria-labelledby=health-system-tab-panel] p,
.ti-block .tab-panel[aria-labelledby=health-system-tab-panel] ul li {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
}
.ti-block .tab-panel[aria-labelledby=health-system-tab-panel] p {
		margin-top: 1.25rem;
}
.ti-block .tab-panel[aria-labelledby=health-system-tab-panel] ul + h2 {
		padding-top: 5rem;
		position: relative;
}
.ti-block .tab-panel[aria-labelledby=health-system-tab-panel] ul + h2 + h3 {
		margin-top: 1.25rem;
}
.ti-block .tab-panel[aria-labelledby=health-system-tab-panel] ul + h2 ~ h3 + ul li ~ li {
		margin-top: 0.75rem;
}
.ti-block .tab-panel[aria-labelledby=health-system-tab-panel] ul + h2::after {
		content: "";
		display: block;
		position: absolute;
		width: 100%;
		height: 1px;
		top: 2.5rem;
		left: 0;
		background-color: rgba(28, 28, 28, 0.1);
}
.ti-block .tab-panel[aria-labelledby=health-system-tab-panel] ul + h3 {
		margin-top: 3.75rem;
}
.ti-block .tab-panel[aria-labelledby=our-health-tab-panel] h2 {
		font-size: 1.125rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
		color: #1c1c1c;
}
.ti-block .tab-panel[aria-labelledby=our-health-tab-panel] h2 + ul {
		margin-top: 1rem;
}
.ti-block .tab-panel[aria-labelledby=our-health-tab-panel] h2 ~ h2 {
		margin-top: 3.75rem;
}
.ti-block .tab-panel[aria-labelledby=our-health-tab-panel] h2 + h3 {
		margin-top: 1rem;
}
.ti-block .tab-panel[aria-labelledby=our-health-tab-panel] h3 {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
}
.ti-block .tab-panel[aria-labelledby=our-health-tab-panel] h3 + ul {
		margin-top: 0.75rem;
}
.ti-block .tab-panel[aria-labelledby=our-health-tab-panel] h3 + ul li {
		padding-left: 1.25rem;
}
.ti-block .tab-panel[aria-labelledby=our-health-tab-panel] h3 ~ h3 {
		margin-top: 2.5rem;
}
.ti-block .tab-panel[aria-labelledby=our-health-tab-panel] ul li ~ li {
		margin-top: 0.5rem;
}

.rv-block.type-floating-btn {
		padding: 3.75rem 1.75rem 4.25rem;
}
.rv-block .tit + .c-select {
		margin-top: 1.25rem;
}
.rv-block .tit + .c-select + .info-txt {
		margin: 3.75rem 0 0.75rem;
		position: relative;
		display: block;
		text-align: right;
}
.rv-block .tit + .c-select + .info-txt li ~ li {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: rgba(28, 28, 28, 0.6);
}
.rv-block .tit + .c-select + .info-txt li ~ li span {
		color: #2f7ad3;
		display: inline-block;
		margin: 0 0.25rem;
}
.rv-block .tit + .c-select + .info-txt + .sub-card.type2 ~ .sub-card.type2 {
		margin-top: 2.5rem;
}
.rv-block .tit + h2 {
		margin-top: 2.5rem;
}
.rv-block .tit + h2 ~ h2 {
		padding-top: 5rem;
		position: relative;
}
.rv-block .tit + h2 ~ h2::after {
		content: "";
		display: block;
		position: absolute;
		width: 100%;
		height: 1px;
		left: 0;
		top: 2.5rem;
		background-color: rgba(28, 28, 28, 0.1);
}
.rv-block h2 {
		font-size: 1.25rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
		position: relative;
}
.rv-block h2.type-link {
		display: flex;
		align-items: center;
		justify-content: space-between;
}
.rv-block h2.type-link a {
		font-size: 0.75rem;
		line-height: 1.25rem;
		color: #2f7ad3;
		text-decoration: underline;
}
.rv-block h2 + .c-input-list {
		margin-top: 1.25rem;
}
.rv-block h2 + h3 {
		margin-top: 1.25rem;
}
.rv-block h2 + h3 ~ h3 {
		padding-top: 1.75rem;
}
.rv-block h2 + .tag-list {
		margin-top: 1.375rem;
}
.rv-block h2 + .tag-list + .type-link {
		padding-top: 4.25rem;
}
.rv-block h2 + .tag-list + .type-link::after {
		top: 1.75rem;
}
.rv-block h3 {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: #2f7ad3;
}
.rv-block h3 + .tag-list {
		margin-top: 0.5rem;
}
.rv-block .c-txtarea-field {
		height: auto;
}
.rv-block .c-txtarea-field textarea {
		height: 10rem;
		background: rgba(255, 255, 255, 0.8);
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
}
.rv-block .c-txtarea-field textarea::placeholder {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
}
.rv-block .c-txtarea-field textarea:disabled {
		border-color: rgba(28, 28, 28, 0.1);
		background-color: rgba(28, 28, 28, 0.05);
		opacity: 1;
}
.rv-block .tab-panel[aria-labelledby=ap-step-1-panel] p {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
}
.rv-block .tab-panel[aria-labelledby=ap-step-1-panel] p ~ p {
		margin-top: 0.75rem;
}
.rv-block .tab-panel[aria-labelledby=ap-step-1-panel] .examinationPeriod {
		margin-top: 2.5rem;
}
.rv-block .tab-panel[aria-labelledby=ap-step-1-panel] .sub-card.type1 {
		margin-top: 1.25rem;
}
.rv-block .tab-panel[aria-labelledby=ap-step-1-panel] .null-contents {
		margin-top: 2.5rem;
}
.rv-block .tab-panel[aria-labelledby=ap-step-1-panel] .accordion.type4 {
		margin-top: 3.75rem;
}
.rv-block .tab-panel[aria-labelledby=ap-step-2-panel] .region-list {
		margin-top: 2.5rem;
}
.rv-block .tab-panel[aria-labelledby=ap-step-3-panel] .accordion-list {
		margin-top: 2.5rem;
}
.rv-block .tab-panel[aria-labelledby=ap-step-3-panel] .accordion-list .accordion {
		margin-top: 1.25rem;
}
.rv-block .tab-panel[aria-labelledby=ap-step-3-panel] .c-input-list {
		margin-top: 3.75rem;
}
.rv-block .tab-panel[aria-labelledby=ap-step-4-panel] .c-loc {
		margin: 1.25rem 0;
}
.rv-block .tab-panel[aria-labelledby=ap-step-4-panel] .c-input-list .col.per-column.label-input .col ~ .col {
		margin-top: 0.75rem;
}
.rv-block .tab-panel[aria-labelledby=ap-step-4-panel] .box + .c-input-list {
		margin-top: 1.25rem;
}
.rv-block .tab-panel[aria-labelledby=ap-step-6-panel] > h2 {
		margin-top: 0;
}
.rv-block .tab-panel[aria-labelledby=ap-step-6-panel] > h2 ~ h2 {
		padding-top: 5rem;
		position: relative;
}
.rv-block .tab-panel[aria-labelledby=ap-step-6-panel] > h2 ~ h2::after {
		content: "";
		display: block;
		position: absolute;
		width: 100%;
		height: 1px;
		left: 0;
		top: 2.5rem;
		background-color: rgba(28, 28, 28, 0.1);
}
.rv-block .tab-panel[aria-labelledby=step-1-panel] .accordion.type6 ~ .accordion.type6 {
		margin-top: 1.5rem;
}
.rv-block .tab-panel[aria-labelledby=step-2-panel] > .c-chkbox {
		float: right;
}
.rv-block .tab-panel[aria-labelledby=step-2-panel] > .c-chkbox + .c-input-list {
		float: none;
		clear: both;
		padding-top: 1.25rem;
}
.rv-block .tab-panel[aria-labelledby=step-2-panel] > .c-chkbox + .c-input-list ~ .c-input-list {
		margin-top: 1.75rem;
}
.rv-block .tab-panel[aria-labelledby=ap-step-5-panel] > p, .rv-block .tab-panel[aria-labelledby=step-3-panel] > p {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: rgba(28, 28, 28, 0.8);
}
.rv-block .tab-panel[aria-labelledby=ap-step-5-panel] > p + .box, .rv-block .tab-panel[aria-labelledby=step-3-panel] > p + .box {
		margin-top: 0.5rem;
}
.rv-block .tab-panel[aria-labelledby=ap-step-5-panel] h2, .rv-block .tab-panel[aria-labelledby=step-3-panel] h2 {
		margin-top: 3.75rem;
}
.rv-block .tab-panel[aria-labelledby=ap-step-5-panel] h2 + .c-input-list, .rv-block .tab-panel[aria-labelledby=step-3-panel] h2 + .c-input-list {
		margin-top: 1.25rem;
}
.rv-block .tab-panel[aria-labelledby=ap-step-5-panel] h2 + .c-input-list ~ .c-input-list, .rv-block .tab-panel[aria-labelledby=step-3-panel] h2 + .c-input-list ~ .c-input-list {
		margin-top: 3.75rem;
}
.rv-block .tab-panel[aria-labelledby=ap-step-5-panel] h2 + .c-input-list + .c-loc, .rv-block .tab-panel[aria-labelledby=step-3-panel] h2 + .c-input-list + .c-loc {
		margin-top: 3.75rem;
}
.rv-block .tab-panel[aria-labelledby=ap-step-5-panel] h2 + .c-input-list + .c-loc + .c-input-list, .rv-block .tab-panel[aria-labelledby=step-3-panel] h2 + .c-input-list + .c-loc + .c-input-list {
		margin-top: 1.75rem;
}
.rv-block .tab-panel[aria-labelledby=ap-step-5-panel] h2 + .terms, .rv-block .tab-panel[aria-labelledby=step-3-panel] h2 + .terms {
		margin-top: 1.25rem;
}
.rv-block .tab-panel[aria-labelledby=ap-step-5-panel] h2 + .terms ~ p, .rv-block .tab-panel[aria-labelledby=step-3-panel] h2 + .terms ~ p {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.6);
		margin-top: 2.5rem;
}
.rv-block .tab-panel[aria-labelledby=ap-step-5-panel] h2 + .terms ~ p ~ p, .rv-block .tab-panel[aria-labelledby=step-3-panel] h2 + .terms ~ p ~ p {
		margin-top: 0.5rem;
}
.rv-block input[type=text].type-link {
		color: #2f7ad3;
		text-decoration: underline;
}
.rv-block .btn-next,
.rv-block .btn-cancel {
		margin-top: 3.75rem;
}

#popupReservationFilter .co-chk-list {
		margin-top: 1.75rem;
}

#popupAddTarget p {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.6);
}
#popupAddTarget p.input-err-txt {
		color: #ba1d3c;
}
#popupAddTarget p.input-ref-txt {
		color: rgba(28, 28, 28, 0.6);
}
#popupAddTarget p.input-err-txt, #popupAddTarget p.input-ref-txt {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
}
#popupAddTarget .c-input-list {
		margin-top: 2.5rem;
}

#popupDetailedExamItems .cont-box h2 {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		color: #2f7ad3;
}
#popupDetailedExamItems .cont-box h2 ~ p {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
		margin-top: 0.25rem;
}
#popupDetailedExamItems .cont-box .c-select {
		margin-top: 2.5rem;
}
#popupDetailedExamItems .cont-box .table-wrap {
		margin-top: 1.75rem;
		overflow-x: hidden;
}
#popupDetailedExamItems .cont-box .table-wrap table {
		width: 100%;
		border-bottom: 2px solid rgba(28, 28, 28, 0.2);
}
#popupDetailedExamItems .cont-box .table-wrap table thead {
		border-bottom: 2px solid rgba(28, 28, 28, 0.2);
}
#popupDetailedExamItems .cont-box .table-wrap table thead th {
		border-top: 0 none;
		width: 39%;
}
#popupDetailedExamItems .cont-box .table-wrap table thead th ~ th {
		width: 61%;
}
#popupDetailedExamItems .cont-box .table-wrap table tbody th {
		vertical-align: middle;
		border-bottom: 2px solid rgba(28, 28, 28, 0.2);
}
#popupDetailedExamItems .cont-box .table-wrap table tbody td {
		padding-top: 0.375rem;
		padding-right: 0;
		padding-bottom: 0.375rem;
		padding-left: 0.5rem;
}
#popupDetailedExamItems .cont-box .table-wrap table tbody td.last {
		border-bottom: 2px solid rgba(28, 28, 28, 0.2);
}
#popupDetailedExamItems .cont-box .table-wrap table tbody td p {
		display: table;
		table-layout: fixed;
		width: 100%;
}
#popupDetailedExamItems .cont-box .table-wrap table tbody td p span,
#popupDetailedExamItems .cont-box .table-wrap table tbody td p a {
		vertical-align: middle;
}
#popupDetailedExamItems .cont-box .table-wrap table tbody td p span {
		display: inline-block;
		width: calc(100% - 2.5rem);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
}

#popupNotAvailableInspection .in-cont p span,
#popupApplicationCompleted .in-cont p span {
		display: block;
}
#popupNotAvailableInspection .in-cont p span ~ span,
#popupApplicationCompleted .in-cont p span ~ span {
		margin-top: 0.75rem;
}

#popupPrecautionsBeforeExam .in-cont p {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
}
#popupPrecautionsBeforeExam .in-cont p ~ p {
		margin-top: 0.75rem;
}

#popupProductAdditionalAmount .cont-box dl {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
}
#popupProductAdditionalAmount .cont-box dl dd {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
		padding-right: 0.75rem;
}
#popupProductAdditionalAmount .cont-box dl dd ~ dd {
		color: rgba(28, 28, 28, 0.4);
		display: flex;
		align-items: center;
		justify-content: flex-end;
		white-space: nowrap;
		padding-right: 0;
}
#popupProductAdditionalAmount .cont-box dl dd ~ dd span {
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5rem;
		color: #1c1c1c;
		display: inline-block;
		margin-right: 0.25rem;
}
#popupProductAdditionalAmount .cont-box dl ~ dl {
		margin-top: 1rem;
}
#popupProductAdditionalAmount .cont-box dl.sum {
		margin-top: 0;
		padding-top: 2.5rem;
}
#popupProductAdditionalAmount .cont-box dl.sum dd ~ dd span {
		color: #2f7ad3;
}
#popupProductAdditionalAmount .cont-box dl.sum::after {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: 1.25rem;
		width: 100%;
		height: 1px;
		background-color: rgba(28, 28, 28, 0.4);
}

.vgt-block .tit + .c-select {
		margin-top: 3.75rem;
}
.vgt-block .co-img-card {
		padding: 0;
		margin-top: 2.5rem;
}
.vgt-block .co-img-card::after {
		display: none;
		width: 0;
		height: 0;
}
.vgt-block .co-img-card .thumbnail {
		padding-bottom: 59.21%;
		position: relative;
		overflow: hidden;
		border: 1px solid rgba(28, 28, 28, 0.05);
		border-radius: 0.75rem;
}
.vgt-block .co-img-card .thumbnail > img {
		min-width: 100%;
		min-height: 100%;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		object-fit: cover;
}

.popup-institution-details h2 {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		color: rgba(28, 28, 28, 0.8);
}
.popup-institution-details h2 ~ h2 {
		padding-top: 0.5rem;
}
.popup-institution-details h2 + .box {
		margin-top: 0.75rem;
		margin-bottom: 1.25rem;
}
.popup-institution-details h2 + .box + .map {
		margin-top: 1.75rem;
		width: 100%;
		min-width: 100%;
		padding-bottom: 65.8%;
		border-radius: 0.75rem;
}
.popup-institution-details h2 + .box + .map img {
		width: 100%;
		height: auto;
}
.popup-institution-details h2 + .box + .map + h2 {
		padding-top: 1.75rem;
}
.popup-institution-details .instDetailsCenter {
		overflow: hidden;
}
.popup-institution-details .instDetailsCenter .swiper-wrapper {
		height: auto;
}
.popup-institution-details .instDetailsCenter .swiper-slide {
		width: 100%;
		height: auto;
		border-radius: 0.75rem;
		overflow: hidden;
}
.popup-institution-details .instDetailsCenter .swiper-slide .image {
		position: relative;
		padding-bottom: 57.971%;
		overflow: hidden;
		width: 100%;
}
.popup-institution-details .instDetailsCenter .swiper-slide .image > img {
		position: absolute;
		min-width: 100%;
		min-height: 100%;
}
.popup-institution-details .instDetailsCenter .swiper-slide img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
}
.popup-institution-details .instDetailsCenter.swiper-ready .swiper-slide {
		height: auto;
}
.popup-institution-details .instDetailsCenter + h2 {
		padding-top: 1.75rem;
}
.popup-institution-details .accordion-list .accordion ~ .accordion {
		margin-top: 1.25rem;
}
.popup-institution-details .full-body {
		padding-bottom: 2.5rem;
}

.ri-block .tit + .c-select,
.ri-block .txt + .c-select {
		margin-top: 1.25rem;
}
.ri-block .tit + .c-select + .swiper,
.ri-block .txt + .c-select + .swiper {
		margin-top: 1.25rem;
		margin-bottom: 2.25rem;
}
.ri-block .tit.type-floating ~ .tab-floating ~ .tab-panel.show,
.ri-block .txt.type-floating ~ .tab-floating ~ .tab-panel.show {
		padding-top: 0 !important;
		margin-top: 0.5rem;
}
.ri-block .no-data img {
		display: block;
		width: 10.937rem;
		height: auto;
		text-align: center;
		margin: 5rem auto 0;
}
.ri-block .no-data img + h2 {
		font-size: 1.25rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
		color: #2f7ad3;
		text-align: center;
		margin-top: 2.5rem;
}
.ri-block .no-data img + h2 + .box {
		margin-top: 1.25rem;
}
.ri-block .no-data img + h2 + .box + .c-input-list {
		margin-top: 3.75rem;
}

.appointment-checkup-panel .box.type3 + .c-input-list {
		margin-top: 1.25rem;
}

#popupViewMoreExam .in-cont .c-input-sub-group {
		margin-top: 0;
}
#popupViewMoreExam .in-cont .c-input-sub-group .per-column {
		margin-top: 0;
}
#popupViewMoreExam .in-cont .c-input-sub-group .per-column ~ .per-column {
		margin-top: 0.5rem;
}
#popupViewMoreExam .in-cont .c-rdochk {
		display: block;
		width: 100%;
		margin-top: 1.25rem;
}
#popupViewMoreExam .in-cont .c-rdochk label {
		display: inline-flex;
}
#popupViewMoreExam .in-cont .c-rdochk label span {
		max-width: calc(100% - 20px);
		margin-left: 0;
		padding-left: 0.5rem;
}

#popupCompareExamRes .cont-box h2 {
		display: flex;
		align-items: center;
}
#popupCompareExamRes .cont-box h2 .ico-ques {
		margin-left: 0.5rem;
}
#popupCompareExamRes .cont-box .box {
		margin-top: 2.5rem;
}

.rc-block .tab-panel[aria-labelledby=rc-consulting-panel] p {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
}
.rc-block .tab-panel[aria-labelledby=rc-consulting-panel] p ~ p {
		margin-top: 0.75rem;
}
.rc-block .tab-panel[aria-labelledby=rc-consulting-panel] .c-input-list,
.rc-block .tab-panel[aria-labelledby=rc-consulting-panel] .terms,
.rc-block .tab-panel[aria-labelledby=rc-consulting-panel] .box {
		margin-top: 2.5rem;
}

.main-block h1 {
		font-size: 2rem;
		font-weight: 500;
		letter-spacing: -0.0313rem;
		line-height: 2.5rem;
		color: #7e8397;
		display: flex;
		align-items: center;
}
.main-block h1 span {
		color: rgba(28, 28, 28, 0.8);
		display: inline-block;
		padding-right: 0.5rem;
}
.main-block h1 + p {
		font-size: 1.125rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
		color: #7e8397;
		margin-top: 0.5rem;
}
.main-block h2 {
		font-size: 1.25rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
		color: #7e8397;
		margin-top: 5rem;
		margin-bottom: 1rem;
}
.main-block h2 a {
		padding-right: 1.75rem;
		background-image: url("../../images/ico/ico_arrow_main_event_24x24.svg");
		background-position: right center;
		background-size: 1.5rem 1.5rem;
		background-repeat: no-repeat;
}
.main-block h2.alt-txt {
		margin-bottom: 0;
}
.main-block .c-tab.type-slide {
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
		margin-top: 2.5rem;
}
.main-block .eventSwiper .swiper-slide {
		display: block;
		padding: 0;
		background: transparent;
		width: 100%;
		height: auto;
		overflow: hidden;
		border: 1px solid rgba(28, 28, 28, 0.05);
		filter: drop-shadow(0.125rem 0.125rem 0.625rem rgba(0, 0, 0, 0.1));
		border-radius: 2rem;
}
.main-block .eventSwiper .swiper-slide img {
		display: block;
		width: 100%;
		height: auto;
}
.main-block .noticeSwiper {
		position: relative;
		width: calc(100% + 3.5rem);
		margin-left: -1.75rem;
		height: 3rem;
		overflow: hidden;
		box-shadow: 0.125rem 0.125rem 0.625rem rgba(0, 0, 0, 0.1);
}
.main-block .noticeSwiper .swiper-wrapper .swiper-slide {
		display: flex;
		align-items: center;
		padding: 0.75rem 1.75rem;
}
.main-block .noticeSwiper .swiper-wrapper .swiper-slide p {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		color: #2f7ad3;
}
.main-block .noticeSwiper .swiper-wrapper .swiper-slide p ~ p {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		color: rgba(28, 28, 28, 0.8);
		padding-left: 1rem;
		width: calc(100% - 3.375rem);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
}

#popupViewcheckupResults .in-cont .c-input-list,
#popupViewcheckupResults .in-cont .terms {
		margin-top: 2.5rem;
}

#popupDeliveryResults .in-cont .c-input-list,
#popupDeliveryResults .in-cont .terms {
		margin-top: 2.5rem;
}
#popupDeliveryResults .in-cont .c-input-list + p,
#popupDeliveryResults .in-cont .terms + p {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: rgba(28, 28, 28, 0.6);
}
#popupDeliveryResults .in-cont .c-input-list + p {
		margin-top: 0.25rem;
}
#popupDeliveryResults .in-cont .terms + p {
		margin-top: 1.25rem;
		color: rgba(28, 28, 28, 0.4);
}

#popupNoticeEmployment .in-cont .card_01,
#popupNoticeEmployment .in-cont .card_01 ~ p {
		margin-top: 2.5rem;
}
#popupNoticeEmployment .in-cont .card_01 ~ .card_01 {
		margin-top: 1.25rem;
}
#popupNoticeEmployment .in-cont .card_01 ~ p {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.25rem;
		color: rgba(28, 28, 28, 0.4);
}

.hi-block .txt {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
}
.hi-block .item-info {
		margin-left: -1.75rem;
		width: 100vw;
		padding: 0 1.125rem;
}
.hi-block .media-list {
		padding-top: 3.75rem;
}
.hi-block .co-img-card.type-tag {
		position: relative;
		padding: 2.5rem 0 2rem;
}
.hi-block .co-img-card.type-tag::after {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 1px;
		background-color: rgba(28, 28, 28, 0.05);
}
.hi-block .co-img-card.type-tag:last-child {
		padding-bottom: 0;
}
.hi-block .co-img-card.type-tag + .btn-view-more {
		margin-top: 1.25rem;
}
.hi-block .co-img-card.type-tag .thumbnail {
		margin-top: 1.25rem;
		padding-bottom: 52.631%;
}
.hi-block .co-img-card.type-tag .thumbnail > img {
		margin-top: 0;
}
.hi-block .co-img-card .thumbnail {
		position: relative;
		overflow: hidden;
		border: 1px solid rgba(28, 28, 28, 0.05);
		border-radius: 0.75rem;
}
.hi-block .co-img-card .thumbnail.type01 {
		padding-bottom: 52.631%;
}
.hi-block .co-img-card .thumbnail.type02 {
		padding-bottom: 55.263%;
}
.hi-block .co-img-card .thumbnail > img {
		min-width: 100%;
		min-height: 100%;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		object-fit: cover;
}
.hi-block .tit + .c-input-list {
		margin-top: 1.75rem;
		padding-bottom: 1.25rem;
}
.hi-block .tit + .c-input-list + .co-img-card.type3::after {
		display: none;
		width: 0;
		height: 0;
}
.hi-block .c-input-list + .co-img-card.type-tag::after {
		display: none;
		width: 0;
		height: 0;
}
.hi-block.disease .co-img-card .thumbnail {
		padding-bottom: 59.868%;
}
.hi-block .null-contents {
		padding: 2.5rem 0;
}

#popupExaminationInfo .box-tit {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		color: rgba(28, 28, 28, 0.8);
}
#popupExaminationInfo .box-tit ~ .box.type3 {
		margin-top: 0.75rem;
}
#popupExaminationInfo .box-tit ~ .box.type3 ~ .box-tit {
		margin-top: 1.75rem;
}

.ni-block .media-list a {
		padding: 0;
		margin-top: 2.5rem;
}
.ni-block .media-list a::after {
		display: none;
		width: 0;
		height: 0;
}
.ni-block .media-list a:first-child {
		margin-top: 0;
}
.ni-block .media-list .thumbnail {
		position: relative;
		padding-bottom: 59.896%;
		overflow: hidden;
		border: 1px solid rgba(28, 28, 28, 0.05);
		border-radius: 0.75rem;
}
.ni-block .media-list .thumbnail > img {
		min-width: 100%;
		min-height: 100%;
		margin-top: 0;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		object-fit: cover;
}
.ni-block .c-tab[data-tablist=notice] {
		padding-top: 1.75rem;
		padding-bottom: 3.75rem;
}
.ni-block .c-input-list + .accordion-list {
		margin-top: 2.5rem;
}
.ni-block .c-input-list + .accordion-list .accordion.type2 ~ .accordion.type2 {
		margin-top: 1.25rem;
}
.ni-block .service-form .row .col .c-txtarea-field .c-txt-area {
		height: 10rem;
}
.ni-block .terms.type-box + .box.type4 {
		margin-top: 2.5rem;
}
.ni-block .terms.type-box + .box.type4 + .btn-view-more {
		margin-top: 3.75rem;
}
.ni-block .type-round {
		border: 0;
}

.mi-block .btn-change-assword {
		margin-top: 2.5rem;
		margin-right: 0;
		margin-bottom: 0;
		margin-left: 0;
}
.mi-block .btn-change-assword ~ .btn-change {
		margin-top: 1.25rem;
		margin-right: 0;
		margin-bottom: 0;
		margin-left: 0;
}

.em-block .txt {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
}
.em-block .examinationPeriod {
		margin-top: 2.5rem;
}
.em-block .examinationPeriod + .sub-card.type1 {
		margin-top: 1.25rem;
}
.em-block .examinationPeriod + .sub-card.type1 + .accordion.type4 {
		margin-top: 3.75rem;
}
.em-block .region-list {
		margin-top: 3.75rem;
}
.em-block .tab-panel[aria-labelledby=hs-day-tab-panel] .terms {
		margin-top: 3.75rem;
}
.em-block .tab-panel[aria-labelledby=hs-day-tab-panel] .terms + .terms {
		margin-top: 2.5rem;
}
.em-block .tab-panel[aria-labelledby=hs-day-tab-panel] .terms ~ p {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.6);
		margin-top: 2.5rem;
}
.em-block .tab-panel[aria-labelledby=hs-day-tab-panel] .terms ~ p ~ p {
		margin-top: 0.5rem;
}
.em-block .tab-panel[aria-labelledby=hs-confirm-tab-panel] h2 {
		font-size: 1.25rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.8);
}
.em-block .tab-panel[aria-labelledby=hs-confirm-tab-panel] h2 + .c-input-list {
		margin-top: 1.25rem;
}
.em-block .tab-panel[aria-labelledby=hs-confirm-tab-panel] > h2 {
		margin-top: 0;
}
.em-block .tab-panel[aria-labelledby=hs-confirm-tab-panel] > h2 ~ h2 {
		padding-top: 3.75rem;
}
.em-block .btn-next {
		margin-top: 3.75rem;
}

.terms-field .btm-add-terms {
		margin-top: 3.75rem;
}

.c-select + .personal-terms {
		margin-top: 2.5rem;
}

.personal-terms {
		position: relative;
}
.personal-terms .table-wrap {
		margin-top: 1.25rem;
}
.personal-terms .table-wrap table {
		width: max-content;
}
.personal-terms .table-wrap table thead {
		border-bottom: 2px solid #d2d2d1;
}
.personal-terms .table-wrap table tbody th,
.personal-terms .table-wrap table tbody td {
		border-color: #e9e9e9;
}
.personal-terms .table-wrap table.terms-table-1, .personal-terms .table-wrap table.terms-table-2, .personal-terms .table-wrap table.terms-table-3 {
		margin-bottom: 1.25rem;
}
.personal-terms .table-wrap table.terms-table-1 tbody tr td:nth-child(1), .personal-terms .table-wrap table.terms-table-2 tbody tr td:nth-child(1), .personal-terms .table-wrap table.terms-table-3 tbody tr td:nth-child(1) {
		width: 7.5rem;
}
.personal-terms .table-wrap table.terms-table-1 tbody tr td:nth-child(2), .personal-terms .table-wrap table.terms-table-2 tbody tr td:nth-child(2), .personal-terms .table-wrap table.terms-table-3 tbody tr td:nth-child(2) {
		width: 10rem;
}
.personal-terms .table-wrap table.terms-table-1 tbody tr td:nth-child(3), .personal-terms .table-wrap table.terms-table-2 tbody tr td:nth-child(3), .personal-terms .table-wrap table.terms-table-3 tbody tr td:nth-child(3) {
		width: 20rem;
}
.personal-terms .table-wrap table.terms-table-1 tbody tr td:nth-child(4), .personal-terms .table-wrap table.terms-table-2 tbody tr td:nth-child(4), .personal-terms .table-wrap table.terms-table-3 tbody tr td:nth-child(4) {
		width: 12.5rem;
}
.personal-terms .table-wrap table.terms-table-1 tbody tr td:nth-child(5), .personal-terms .table-wrap table.terms-table-2 tbody tr td:nth-child(5), .personal-terms .table-wrap table.terms-table-3 tbody tr td:nth-child(5) {
		width: 20rem;
}
.personal-terms .table-wrap table.terms-table-1 tbody tr td:last-child, .personal-terms .table-wrap table.terms-table-2 tbody tr td:last-child, .personal-terms .table-wrap table.terms-table-3 tbody tr td:last-child {
		text-align: center;
}
.personal-terms .table-wrap table.terms-table-1 tbody th {
		vertical-align: middle;
		color: rgba(28, 28, 28, 0.8);
}
.personal-terms .table-wrap table.terms-table-1 tbody th[rowspan] {
		vertical-align: top;
}
.personal-terms .table-wrap table.terms-table-2 thead tr th,
.personal-terms .table-wrap table.terms-table-2 tbody tr td {
		width: 15rem;
}
.personal-terms .table-wrap table.terms-table-3 {
		min-width: 100%;
}
.personal-terms .table-wrap table.terms-table-3 thead tr th,
.personal-terms .table-wrap table.terms-table-3 tbody tr td {
		width: 33.333%;
}
.personal-terms .table-wrap table.terms-table-3 tbody td {
		text-align: left;
}
.personal-terms .table-wrap + p {
		margin-top: 1.25rem;
}
.personal-terms .c-terms-section:first-of-type {
		margin-top: 2.5rem;
}
.personal-terms h3 + .co-list-card {
		margin-top: 0.25rem;
}

.personal-terms-popup {
		text-align: left;
}
.personal-terms-popup .title {
		font-size: 1.125rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
}
.personal-terms-popup .cont {
		margin-top: 0.75rem;
}

.policy-list {
		margin-top: 20px;
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
}
.policy-list > li ~ li {
		margin-top: 0.5rem;
}
.policy-list > li > a {
		position: relative;
		color: #253981;
		border-bottom: 1px solid #253981;
}
.policy-list > li > a:before {
		content: "";
		border-bottom: 1px solid #253981;
}

.c-terms-section > h2,
.c-terms-section > h3 {
		font-size: 1rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		color: rgba(28, 28, 28, 0.8);
}
.c-terms-section > h3 {
		margin-top: 20px;
		color: rgba(28, 28, 28, 0.8);
}
.c-terms-section > p {
		margin-top: 4px;
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
}
.c-terms-section p,
.c-terms-section ol,
.c-terms-section ul {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.6);
}
.c-terms-section .co-list-card.number01 > li:before {
		width: 1rem;
		height: 1rem;
		font-size: 0.75rem;
		line-height: 1rem;
		margin-right: 0.25rem;
}
.c-terms-section .co-list-card.number02 > li:before {
		top: 8px;
}
.c-terms-section ~ .c-terms-section {
		margin-top: 3.75rem;
}
.c-terms-section .c-terms-link {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: #253981;
		border-bottom: 1px solid #253981;
}

.guide-block h2 {
		font-size: 1.5rem;
		font-weight: 500;
		letter-spacing: -0.0313rem;
		line-height: 2rem;
		color: #494949;
		margin-top: 2.5rem;
}
.guide-block h3 {
		font-size: 1.125rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
		color: #253981;
		margin-top: 2.5rem;
}
.guide-block h3 span {
		color: rgba(28, 28, 28, 0.4);
}
.guide-block p {
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
		color: #494949;
		margin-top: 0.75rem;
}
.guide-block p + h2 {
		margin-top: 3.75rem;
}
.guide-block img {
		display: block;
		width: 100%;
		height: auto;
		margin-top: 1.25rem;
}
.guide-block .tab-floating + .tab-panel.show > h2 {
		margin-top: 0;
}
.guide-block .tab-floating + .tab-panel.show .c-select + h2 {
		margin-top: 2.5rem;
}
.guide-block .tab-floating + .tab-panel.show img + h2 {
		margin-top: 3.75rem;
}

.tab-panel[aria-labelledby=event-end-tab-panel] {
		padding-top: 3rem;
}

.c-full-layer.popup-chkupresult-compare .cont-box {
		padding-left: 0;
		padding-right: 0;
}
.c-full-layer.popup-chkupresult-compare .chart-area {
		margin-top: 1.25rem;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
}
.c-full-layer.popup-chkupresult-compare .cont-title,
.c-full-layer.popup-chkupresult-compare .btm-cont-box {
		padding-left: 1.75rem;
		padding-right: 1.75rem;
}
.c-full-layer .chart-area {
		width: 100%;
		height: auto;
}

.offered-examination .offered-exam-list > li {
		padding-top: 8px;
		padding-bottom: 8px;
		font-size: 1rem;
		font-weight: 300;
		letter-spacing: -0.0187rem;
		line-height: 1.75rem;
}

.jo-block .cont + .box.type03 {
		margin-top: 1.25rem;
}
.jo-block h2 + .terms {
		margin-top: 1.25rem;
}
.jo-block .co-complete-field .cont + .box {
		margin-top: 1.25rem;
}
.jo-block .co-complete-field .box > p {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
}
.jo-block .co-complete-field .box > p + .co-list-card {
		margin-top: 12px;
}
.jo-block .co-complete-field .box .co-list-card {
		color: #1c1c1c;
}
.jo-block .co-complete-field .box .co-list-card > li {
		margin-top: 12px;
}

.jobstrees-factor-field .ref-cont {
		font-weight: 400;
}

.jobstress-target-list .sub-card ~ .sub-card {
		margin-top: 0.75rem;
}

.jobstress-title-field {
		margin-top: 3.75rem;
		margin-bottom: 2.5rem;
		display: block;
		align-items: center;
		color: rgba(28, 28, 28, 0.8);
}
.jobstress-title-field .co-tooltip {
		margin-top: 0.25rem;
		margin-left: 0.5rem;
}
.jobstress-title-field .title {
		font-size: 1.125rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
		display: flex;
		align-items: center;
}
.jobstress-title-field .title + .cont {
		color: rgba(28, 28, 28, 0.8);
		margin-top: 1.25rem;
}
.jobstress-title-field.type02 .title + .cont {
		margin-top: 8px;
}

.jo-exam-listbox {
		margin-left: -1.75rem;
		margin-right: -1.75rem;
		padding-bottom: 2rem;
		background-color: rgba(28, 28, 28, 0.05);
}
.jo-exam-listbox .sub-title {
		font-size: 1.125rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
}
.jo-exam-listbox .condition-field {
		margin-top: 2.5rem;
		color: rgba(28, 28, 28, 0.8);
}
.jo-exam-listbox .condition-field .cont {
		color: #1c1c1c;
}
.jo-exam-listbox .condition-field .cont + .cont {
		margin-top: 2.5rem;
}

.jo-exam-field {
		padding: 2rem 1.75rem 0;
}
.jo-exam-field .title {
		font-size: 1.5rem;
		font-weight: 500;
		letter-spacing: -0.0313rem;
		line-height: 2rem;
}
.jo-exam-field ~ .exam-field {
		margin-top: 3.75rem;
}
.jo-exam-field .cont + .chkgruoup {
		margin-top: 0.75rem;
}
.jo-exam-field .exam-article:first-of-type {
		margin-top: 1.25rem;
}
.jo-exam-field .exam-article .cont {
		color: #1c1c1c;
}
.jo-exam-field .exam-article .cont.is-error {
		color: #ba1d3c;
		font-weight: 400;
}
.jo-exam-field .exam-article .cont + .row {
		margin-top: 0.75rem;
}
.jo-exam-field .exam-article .c-chkbox ~ .c-chkbox {
		margin-top: 0.5rem;
}
.jo-exam-field .exam-article ~ .exam-article {
		margin-top: 2.5rem;
}

.jo-btm-refcont {
		margin-top: 1.25rem;
		color: rgba(28, 28, 28, 0.8);
}

.c-chkbox.type-jobstress {
		text-align: left;
		display: block;
		width: 100%;
}
.c-chkbox.type-jobstress ~ .type-jobstress {
		margin-top: 9px;
}
.c-chkbox.type-jobstress input [type=checkbox] + .chk-label,
.c-chkbox.type-jobstress input[type=radio] + .chk-label {
		position: relative;
}
.c-chkbox.type-jobstress input [type=checkbox] + .chk-label:before,
.c-chkbox.type-jobstress input[type=radio] + .chk-label:before {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
		border: 3px solid transparent;
		border-radius: 1.25rem;
		background-image: linear-gradient(to bottom, #fff, #fff), linear-gradient(65deg, #2f7ad3 1%, #253981 113%);
		background-origin: border-box;
		background-clip: content-box, border-box;
		transition: opacity 0.3s ease-in-out;
}
.c-chkbox.type-jobstress input [type=checkbox] + .chk-label > span,
.c-chkbox.type-jobstress input[type=radio] + .chk-label > span {
		color: rgba(28, 28, 28, 0.8);
}
.c-chkbox.type-jobstress input [type=checkbox] + .chk-label > i,
.c-chkbox.type-jobstress input[type=radio] + .chk-label > i {
		width: 24px;
		height: 24px;
		background: url("../images/ico/ico_checkbox_sub_card_20x20.svg") center/24px auto no-repeat;
		margin-right: 0.25rem;
}
.c-chkbox.type-jobstress input [type=checkbox] + .chk-label > i,
.c-chkbox.type-jobstress input [type=checkbox] + .chk-label > span,
.c-chkbox.type-jobstress input[type=radio] + .chk-label > i,
.c-chkbox.type-jobstress input[type=radio] + .chk-label > span {
		z-index: 10;
		margin-left: 0;
}
.c-chkbox.type-jobstress input [type=checkbox]:checked + .chk-label,
.c-chkbox.type-jobstress input[type=radio]:checked + .chk-label {
		position: relative;
}
.c-chkbox.type-jobstress input [type=checkbox]:checked + .chk-label:before,
.c-chkbox.type-jobstress input[type=radio]:checked + .chk-label:before {
		border-radius: 0.75rem;
		opacity: 1;
}
.c-chkbox.type-jobstress input [type=checkbox]:checked + .chk-label > i,
.c-chkbox.type-jobstress input[type=radio]:checked + .chk-label > i {
		background-image: url("../images/ico/ico_checkbox_sub_card_20x20_default_on.svg");
}
.c-chkbox.type-jobstress > label {
		width: 100%;
		padding: 15px 12px;
		border-radius: 0.75rem;
		border: 1px solid #ecf1f8;
		background-color: #fff;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		cursor: pointer;
}

.exam-field {
		height: 500px;
		padding: 40px;
}

.jo-progress-field .jo-row ~ .jo-row {
		margin-top: 1.5rem;
}
.jo-progress-field .jo-row .inner {
		margin-top: 0.5rem;
		padding: 1rem 1.25rem;
		border-radius: 0.75rem;
		background-color: #fff;
		box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}
.jo-progress-field h3 {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		color: rgba(28, 28, 28, 0.8);
}
.jo-progress-field .score {
		display: flex;
		align-items: flex-end;
}
.jo-progress-field .score .type {
		font-size: 1.375rem;
		font-weight: 500;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
}
.jo-progress-field .score .num {
		font-size: 1.125rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.6);
		padding-left: 0.5rem;
}
.jo-progress-field .score .unit {
		font-size: 1.125rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
		color: rgba(28, 28, 28, 0.6);
		padding-left: 0.5rem;
}

.jo-res-swiper {
		margin-top: 20px;
}

.jo-accordion-list .accordion + .accordion {
		margin-top: 24px;
}

.jo-factor-box {
		margin-top: 1.5rem;
		padding: 1.5rem 1.25rem;
		background-color: #f6f6f8;
		border-radius: 0.75rem;
}

.jo-factor-list > li ~ li {
		margin-top: 1.5rem;
}
.jo-factor-list .name {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		color: #1c1c1c;
}
.jo-factor-list .name + .value {
		margin-top: 0.5rem;
}
.jo-factor-list .value {
		display: flex;
		align-items: flex-end;
		padding: 1rem 1.25rem;
		background-color: #fff;
		box-shadow: 0px 0.125rem 4px rgba(0, 0, 0, 0.2);
		border-radius: 0.75rem;
		font-size: 1.125rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
		color: rgba(28, 28, 28, 0.6);
}
.jo-factor-list .value > em {
		color: #1c1c1c;
		font-size: 1.375rem;
		font-weight: 500;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
}
.jo-factor-list .value .unit {
		display: inline-flex;
		margin-left: 0.25rem;
}
.jo-factor-list + .cont {
		margin-top: 1.5rem;
}

.jo-progress-bar {
		border-radius: 12px;
		position: relative;
		overflow: hidden;
		width: 100%;
		height: 4px;
		background-color: #ebf2fb;
		margin-top: 0.5rem;
}
.jo-progress-bar .unit {
		padding-left: 0.5rem;
		font-size: 1.125rem;
		font-weight: 400;
		letter-spacing: -0.0313rem;
		line-height: 1.75rem;
}
.jo-progress-bar .jo-progress {
		border-radius: 8px;
		display: block;
		width: 0%;
		height: 100%;
		background: #2f7ad3;
}

.target-btn-list {
		display: flex;
		align-items: center;
		position: absolute;
		top: 25px;
		right: 22px;
}
.target-btn-list button + button {
		margin-left: 1.25rem;
		width: 1.25rem;
		height: 1.25rem;
}
.target-btn-list .btn-del-target {
		width: 1rem;
		height: 1rem;
		background: url("../images/ico/ico_close_03.svg") 0/1rem 1rem no-repeat;
		z-index: 10;
		overflow: hidden;
		text-indent: 100%;
		white-space: nowrap;
}
.target-btn-list .btn-modify-target {
		width: 1rem;
		height: 1rem;
		background: url("../images/ico/ico_modify_01.svg") 0/1.125rem 1rem no-repeat;
		z-index: 10;
		overflow: hidden;
		text-indent: 100%;
		white-space: nowrap;
}

.popup-agree-consent .c-modal-body {
		padding-bottom: 0;
}
.popup-agree-consent .c-modal-footer .chk-list {
		margin-bottom: 1.25rem;
}
.popup-agree-consent .c-modal-inner {
		font-size: 0.9375rem;
		font-weight: 300;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
}
.popup-agree-consent .c-modal-inner .co-list-card > li {
		font-size: 0.9375rem;
		font-weight: 300;
		letter-spacing: -0.0313rem;
		line-height: 1.5rem;
}

.co-consent-form-field .cont-detail-body {
		margin-top: 10px;
		margin-bottom: 10px;
}

.resexam-agreeuse-cont .co-list-card > li:before {
		font-weight: 500;
}
.resexam-agreeuse-cont .co-list-card > li ~ li {
		margin-top: 20px;
}
.resexam-agreeuse-cont .co-list-card .title {
		display: inline-block;
		font-weight: 700;
}
.resexam-agreeuse-cont .co-list-card .title + .table-wrap {
		margin-top: 0.625rem;
}
.resexam-agreeuse-cont .messrs {
		margin-top: 0.625rem;
		text-align: center;
		font-size: 0.9375rem;
		font-weight: 500;
		line-height: 1.875rem;
}

.consent-personal-info {
		margin-top: 1.25rem;
		text-align: center;
		font-size: 0.9375rem;
		font-weight: 400;
		line-height: 1.875rem;
}
.consent-personal-info .date {
		display: flex;
		align-items: center;
		justify-content: center;
}
.consent-personal-info .date > span ~ span {
		padding-left: 4px;
}
.consent-personal-info .chk-list {
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		text-align: left;
}

table.tbl-useagree {
		width: 100%;
}
table.tbl-useagree em {
		font-weight: 700;
}

.c-t-cont-type01 {
		font-size: 0.9375rem;
		font-weight: 300;
		line-height: 1.625rem;
}

@media (max-width: 370px) {
		.co-main-card {
				height: 11.75rem;
		}
		.co-main-card a.result,
.co-main-card p.result {
				margin-top: 1.25rem;
		}
}
@media screen and (max-width: 280px) {
		html,
body {
				font-size: 15px;
		}
		input,
textarea {
				max-width: 100%;
				box-sizing: border-box;
		}
		input::placeholder,
textarea::placeholder {
				font-size: 14px;
		}
		.co-gnb {
				right: calc(0rem - (100% - 2.666rem));
				width: calc(100% - 2.666rem);
		}
		.co-gnb .gnb-header,
.co-gnb .gnb-btn-logout {
				padding-left: 1.066rem;
				padding-right: 1.066rem;
		}
		.co-gnb .gnb-body li > a,
.co-gnb .gnb-body li button {
				padding-left: 0.8rem;
				padding-right: 0.8rem;
		}
		.co-gnb .gnb-body li .gnb-list a {
				padding-left: 0.8rem;
				padding-right: 0.8rem;
		}
		.login-banner img {
				right: auto;
				left: 10.933rem;
		}
		.login-banner02 {
				padding-left: 0.625rem;
		}
		.login-banner02 img {
				right: auto;
				left: 12.3rem;
		}
		.login-block {
				padding-left: 1.066rem;
				padding-right: 1.066rem;
		}
		.login-block .tab-panel + .btn-list a:nth-child(even):after {
				display: block;
				content: "";
				clear: both;
		}
		.footer.type-default .img-logo {
				width: 12.857rem;
		}
		.c-full-layer .cont-box,
.c-full-layer .full-header,
.c-full-layer .full-footer,
.c-full-layer .full-body .top-title-box,
.c-full-layer .btn-floating,
.c-full-layer .btn-floating.isScroll {
				padding-left: 1.066rem;
				padding-right: 1.066rem;
		}
		.c-layer-popup .c-modal-header,
.c-layer-popup .c-modal-body,
.c-layer-popup .c-modal-footer {
				padding-left: 1.333rem;
				padding-right: 1.333rem;
		}
		.ti-block,
.rv-block,
.rc-block,
.vgt-block,
.ri-block,
.hi-block,
.ni-block,
.mi-block,
.em-block,
.terms-block,
.guide-block,
.main-block,
.jo-block {
				padding-left: 1.066rem;
				padding-right: 1.066rem;
		}
		.rv-block.type-floating-btn {
				padding-left: 1.066rem;
				padding-right: 1.066rem;
		}
		input[type=text], input[type=password], input[type=number], input[type=search] {
				padding-left: 1.066rem;
				padding-right: 1.066rem;
		}
		button.co-btn,
a.co-btn {
				padding-left: 0;
				padding-right: 0;
		}
		button.is-prev > span, button.is-next > span,
a.is-prev > span,
a.is-next > span {
				line-height: 1.6rem;
		}
		.c-input {
				min-height: 46px;
		}
		.c-input-btn-group .co-btn {
				min-height: 46px;
		}
		.login-block .tab-panel + .btn-list a {
				padding: 0 0.4rem;
		}
		.c-full-layer .full-body .top-title-box {
				font-size: 1.866rem;
		}
		.hi-block .item-info {
				margin-left: -1.066rem;
				padding: 0.4rem;
		}
		.item-info a {
				width: calc(50vw - 0.666rem - 1.066rem);
		}
		.item-info a margin {
				left: 0.666rem;
				right: 0.666rem;
		}
		.c-modal-header .c-modal-title-field .c-title {
				font-size: 1.4rem;
				margin-right: 0.571rem;
		}
		header.header.co-header {
				padding-left: 1.066rem;
				padding-right: 1.066rem;
		}
		#popupInstitutionDetails .instDetailsCenter.swiper-ready .swiper-slide {
				width: 100% !important;
		}
		.main-card-rsv-inquiry .hide-txt {
				position: absolute;
				overflow: hidden;
				clip: rect(0 0 0 0);
				margin: -1px;
				padding: 0;
				width: 1px;
				height: 1px;
				border: 0;
				font-size: 1px;
				line-height: 1px;
				color: transparent;
		}
		.main-block .card_09 {
				padding-left: 5.2rem;
				padding-right: 0.937rem;
		}
		.subjectSwiper,
.eventSwiper,
.resultSwiper {
				overflow: hidden;
		}
		.subjectSwiper .swiper-slide,
.eventSwiper .swiper-slide,
.resultSwiper .swiper-slide {
				width: 100%;
		}
		.login-block .tit,
.ti-block .tit,
.rv-block .tit,
.rc-block .tit,
.vgt-block .tit,
.main-block .tit,
.hi-block .tit,
.ni-block .tit,
.mi-block .tit,
.em-block .tit,
.terms-block .tit,
.guide-block .tit,
.jo-block .tit {
				font-size: 1.866rem;
		}
		.c-full-layer.popup-prd-additional-amount#popupProductAdditionalAmount .top-title-box {
				font-size: 1.466rem;
		}
		.c-full-layer.popup-prd-additional-amount#popupProductAdditionalAmount .cont-box dl dd:first-of-type {
				padding-right: 0;
		}
		.swiper.examResSwiper .swiper-wrapper .swiper-slide {
				width: 100%;
		}
}