// card
// + card_01
.card_01 {
	position: relative;
	input[type='checkbox'],
	input[type='radio'] {
		@include hide-input;
		& + label {
			@include card-check-type-off;
			box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
			border-radius: 1.25rem;
			display: flex;
			flex-direction: column;
			overflow: hidden;
			.inner {
				position: relative;
				border-radius: 1.125rem;
				background-color: #fff;
				padding: {
					top: 0.75rem;
					right: 1rem;
					bottom: 0.75rem;
					left: 1rem;
				}
				ul {
					&.case {
						display: flex;
						align-items: center;
						justify-content: space-between;
						li {
							&.ico-checkbox {
								width: 1.25rem;
								height: 1.25rem;
								background-image: url($image-ico-path + 'ico_checkbox_sub_card_20x20.svg');
								background-size: 1.25rem 1.25rem;
								background-position: center center;
								background-repeat: no-repeat;
								transition: background-image $transition-3 ease-in-out;
							}
							&:first-child {
								@include coTxtSubCard;
								color: $color-sub-02;
							}
						}
					}
					&.profile {
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-top: 0.25rem;
						li {
							@include coTxtTitleLarge;
							color: $color-black-80;
							& ~ li {
								@include coTxtLabelMedium;
								color: $color-black-60;
							}
						}
					}
				}
				p {
					@include coTxtLabelMedium;
					color: $color-black-60;
					margin-top: 0.75rem;
				}
			}
			&::before {
				@include card-check-type-on-before(1.25rem);
				border-radius: 1.25rem;
			}
		}
		&:checked {
			& + label {
				@include card-check-type-on;
				.inner {
					ul {
						&.case {
							li {
								&.ico-checkbox {
									background-image: url($image-ico-path + 'ico_checkbox_sub_card_20x20_default_on.svg');
								}
							}
						}
					}
				}
				&::before {
					opacity: 1;
				}
			}
		}
	}
}

// + card_02
.card_02 {
	position: relative;
	input[type='checkbox'] {
		@include hide-input;
		& + label {
			@include card-check-type-off;
			box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
			border-radius: 1.25rem;
			display: block;
			overflow: hidden;
			.inner {
				position: relative;
				border-radius: 1.125rem;
				background-color: #fff;
				padding: 0.75rem 1rem;
				p {
					@include coTxtLabelLarge;
					color: $color-black-100;
				}
				ul {
					display: flex;
					align-items: center;
					justify-content: space-between;
					li {
						&.price {
							display: flex;
							align-items: center;
							span {
								@include coTxtTitleLarge;
								color: $color-sub-02;
							}
							em {
								@include coTxtBody;
								color: $color-black-60;
								margin-left: 0.25rem;
							}
						}
						&.icon {
							display: flex;
							align-items: center;
							.ico-document,
							.ico-checkbox {
								display: block;
								width: 2rem;
								height: 2rem;
								background-size: 2rem 2rem;
								background-position: center center;
								background-repeat: no-repeat;
							}
							.ico-document {
								background-image: url($image-ico-path + 'ico_card_document_01.svg');
							}
							.ico-checkbox {
								background-image: url($image-ico-path + 'ico_card_checkbox_01.svg');
								margin-left: 0.75rem;
								transition: background-image $transition-3 ease-in-out;
							}
						}
					}
					&.case {
						li {
							&.ico-checkbox {
								width: 1.25rem;
								height: 1.25rem;
								background-image: url($image-ico-path + 'ico_checkbox_sub_card_20x20.svg');
								background-size: 1.25rem 1.25rem;
								background-position: center center;
								background-repeat: no-repeat;
								transition: background-image $transition-3 ease-in-out;
							}
							&:first-child {
								@include coTxtLabelMedium;
								color: $color-black-60;
							}
						}
						& ~ ul {
							margin-top: 0.25rem;
						}
					}
				}
			}
			&::before {
				@include card-check-type-on-before(1.25rem);
				border-radius: 1.25rem;
			}
		}
		&:checked {
			& + label {
				@include card-check-type-on;
				.inner {
					ul {
						&.case {
							li {
								&.ico-checkbox {
									background-image: url($image-ico-path + 'ico_checkbox_sub_card_20x20_on.svg');
								}
							}
						}
					}
				}
				&::before {
					opacity: 1;
				}
			}
		}
	}
}

// + card_03
.card_03 {
	position: relative;
	display: block;
	background: #ffffff;
	box-shadow: 0.125rem 0.125rem 0.625rem rgba(0, 0, 0, 0.1);
	border-radius: 2rem;
	overflow: hidden;
	padding: {
		top: 1.25rem;
		right: 1.25rem;
		bottom: 1.25rem;
		left: 1.25rem;
	}
	&.type-link {
		padding-right: 3.625rem;
		background-image: url($image-ico-path + 'ico_arrow_right_01_34x34.svg');
		background-position: right 0.75rem center;
		background-size: 2.125rem 2.125rem;
		background-repeat: no-repeat;
		p {
			@include coTxtTitleLarge;
			color: $color-black-20;
			& ~ p {
				@include coTxtLabelLarge;
				color: $color-black-60;
				margin-top: 0.25rem;
				span {
					color: $color-sub-02;
					display: inline-block;
					margin-right: 0.25rem;
				}
			}
		}
	}
	&.type-card {
		padding-bottom: 0.5rem;
		p,
		span {
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}
		p {
			em {
				@include coTxtTitleLarge;
				color: $color-main;
				position: relative;
				& ~ em {
					padding-left: 1.375rem;
					&::after {
						content: '/';
						display: block;
						position: absolute;
						left: 0.5rem;
						top: 0.125rem;
						width: 0.375rem;
						height: 1.5rem;
						@include coTxtLabelLarge;
						color: $color-black-40;
					}
				}
			}
		}
		span,
		span em {
			@include coTxtLabelLarge;
		}
		span {
			color: $color-black-40;
			padding-bottom: 0.75rem;
			em {
				color: $color-sub-01;
				margin-right: 0.25rem;
			}
		}
		ul {
			&.co-badge {
				margin-top: 0.5rem;
				margin-left: -0.375rem;
				width: calc(100% + 0.75rem);
				li {
					@include coTxtLabelLarge;
					background-color: $color-bg-03;
					color: $color-main;
					padding: 0.125rem 0.625rem;
					height: 1.75rem;
					margin: {
						top: 0;
						right: 0.375rem;
						bottom: 0.75rem;
						left: 0.375rem;
					}
				}
			}
		}
	}
}

// + card_04
.card_04 {
	width: 16.25rem;
	border-radius: 1.25rem;
	background-color: $color-white-100;
	padding: 0.1875rem;
	box-shadow: 0.125rem 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.1);
	overflow: hidden;
	&.swiper-slide {
		position: relative;
		&::before {
			@include card-check-type-on-before(1.25rem);

			transition: none;
		}
		&.swiper-slide-active {
			&::before {
				opacity: 1;
			}
		}
	}
	.inner {
		position: relative;
		border-radius: 1.125rem;
		background-color: $color-white-100;
		padding: 1.25rem;
		p {
			@include ellipsisLine01;
		}
		.hospital-date {
			@include coTxtLabelLarge;
			color: $color-sub-02;
			text-align: left;
		}
		.hospital-name {
			@include coTxtTitleLarge;
			color: $color-black-80;
			margin-top: 0.25rem;
		}
		.badges {
			width: max-content;
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			width: 100%;
			margin-top: 1.25rem;
			span {
				@include coTxtLabelMedium;
				@include ellipsisLine01;
				color: $color-main;
				padding: 0.125rem 0.625rem;
				background-color: $color-bg-03;
				border-radius: 1.25rem;
			}
		}
	}
}

// + card_05
.card_05 {
	position: relative;
	input[type='checkbox'],
	input[type='radio'] {
		@include hide-input;
		& + label {
			@include card-check-type-off;
			box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
			border-radius: 1.25rem;
			display: block;
			overflow: hidden;
			.inner {
				position: relative;
				border-radius: 1.125rem;
				background-color: #fff;
				padding: 1rem 1.25rem;
				p {
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;
					span {
						@include coTxtLabelLarge;
						color: $color-black-100;
						padding-right: 1rem;
						@include shortening-one;
					}
					em {
						@include coTxtLabelMedium;
						color: $color-white-100;
						background-color: $color-main;
						display: inline-block;
						padding: 0 0.5rem;
						border-radius: 0.5rem;
						white-space: nowrap;
					}
					& + ul {
						margin-top: 0.75rem;
						position: relative;
					}
				}

				//#
				.co-caption-txt {
					@include coTxtBodySmall;
					@include shortening-one;
					display: block;
				}

				ul {
					display: flex;
					align-items: center;
					justify-content: space-between;
					li {
						&.price {
							display: flex;
							align-items: center;
							span {
								@include coTxtTitleLarge;
								color: $color-sub-02;
							}
							em {
								@include coTxtTitleLarge;
								color: $color-black-40;
								margin-left: 0.25rem;
							}
						}
						&.icon {
							display: flex;
							align-items: center;
							.ico-document,
							.ico-checkbox {
								display: block;
								width: 2rem;
								height: 2rem;
								background-size: 2rem 2rem;
								background-position: center center;
								background-repeat: no-repeat;
							}
							.ico-document {
								background-image: url($image-ico-path + 'ico_card_document_01.svg');
							}
							.ico-checkbox {
								background-image: url($image-ico-path + 'ico_card_checkbox_01.svg');
								margin-left: 0.75rem;
								transition: background-image $transition-3 ease-in-out;
							}
						}
					}
				}
			}
			&::before {
				@include card-check-type-on-before(1.25rem);
				border-radius: 1.25rem;
			}
		}
		&:checked {
			& + label {
				@include card-check-type-on;
				.inner {
					ul {
						li {
							&.icon {
								.ico-checkbox {
									background-image: url($image-ico-path + 'ico_card_checkbox_01_on.svg');
								}
							}
						}
					}
				}
				&::before {
					opacity: 1;
				}
			}
		}
	}
}

// + card_06
.card_06 {
	background: $color-white-100;
	border: 1px solid $color-black-05;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
	border-radius: 0.75rem;
	padding: 0.75rem 1.25rem;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: flex-start;
	li {
		flex-grow: 1;
		width: 100%;
		.card-tit {
			@include coTxtLabelMedium;
			color: $color-sub-02;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			.co-tooltip {
				margin-left: 0.5rem;
			}
		}
		& ~ li {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.card-res {
				@include coTxtBody;
				color: $color-black-80;
				padding-right: 0.25rem;
				@include shortening-one;
			}
			.co-btn {
				background: $color-bg-03;
				border-radius: 0.5rem;
				border: 0 none;
				white-space: nowrap;
				span {
					color: $color-main;
				}
			}
		}
	}
	& ~ .card_06 {
		margin-top: 0.75rem;
	}
}

// + card_07
.card_07 {
	display: block;
	padding: 0.75rem 1.25rem;
	width: 100%;
	height: 4.5rem;
	background-color: $color-white-100;
	border: 1px solid $color-black-05;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.16);
	border-radius: 0.75rem;
	ul {
		display: flex;
		align-items: center;
		justify-content: space-between;
		li {
			@include coTxtLabelMedium;
			&.sub-tit {
				color: $color-sub-02;
			}
			&.date {
				color: $color-black-40;
				span {
					@include coTxtLabelMedium;
					color: $color-black-40;
				}
			}
		}
	}
	.card-tit {
		color: $color-black-80;
		font-size: 0.875rem;
		line-height: 1.5rem;
		font-weight: 400;
		font-style: normal;
		text-align: left;
		margin-top: 0.25rem;
		// 1줄 말줄임
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	& ~ .card_07 {
		margin-top: 1.25rem;
	}
}

// + card_08
.card_08 {
	position: relative;
	background: $color-white-100;
	border: 1px solid $color-black-05;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
	border-radius: 0.75rem;
	padding: 2rem 1.25rem;
	.card-date {
		@include coTxtLabelMedium;
		color: $color-sub-02;
	}
	.card-tit {
		@include coTxtBody;
		color: $color-black-100;
	}
	.list {
		margin-top: 1.25rem;
		li {
			margin-top: 0.5rem;
		}
	}
	& + .card_08 {
		margin-top: 2.5rem;
	}
}

// + card_09
.card_09 {
	position: relative;
	display: block;
	box-shadow: 0.125rem 0.125rem 0.625rem rgba(0, 0, 0, 0.1);
	border-radius: 2rem;
	background-color: $color-white-100;
	padding: {
		top: 1.5rem;
		right: 1.25rem;
		bottom: 1.5rem;
		left: 5.375rem;
	}
	img {
		position: absolute;
		left: 1.25rem;
		top: 50%;
		width: 2.875rem;
		height: 2.875rem;
		transform: translateY(-50%);
		border-radius: 1rem;
		overflow: hidden;
	}
	p {
		@include coTxtTitleSmall;
		color: $color-main;
		& ~ p {
			@include coTxtLabelLarge;
			color: $color-sub-01;
		}
	}
	& ~ .card_09 {
		margin-top: 1rem;
	}
}
a {
	&.card_09 {
		padding-right: 4.125rem;
		background-image: url('../../images/ico/ico_arrow_main_result_34x34.svg');
		background-size: 2.125rem 2.125rem;
		background-position: right 0.75rem center;
		background-repeat: no-repeat;
	}
}

// + Inspection Card
.card-inspection {
	position: relative;
	input[type='checkbox'],
	input[type='radio'] {
		@include hide-input;
		& + label {
			@include card-check-type-off;
			box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
			border-radius: 0.75rem;
			display: flex;
			flex-direction: column;
			overflow: hidden;
			.inner {
				position: relative;
				border-radius: 0.625rem;
				background-color: #fff;
				padding: {
					top: 0.8125rem;
					right: 1.0625rem;
					bottom: 0;
					left: 1.0625rem;
				}
				p,
				p em {
					@include coTxtLabelMedium;
				}
				p {
					display: flex;
					align-items: center;
					color: $color-black-40;
					em {
						color: $color-black-60;
						margin-right: 0.25rem;
					}
				}
				ul {
					&.price {
						display: flex;
						align-items: center;
						margin-top: 0.25rem;
						padding-bottom: 0.75rem;
						li {
							@include coTxtTitleHeadingSmall;
							color: $color-black-100;
							& ~ li {
								@include coTxtTitleSmall;
								color: $color-black-60;
								margin-left: 0.25rem;
							}
						}
						& + .tag-list {
							margin-top: 0.5rem;
						}
					}
					&.co-badge {
						margin-top: 0.25rem;
						// common
						margin-left: -0.375rem;
						width: calc(100% + 0.75rem);
						li {
							@include coTxtLabelMedium;
							background-color: $color-bg-03;
							color: $color-main;
							padding: 0.125rem 0.625rem;
							height: 1.75rem;
							margin: {
								top: 0;
								right: 0.375rem;
								bottom: 0.75rem;
								left: 0.375rem;
							}
						}
					}
				}
				.co-tooltip {
					position: absolute;
					right: 1.25rem;
					top: 1rem;
				}
			}
			&::before {
				@include card-check-type-on-before(0.75rem);
			}
		}
		&:checked {
			& + label {
				@include card-check-type-on;
				&::before {
					opacity: 1;
				}
			}
		}
	}
}
